blockquote, body, button, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, input, li, ol, p, pre, td, textarea, th, ul {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

a:active, a:hover {
    outline: 0
}

img {
    display: inline-block;
    border: none;
    vertical-align: middle
}

li {
    list-style: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

h1, h2, h3, h4 {
    font-weight: 700
}

h5, h6 {
    font-weight: 500;
    font-size: 100%
}

button, input, select, textarea {
    font-size: 100%
}

button, input, optgroup, option, select, textarea {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    outline: 0
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word
}

body {
    line-height: 1.6;
    color: #333;
    color: rgba(0, 0, 0, .85);
    font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif
}

hr {
    height: 0;
    line-height: 0;
    margin: 10px 0;
    padding: 0;
    border: none !important;
    border-bottom: 1px solid #eee !important;
    clear: both;
    overflow: hidden;
    background: 0 0
}

a {
    color: #333;
    text-decoration: none
}

a:hover {
    color: #777
}

a cite {
    font-style: normal;

}

.layui-border-box, .layui-border-box * {
    box-sizing: border-box
}

.layui-box, .layui-box * {
    box-sizing: content-box
}

.layui-clear {
    clear: both;

}

.layui-clear:after {
    content: '\20';
    clear: both;
    ;
    display: block;
    height: 0
}

.layui-clear-space {
    word-spacing: -5px
}

.layui-inline {
    position: relative;
    display: inline-block;

    ;
    vertical-align: middle
}

.layui-edge {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: dashed;
    border-color: transparent;
    overflow: hidden
}

.layui-edge-top {
    top: -4px;
    border-bottom-color: #999;
    border-bottom-style: solid
}

.layui-edge-right {
    border-left-color: #999;
    border-left-style: solid
}

.layui-edge-bottom {
    top: 2px;
    border-top-color: #999;
    border-top-style: solid
}

.layui-edge-left {
    border-right-color: #999;
    border-right-style: solid
}

.layui-elip {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.layui-disabled, .layui-icon, .layui-unselect {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.layui-disabled, .layui-disabled:hover {
    color: #d2d2d2 !important;
    cursor: not-allowed !important
}

.layui-circle {
    border-radius: 100%
}

.layui-show {
    display: block !important
}

.layui-hide {
    display: none !important
}

.layui-show-v {
    visibility: visible !important
}

.layui-hide-v {
    visibility: hidden !important
}

@font-face {
    font-family: layui-icon;
    src: url(../font/iconfont.eot?v=282);
    src: url(../font/iconfont.eot?v=282#iefix) format('embedded-opentype'), url(../font/iconfont.woff2?v=282) format('woff2'), url(../font/iconfont.woff?v=282) format('woff'), url(../font/iconfont.ttf?v=282) format('truetype'), url(../font/iconfont.svg?v=282#layui-icon) format('svg')
}

.layui-icon {
    font-family: layui-icon !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.layui-icon-leaf:before {
    content: "\e701"
}

.layui-icon-folder:before {
    content: "\eabe"
}

.layui-icon-folder-open:before {
    content: "\eac1"
}

.layui-icon-gitee:before {
    content: "\e69b"
}

.layui-icon-github:before {
    content: "\e6a7"
}

.layui-icon-disabled:before {
    content: "\e6cc"
}

.layui-icon-moon:before {
    content: "\e6c2"
}

.layui-icon-error:before {
    content: "\e693"
}

.layui-icon-success:before {
    content: "\e697"
}

.layui-icon-question:before {
    content: "\e699"
}

.layui-icon-lock:before {
    content: "\e69a"
}

.layui-icon-eye:before {
    content: "\e695"
}

.layui-icon-eye-invisible:before {
    content: "\e696"
}

.layui-icon-backspace:before {
    content: "\e694"
}

.layui-icon-tips-fill:before {
    content: "\eb2e"
}

.layui-icon-test:before {
    content: "\e692"
}

.layui-icon-clear:before {
    content: "\e788"
}

.layui-icon-heart-fill:before {
    content: "\e68f"
}

.layui-icon-light:before {
    content: "\e748"
}

.layui-icon-music:before {
    content: "\e690"
}

.layui-icon-time:before {
    content: "\e68d"
}

.layui-icon-ie:before {
    content: "\e7bb"
}

.layui-icon-firefox:before {
    content: "\e686"
}

.layui-icon-at:before {
    content: "\e687"
}

.layui-icon-bluetooth:before {
    content: "\e689"
}

.layui-icon-chrome:before {
    content: "\e68a"
}

.layui-icon-edge:before {
    content: "\e68b"
}

.layui-icon-heart:before {
    content: "\e68c"
}

.layui-icon-key:before {
    content: "\e683"
}

.layui-icon-android:before {
    content: "\e684"
}

.layui-icon-mike:before {
    content: "\e6dc"
}

.layui-icon-mute:before {
    content: "\e685"
}

.layui-icon-gift:before {
    content: "\e627"
}

.layui-icon-windows:before {
    content: "\e67f"
}

.layui-icon-ios:before {
    content: "\e680"
}

.layui-icon-logout:before {
    content: "\e682"
}

.layui-icon-wifi:before {
    content: "\e7e0"
}

.layui-icon-rss:before {
    content: "\e808"
}

.layui-icon-email:before {
    content: "\e618"
}

.layui-icon-reduce-circle:before {
    content: "\e616"
}

.layui-icon-transfer:before {
    content: "\e691"
}

.layui-icon-service:before {
    content: "\e626"
}

.layui-icon-addition:before {
    content: "\e624"
}

.layui-icon-subtraction:before {
    content: "\e67e"
}

.layui-icon-slider:before {
    content: "\e714"
}

.layui-icon-print:before {
    content: "\e66d"
}

.layui-icon-export:before {
    content: "\e67d"
}

.layui-icon-cols:before {
    content: "\e610"
}

.layui-icon-screen-full:before {
    content: "\e622"
}

.layui-icon-screen-restore:before {
    content: "\e758"
}

.layui-icon-rate-half:before {
    content: "\e6c9"
}

.layui-icon-rate-solid:before {
    content: "\e67a"
}

.layui-icon-rate:before {
    content: "\e67b"
}

.layui-icon-cellphone:before {
    content: "\e678"
}

.layui-icon-vercode:before {
    content: "\e679"
}

.layui-icon-login-weibo:before {
    content: "\e675"
}

.layui-icon-login-qq:before {
    content: "\e676"
}

.layui-icon-login-wechat:before {
    content: "\e677"
}

.layui-icon-username:before {
    content: "\e66f"
}

.layui-icon-password:before {
    content: "\e673"
}

.layui-icon-refresh-3:before {
    content: "\e9aa"
}

.layui-icon-auz:before {
    content: "\e672"
}

.layui-icon-shrink-right:before {
    content: "\e668"
}

.layui-icon-spread-left:before {
    content: "\e66b"
}

.layui-icon-snowflake:before {
    content: "\e6b1"
}

.layui-icon-tips:before {
    content: "\e702"
}

.layui-icon-note:before {
    content: "\e66e"
}

.layui-icon-senior:before {
    content: "\e674"
}

.layui-icon-refresh-1:before {
    content: "\e666"
}

.layui-icon-refresh:before {
    content: "\e669"
}

.layui-icon-flag:before {
    content: "\e66c"
}

.layui-icon-theme:before {
    content: "\e66a"
}

.layui-icon-notice:before {
    content: "\e667"
}

.layui-icon-console:before {
    content: "\e665"
}

.layui-icon-website:before {
    content: "\e7ae"
}

.layui-icon-face-surprised:before {
    content: "\e664"
}

.layui-icon-set:before {
    content: "\e716"
}

.layui-icon-template:before {
    content: "\e663"
}

.layui-icon-app:before {
    content: "\e653"
}

.layui-icon-template-1:before {
    content: "\e656"
}

.layui-icon-home:before {
    content: "\e68e"
}

.layui-icon-female:before {
    content: "\e661"
}

.layui-icon-male:before {
    content: "\e662"
}

.layui-icon-tread:before {
    content: "\e6c5"
}

.layui-icon-praise:before {
    content: "\e6c6"
}

.layui-icon-rmb:before {
    content: "\e65e"
}

.layui-icon-more:before {
    content: "\e65f"
}

.layui-icon-camera:before {
    content: "\e660"
}

.layui-icon-cart-simple:before {
    content: "\e698"
}

.layui-icon-face-cry:before {
    content: "\e69c"
}

.layui-icon-face-smile:before {
    content: "\e6af"
}

.layui-icon-survey:before {
    content: "\e6b2"
}

.layui-icon-read:before {
    content: "\e705"
}

.layui-icon-location:before {
    content: "\e715"
}

.layui-icon-dollar:before {
    content: "\e659"
}

.layui-icon-diamond:before {
    content: "\e735"
}

.layui-icon-return:before {
    content: "\e65c"
}

.layui-icon-camera-fill:before {
    content: "\e65d"
}

.layui-icon-fire:before {
    content: "\e756"
}

.layui-icon-more-vertical:before {
    content: "\e671"
}

.layui-icon-cart:before {
    content: "\e657"
}

.layui-icon-star-fill:before {
    content: "\e658"
}

.layui-icon-prev:before {
    content: "\e65a"
}

.layui-icon-next:before {
    content: "\e65b"
}

.layui-icon-upload:before {
    content: "\e67c"
}

.layui-icon-upload-drag:before {
    content: "\e681"
}

.layui-icon-user:before {
    content: "\e770"
}

.layui-icon-file-b:before {
    content: "\e655"
}

.layui-icon-component:before {
    content: "\e857"
}

.layui-icon-find-fill:before {
    content: "\e670"
}

.layui-icon-loading:before {
    content: "\e63d"
}

.layui-icon-loading-1:before {
    content: "\e63e"
}

.layui-icon-add-1:before {
    content: "\e654"
}

.layui-icon-pause:before {
    content: "\e651"
}

.layui-icon-play:before {
    content: "\e652"
}

.layui-icon-video:before {
    content: "\e6ed"
}

.layui-icon-headset:before {
    content: "\e6fc"
}

.layui-icon-voice:before {
    content: "\e688"
}

.layui-icon-speaker:before {
    content: "\e645"
}

.layui-icon-fonts-del:before {
    content: "\e64f"
}

.layui-icon-fonts-html:before {
    content: "\e64b"
}

.layui-icon-fonts-code:before {
    content: "\e64e"
}

.layui-icon-fonts-strong:before {
    content: "\e62b"
}

.layui-icon-unlink:before {
    content: "\e64d"
}

.layui-icon-picture:before {
    content: "\e64a"
}

.layui-icon-link:before {
    content: "\e64c"
}

.layui-icon-face-smile-b:before {
    content: "\e650"
}

.layui-icon-align-center:before {
    content: "\e647"
}

.layui-icon-align-right:before {
    content: "\e648"
}

.layui-icon-align-left:before {
    content: "\e649"
}

.layui-icon-fonts-u:before {
    content: "\e646"
}

.layui-icon-fonts-i:before {
    content: "\e644"
}

.layui-icon-tabs:before {
    content: "\e62a"
}

.layui-icon-circle:before {
    content: "\e63f"
}

.layui-icon-radio:before {
    content: "\e643"
}

.layui-icon-share:before {
    content: "\e641"
}

.layui-icon-edit:before {
    content: "\e642"
}

.layui-icon-delete:before {
    content: "\e640"
}

.layui-icon-engine:before {
    content: "\e628"
}

.layui-icon-chart-screen:before {
    content: "\e629"
}

.layui-icon-chart:before {
    content: "\e62c"
}

.layui-icon-table:before {
    content: "\e62d"
}

.layui-icon-tree:before {
    content: "\e62e"
}

.layui-icon-upload-circle:before {
    content: "\e62f"
}

.layui-icon-templeate-1:before {
    content: "\e630"
}

.layui-icon-util:before {
    content: "\e631"
}

.layui-icon-layouts:before {
    content: "\e632"
}

.layui-icon-prev-circle:before {
    content: "\e633"
}

.layui-icon-carousel:before {
    content: "\e634"
}

.layui-icon-code-circle:before {
    content: "\e635"
}

.layui-icon-water:before {
    content: "\e636"
}

.layui-icon-date:before {
    content: "\e637"
}

.layui-icon-layer:before {
    content: "\e638"
}

.layui-icon-fonts-clear:before {
    content: "\e639"
}

.layui-icon-dialogue:before {
    content: "\e63a"
}

.layui-icon-cellphone-fine:before {
    content: "\e63b"
}

.layui-icon-form:before {
    content: "\e63c"
}

.layui-icon-file:before {
    content: "\e621"
}

.layui-icon-triangle-r:before {
    content: "\e623"
}

.layui-icon-triangle-d:before {
    content: "\e625"
}

.layui-icon-set-sm:before {
    content: "\e620"
}

.layui-icon-add-circle:before {
    content: "\e61f"
}

.layui-icon-layim-download:before {
    content: "\e61e"
}

.layui-icon-layim-uploadfile:before {
    content: "\e61d"
}

.layui-icon-404:before {
    content: "\e61c"
}

.layui-icon-about:before {
    content: "\e60b"
}

.layui-icon-layim-theme:before {
    content: "\e61b"
}

.layui-icon-down:before {
    content: "\e61a"
}

.layui-icon-up:before {
    content: "\e619"
}

.layui-icon-circle-dot:before {
    content: "\e617"
}

.layui-icon-set-fill:before {
    content: "\e614"
}

.layui-icon-search:before {
    content: "\e615"
}

.layui-icon-friends:before {
    content: "\e612"
}

.layui-icon-group:before {
    content: "\e613"
}

.layui-icon-reply-fill:before {
    content: "\e611"
}

.layui-icon-menu-fill:before {
    content: "\e60f"
}

.layui-icon-face-smile-fine:before {
    content: "\e60c"
}

.layui-icon-picture-fine:before {
    content: "\e60d"
}

.layui-icon-log:before {
    content: "\e60e"
}

.layui-icon-list:before {
    content: "\e60a"
}

.layui-icon-release:before {
    content: "\e609"
}

.layui-icon-add-circle-fine:before {
    content: "\e608"
}

.layui-icon-ok:before {
    content: "\e605"
}

.layui-icon-help:before {
    content: "\e607"
}

.layui-icon-chat:before {
    content: "\e606"
}

.layui-icon-top:before {
    content: "\e604"
}

.layui-icon-right:before {
    content: "\e602"
}

.layui-icon-left:before {
    content: "\e603"
}

.layui-icon-star:before {
    content: "\e600"
}

.layui-icon-download-circle:before {
    content: "\e601"
}

.layui-icon-close:before {
    content: "\1006"
}

.layui-icon-close-fill:before {
    content: "\1007"
}

.layui-icon-ok-circle:before {
    content: "\1005"
}

.layui-main {
    position: relative;
    width: 1160px;
    margin: 0 auto
}

.layui-header {
    position: relative;
    z-index: 1000;
    height: 60px
}

.layui-header a:hover {
    transition: all .5s;
    -webkit-transition: all .5s
}

.layui-side {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    width: 200px;
    overflow-x: hidden
}

.layui-side-scroll {
    position: relative;
    width: 220px;
    height: 100%;
    overflow-x: hidden
}

.layui-body {
    position: relative;
    left: 200px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900;
    width: auto;
    box-sizing: border-box
}

.layui-layout-body {
    overflow-x: hidden
}

.layui-layout-admin .layui-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #23292e
}

.layui-layout-admin .layui-side {
    top: 60px;
    width: 200px;
    overflow-x: hidden
}

.layui-layout-admin .layui-body {
    position: absolute;
    top: 60px;
    padding-bottom: 44px
}

.layui-layout-admin .layui-main {
    width: auto;
    margin: 0 15px
}

.layui-layout-admin .layui-footer {
    position: fixed;
    left: 200px;
    right: 0;
    bottom: 0;
    z-index: 990;
    height: 44px;
    line-height: 44px;
    padding: 0 15px;
    box-shadow: -1px 0 4px rgb(0 0 0 / 12%);
    background-color: #fafafa
}

.layui-layout-admin .layui-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    line-height: 60px;
    text-align: center;
    color: #16baaa;
    font-size: 16px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%)
}

.layui-layout-admin .layui-header .layui-nav {
    background: 0 0
}

.layui-layout-left {
    position: absolute !important;
    left: 200px;
    top: 0
}

.layui-layout-right {
    position: absolute !important;
    right: 0;
    top: 0
}

.layui-container {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box
}

.layui-fluid {
    position: relative;
    margin: 0 auto;
    padding: 0 15px
}

.layui-row:after, .layui-row:before {
    content: "";
    display: block;
    clear: both
}

.layui-col-lg1, .layui-col-lg10, .layui-col-lg11, .layui-col-lg12, .layui-col-lg2, .layui-col-lg3, .layui-col-lg4, .layui-col-lg5, .layui-col-lg6, .layui-col-lg7, .layui-col-lg8, .layui-col-lg9, .layui-col-md1, .layui-col-md10, .layui-col-md11, .layui-col-md12, .layui-col-md2, .layui-col-md3, .layui-col-md4, .layui-col-md5, .layui-col-md6, .layui-col-md7, .layui-col-md8, .layui-col-md9, .layui-col-sm1, .layui-col-sm10, .layui-col-sm11, .layui-col-sm12, .layui-col-sm2, .layui-col-sm3, .layui-col-sm4, .layui-col-sm5, .layui-col-sm6, .layui-col-sm7, .layui-col-sm8, .layui-col-sm9, .layui-col-xl1, .layui-col-xl10, .layui-col-xl11, .layui-col-xl12, .layui-col-xl2, .layui-col-xl3, .layui-col-xl4, .layui-col-xl5, .layui-col-xl6, .layui-col-xl7, .layui-col-xl8, .layui-col-xl9, .layui-col-xs1, .layui-col-xs10, .layui-col-xs11, .layui-col-xs12, .layui-col-xs2, .layui-col-xs3, .layui-col-xs4, .layui-col-xs5, .layui-col-xs6, .layui-col-xs7, .layui-col-xs8, .layui-col-xs9 {
    position: relative;
    display: block;
    box-sizing: border-box
}

.layui-col-xs1, .layui-col-xs10, .layui-col-xs11, .layui-col-xs12, .layui-col-xs2, .layui-col-xs3, .layui-col-xs4, .layui-col-xs5, .layui-col-xs6, .layui-col-xs7, .layui-col-xs8, .layui-col-xs9 {
    float: left
}

.layui-col-xs1 {
    width: 8.33333333%
}

.layui-col-xs2 {
    width: 16.66666667%
}

.layui-col-xs3 {
    width: 25%
}

.layui-col-xs4 {
    width: 33.33333333%
}

.layui-col-xs5 {
    width: 41.66666667%
}

.layui-col-xs6 {
    width: 50%
}

.layui-col-xs7 {
    width: 58.33333333%
}

.layui-col-xs8 {
    width: 66.66666667%
}

.layui-col-xs9 {
    width: 75%
}

.layui-col-xs10 {
    width: 83.33333333%
}

.layui-col-xs11 {
    width: 91.66666667%
}

.layui-col-xs12 {
    width: 100%
}

.layui-col-xs-offset1 {
    margin-left: 8.33333333%
}

.layui-col-xs-offset2 {
    margin-left: 16.66666667%
}

.layui-col-xs-offset3 {
    margin-left: 25%
}

.layui-col-xs-offset4 {
    margin-left: 33.33333333%
}

.layui-col-xs-offset5 {
    margin-left: 41.66666667%
}

.layui-col-xs-offset6 {
    margin-left: 50%
}

.layui-col-xs-offset7 {
    margin-left: 58.33333333%
}

.layui-col-xs-offset8 {
    margin-left: 66.66666667%
}

.layui-col-xs-offset9 {
    margin-left: 75%
}

.layui-col-xs-offset10 {
    margin-left: 83.33333333%
}

.layui-col-xs-offset11 {
    margin-left: 91.66666667%
}

.layui-col-xs-offset12 {
    margin-left: 100%
}

@media screen and (max-width: 767.98px) {
    .layui-container {
        padding: 0 15px
    }

    .layui-hide-xs {
        display: none !important
    }

    .layui-show-xs-block {
        display: block !important
    }

    .layui-show-xs-inline {
        display: inline !important
    }

    .layui-show-xs-inline-block {
        display: inline-block !important
    }
}

@media screen and (min-width: 768px) {
    .layui-container {
        width: 720px
    }

    .layui-hide-sm {
        display: none !important
    }

    .layui-show-sm-block {
        display: block !important
    }

    .layui-show-sm-inline {
        display: inline !important
    }

    .layui-show-sm-inline-block {
        display: inline-block !important
    }

    .layui-col-sm1, .layui-col-sm10, .layui-col-sm11, .layui-col-sm12, .layui-col-sm2, .layui-col-sm3, .layui-col-sm4, .layui-col-sm5, .layui-col-sm6, .layui-col-sm7, .layui-col-sm8, .layui-col-sm9 {
        float: left
    }

    .layui-col-sm1 {
        width: 8.33333333%
    }

    .layui-col-sm2 {
        width: 16.66666667%
    }

    .layui-col-sm3 {
        width: 25%
    }

    .layui-col-sm4 {
        width: 33.33333333%
    }

    .layui-col-sm5 {
        width: 41.66666667%
    }

    .layui-col-sm6 {
        width: 50%
    }

    .layui-col-sm7 {
        width: 58.33333333%
    }

    .layui-col-sm8 {
        width: 66.66666667%
    }

    .layui-col-sm9 {
        width: 75%
    }

    .layui-col-sm10 {
        width: 83.33333333%
    }

    .layui-col-sm11 {
        width: 91.66666667%
    }

    .layui-col-sm12 {
        width: 100%
    }

    .layui-col-sm-offset1 {
        margin-left: 8.33333333%
    }

    .layui-col-sm-offset2 {
        margin-left: 16.66666667%
    }

    .layui-col-sm-offset3 {
        margin-left: 25%
    }

    .layui-col-sm-offset4 {
        margin-left: 33.33333333%
    }

    .layui-col-sm-offset5 {
        margin-left: 41.66666667%
    }

    .layui-col-sm-offset6 {
        margin-left: 50%
    }

    .layui-col-sm-offset7 {
        margin-left: 58.33333333%
    }

    .layui-col-sm-offset8 {
        margin-left: 66.66666667%
    }

    .layui-col-sm-offset9 {
        margin-left: 75%
    }

    .layui-col-sm-offset10 {
        margin-left: 83.33333333%
    }

    .layui-col-sm-offset11 {
        margin-left: 91.66666667%
    }

    .layui-col-sm-offset12 {
        margin-left: 100%
    }
}

@media screen and (min-width: 992px) {
    .layui-container {
        width: 960px
    }

    .layui-hide-md {
        display: none !important
    }

    .layui-show-md-block {
        display: block !important
    }

    .layui-show-md-inline {
        display: inline !important
    }

    .layui-show-md-inline-block {
        display: inline-block !important
    }

    .layui-col-md1, .layui-col-md10, .layui-col-md11, .layui-col-md12, .layui-col-md2, .layui-col-md3, .layui-col-md4, .layui-col-md5, .layui-col-md6, .layui-col-md7, .layui-col-md8, .layui-col-md9 {
        float: left
    }

    .layui-col-md1 {
        width: 8.33333333%
    }

    .layui-col-md2 {
        width: 16.66666667%
    }

    .layui-col-md3 {
        width: 25%
    }

    .layui-col-md4 {
        width: 33.33333333%
    }

    .layui-col-md5 {
        width: 41.66666667%
    }

    .layui-col-md6 {
        width: 50%
    }

    .layui-col-md7 {
        width: 58.33333333%
    }

    .layui-col-md8 {
        width: 66.66666667%
    }

    .layui-col-md9 {
        width: 75%
    }

    .layui-col-md10 {
        width: 83.33333333%
    }

    .layui-col-md11 {
        width: 91.66666667%
    }

    .layui-col-md12 {
        width: 100%
    }

    .layui-col-md-offset1 {
        margin-left: 8.33333333%
    }

    .layui-col-md-offset2 {
        margin-left: 16.66666667%
    }

    .layui-col-md-offset3 {
        margin-left: 25%
    }

    .layui-col-md-offset4 {
        margin-left: 33.33333333%
    }

    .layui-col-md-offset5 {
        margin-left: 41.66666667%
    }

    .layui-col-md-offset6 {
        margin-left: 50%
    }

    .layui-col-md-offset7 {
        margin-left: 58.33333333%
    }

    .layui-col-md-offset8 {
        margin-left: 66.66666667%
    }

    .layui-col-md-offset9 {
        margin-left: 75%
    }

    .layui-col-md-offset10 {
        margin-left: 83.33333333%
    }

    .layui-col-md-offset11 {
        margin-left: 91.66666667%
    }

    .layui-col-md-offset12 {
        margin-left: 100%
    }
}

@media screen and (min-width: 1200px) {
    .layui-container {
        width: 1150px
    }

    .layui-hide-lg {
        display: none !important
    }

    .layui-show-lg-block {
        display: block !important
    }

    .layui-show-lg-inline {
        display: inline !important
    }

    .layui-show-lg-inline-block {
        display: inline-block !important
    }

    .layui-col-lg1, .layui-col-lg10, .layui-col-lg11, .layui-col-lg12, .layui-col-lg2, .layui-col-lg3, .layui-col-lg4, .layui-col-lg5, .layui-col-lg6, .layui-col-lg7, .layui-col-lg8, .layui-col-lg9 {
        float: left
    }

    .layui-col-lg1 {
        width: 8.33333333%
    }

    .layui-col-lg2 {
        width: 16.66666667%
    }

    .layui-col-lg3 {
        width: 25%
    }

    .layui-col-lg4 {
        width: 33.33333333%
    }

    .layui-col-lg5 {
        width: 41.66666667%
    }

    .layui-col-lg6 {
        width: 50%
    }

    .layui-col-lg7 {
        width: 58.33333333%
    }

    .layui-col-lg8 {
        width: 66.66666667%
    }

    .layui-col-lg9 {
        width: 75%
    }

    .layui-col-lg10 {
        width: 83.33333333%
    }

    .layui-col-lg11 {
        width: 91.66666667%
    }

    .layui-col-lg12 {
        width: 100%
    }

    .layui-col-lg-offset1 {
        margin-left: 8.33333333%
    }

    .layui-col-lg-offset2 {
        margin-left: 16.66666667%
    }

    .layui-col-lg-offset3 {
        margin-left: 25%
    }

    .layui-col-lg-offset4 {
        margin-left: 33.33333333%
    }

    .layui-col-lg-offset5 {
        margin-left: 41.66666667%
    }

    .layui-col-lg-offset6 {
        margin-left: 50%
    }

    .layui-col-lg-offset7 {
        margin-left: 58.33333333%
    }

    .layui-col-lg-offset8 {
        margin-left: 66.66666667%
    }

    .layui-col-lg-offset9 {
        margin-left: 75%
    }

    .layui-col-lg-offset10 {
        margin-left: 83.33333333%
    }

    .layui-col-lg-offset11 {
        margin-left: 91.66666667%
    }

    .layui-col-lg-offset12 {
        margin-left: 100%
    }
}

@media screen and (min-width: 1400px) {
    .layui-container {
        width: 1330px
    }

    .layui-hide-xl {
        display: none !important
    }

    .layui-show-xl-block {
        display: block !important
    }

    .layui-show-xl-inline {
        display: inline !important
    }

    .layui-show-xl-inline-block {
        display: inline-block !important
    }

    .layui-col-xl1, .layui-col-xl10, .layui-col-xl11, .layui-col-xl12, .layui-col-xl2, .layui-col-xl3, .layui-col-xl4, .layui-col-xl5, .layui-col-xl6, .layui-col-xl7, .layui-col-xl8, .layui-col-xl9 {
        float: left
    }

    .layui-col-xl1 {
        width: 8.33333333%
    }

    .layui-col-xl2 {
        width: 16.66666667%
    }

    .layui-col-xl3 {
        width: 25%
    }

    .layui-col-xl4 {
        width: 33.33333333%
    }

    .layui-col-xl5 {
        width: 41.66666667%
    }

    .layui-col-xl6 {
        width: 50%
    }

    .layui-col-xl7 {
        width: 58.33333333%
    }

    .layui-col-xl8 {
        width: 66.66666667%
    }

    .layui-col-xl9 {
        width: 75%
    }

    .layui-col-xl10 {
        width: 83.33333333%
    }

    .layui-col-xl11 {
        width: 91.66666667%
    }

    .layui-col-xl12 {
        width: 100%
    }

    .layui-col-xl-offset1 {
        margin-left: 8.33333333%
    }

    .layui-col-xl-offset2 {
        margin-left: 16.66666667%
    }

    .layui-col-xl-offset3 {
        margin-left: 25%
    }

    .layui-col-xl-offset4 {
        margin-left: 33.33333333%
    }

    .layui-col-xl-offset5 {
        margin-left: 41.66666667%
    }

    .layui-col-xl-offset6 {
        margin-left: 50%
    }

    .layui-col-xl-offset7 {
        margin-left: 58.33333333%
    }

    .layui-col-xl-offset8 {
        margin-left: 66.66666667%
    }

    .layui-col-xl-offset9 {
        margin-left: 75%
    }

    .layui-col-xl-offset10 {
        margin-left: 83.33333333%
    }

    .layui-col-xl-offset11 {
        margin-left: 91.66666667%
    }

    .layui-col-xl-offset12 {
        margin-left: 100%
    }
}

.layui-col-space1 {
    margin: -.5px
}

.layui-col-space1 > * {
    padding: .5px
}

.layui-col-space2 {
    margin: -1px
}

.layui-col-space2 > * {
    padding: 1px
}

.layui-col-space4 {
    margin: -2px
}

.layui-col-space4 > * {
    padding: 2px
}

.layui-col-space5 {
    margin: -2.5px
}

.layui-col-space5 > * {
    padding: 2.5px
}

.layui-col-space6 {
    margin: -3px
}

.layui-col-space6 > * {
    padding: 3px
}

.layui-col-space8 {
    margin: -4px
}

.layui-col-space8 > * {
    padding: 4px
}

.layui-col-space10 {
    margin: -5px
}

.layui-col-space10 > * {
    padding: 5px
}

.layui-col-space12 {
    margin: -6px
}

.layui-col-space12 > * {
    padding: 6px
}

.layui-col-space14 {
    margin: -7px
}

.layui-col-space14 > * {
    padding: 7px
}

.layui-col-space15 {
    margin: -7.5px
}

.layui-col-space15 > * {
    padding: 7.5px
}

.layui-col-space16 {
    margin: -8px
}

.layui-col-space16 > * {
    padding: 8px
}

.layui-col-space18 {
    margin: -9px
}

.layui-col-space18 > * {
    padding: 9px
}

.layui-col-space20 {
    margin: -10px
}

.layui-col-space20 > * {
    padding: 10px
}

.layui-col-space22 {
    margin: -11px
}

.layui-col-space22 > * {
    padding: 11px
}

.layui-col-space24 {
    margin: -12px
}

.layui-col-space24 > * {
    padding: 12px
}

.layui-col-space25 {
    margin: -12.5px
}

.layui-col-space25 > * {
    padding: 12.5px
}

.layui-col-space26 {
    margin: -13px
}

.layui-col-space26 > * {
    padding: 13px
}

.layui-col-space28 {
    margin: -14px
}

.layui-col-space28 > * {
    padding: 14px
}

.layui-col-space30 {
    margin: -15px
}

.layui-col-space30 > * {
    padding: 15px
}

.layui-col-space32 {
    margin: -15px
}

.layui-col-space32 > * {
    padding: 15px
}

.layui-btn, .layui-input, .layui-select, .layui-textarea, .layui-upload-button {
    outline: 0;
    -webkit-appearance: none;
    transition: all .3s;
    -webkit-transition: all .3s;
    box-sizing: border-box
}

.layui-elem-quote {
    margin-bottom: 10px;
    padding: 15px;
    line-height: 1.8;
    border-left: 5px solid #16b777;
    border-radius: 0 2px 2px 0;
    background-color: #fafafa
}

.layui-quote-nm {
    border-style: solid;
    border-width: 1px;
    border-left-width: 5px;
    background: 0 0
}

.layui-elem-field {
    margin-bottom: 10px;
    padding: 0;
    border-width: 1px;
    border-style: solid
}

.layui-elem-field legend {
    margin-left: 20px;
    padding: 0 10px;
    font-size: 20px
}

.layui-field-title {
    margin: 16px 0;
    border-width: 0;
    border-top-width: 1px
}

.layui-field-box {
    padding: 15px
}

.layui-field-title .layui-field-box {
    padding: 10px 0
}

.layui-progress {
    position: relative;
    height: 6px;
    border-radius: 20px;
    background-color: #eee
}

.layui-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    max-width: 100%;
    height: 6px;
    border-radius: 20px;
    text-align: right;
    background-color: #16b777;
    transition: all .3s;
    -webkit-transition: all .3s
}

.layui-progress-big, .layui-progress-big .layui-progress-bar {
    height: 18px;
    line-height: 18px
}

.layui-progress-text {
    position: relative;
    top: -20px;
    line-height: 18px;
    font-size: 12px;
    color: #5f5f5f
}

.layui-progress-big .layui-progress-text {
    position: static;
    padding: 0 10px;
    color: #fff
}

.layui-collapse {
    border-width: 1px;
    border-style: solid;
    border-radius: 2px
}

.layui-colla-content, .layui-colla-item {
    border-top-width: 1px;
    border-top-style: solid
}

.layui-colla-item:first-child {
    border-top: none
}

.layui-colla-title {
    position: relative;
    height: 42px;
    line-height: 42px;
    padding: 0 15px 0 35px;
    color: #333;
    background-color: #fafafa;
    cursor: pointer;
    font-size: 14px;
    overflow: hidden
}

.layui-colla-content {
    display: none;
    padding: 10px 15px;
    line-height: 1.6;
    color: #5f5f5f
}

.layui-colla-icon {
    position: absolute;
    left: 15px;
    top: 0;
    font-size: 14px
}

.layui-card {
    margin-bottom: 15px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05)
}

.layui-card:last-child {
    margin-bottom: 0
}

.layui-card-header {
    position: relative;
    height: 42px;
    line-height: 42px;
    padding: 0 15px;
    border-bottom: 1px solid #f8f8f8;
    color: #333;
    border-radius: 2px 2px 0 0;
    font-size: 14px
}

.layui-card-body {
    position: relative;
    padding: 10px 15px;
    line-height: 24px
}

.layui-card-body[pad15] {
    padding: 15px
}

.layui-card-body[pad20] {
    padding: 20px
}

.layui-card-body .layui-table {
    margin: 5px 0
}

.layui-card .layui-tab {
    margin: 0
}

.layui-panel {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 8%);
    background-color: #fff;
    color: #5f5f5f
}

.layui-panel-window {
    position: relative;
    padding: 15px;
    border-radius: 0;
    border-top: 5px solid #eee;
    background-color: #fff
}

.layui-auxiliar-moving {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: 0 0;
    z-index: 9999999999
}

.layui-scollbar-hide {
    overflow: hidden !important
}

.layui-bg-red {
    background-color: #ff5722 !important;
    color: #fff !important
}

.layui-bg-orange {
    background-color: #ffb800 !important;
    color: #fff !important
}

.layui-bg-green {
    background-color: #16baaa !important;
    color: #fff !important
}

.layui-bg-cyan {
    background-color: #2f4056 !important;
    color: #fff !important
}

.layui-bg-blue {
    background-color: #1e9fff !important;
    color: #fff !important
}

.layui-bg-purple {
    background-color: #a233c6 !important;
    color: #fff !important
}

.layui-bg-black {
    background-color: #2f363c !important;
    color: #fff !important
}

.layui-bg-gray {
    background-color: #fafafa !important;
    color: #5f5f5f !important
}

.layui-badge-rim, .layui-border, .layui-colla-content, .layui-colla-item, .layui-collapse, .layui-elem-field, .layui-form-pane .layui-form-item[pane], .layui-form-pane .layui-form-label, .layui-input, .layui-input-split, .layui-panel, .layui-quote-nm, .layui-select, .layui-tab-bar, .layui-tab-card, .layui-tab-title, .layui-tab-title .layui-this:after, .layui-textarea {
    border-color: #eee
}

.layui-border {
    border-width: 1px;
    border-style: solid;
    color: #5f5f5f !important
}

.layui-border-red {
    border-width: 1px;
    border-style: solid;
    border-color: #ff5722 !important;
    color: #ff5722 !important
}

.layui-border-orange {
    border-width: 1px;
    border-style: solid;
    border-color: #ffb800 !important;
    color: #ffb800 !important
}

.layui-border-green {
    border-width: 1px;
    border-style: solid;
    border-color: #16baaa !important;
    color: #16baaa !important
}

.layui-border-cyan {
    border-width: 1px;
    border-style: solid;
    border-color: #2f4056 !important;
    color: #2f4056 !important
}

.layui-border-blue {
    border-width: 1px;
    border-style: solid;
    border-color: #1e9fff !important;
    color: #1e9fff !important
}

.layui-border-purple {
    border-width: 1px;
    border-style: solid;
    border-color: #a233c6 !important;
    color: #a233c6 !important
}

.layui-border-black {
    border-width: 1px;
    border-style: solid;
    border-color: #2f363c !important;
    color: #2f363c !important
}

.layui-timeline-item:before {
    background-color: #eee
}

.layui-text {
    line-height: 1.8;
    font-size: 14px
}

.layui-text h1, .layui-text h2, .layui-text h3, .layui-text h4, .layui-text h5, .layui-text h6 {
    color: #3a3a3a
}

.layui-text h1 {
    font-size: 32px
}

.layui-text h2 {
    font-size: 24px
}

.layui-text h3 {
    font-size: 18px
}

.layui-text h4 {
    font-size: 16px
}

.layui-text h5 {
    font-size: 14px
}

.layui-text h6 {
    font-size: 13px
}

.layui-text ol, .layui-text ul {
    padding-left: 15px
}

.layui-text ul li {
    margin-top: 5px;
    list-style-type: disc
}

.layui-text ol li {
    margin-top: 5px;
    list-style-type: decimal
}

.layui-text-em, .layui-word-aux {
    color: #999 !important;
    padding-left: 5px !important;
    padding-right: 5px !important
}

.layui-text p {
    margin: 15px 0
}

.layui-text p:first-child {
    margin-top: 0
}

.layui-text p:last-child {
    margin-bottom: 0
}

.layui-text a:not(.layui-btn) {
    color: #01aaed
}

.layui-text a:not(.layui-btn):hover {
    text-decoration: underline
}

.layui-text blockquote:not(.layui-elem-quote) {
    padding: 5px 15px;
    border-left: 5px solid #eee
}

.layui-text pre > code:not(.layui-code) {
    padding: 15px;
    font-family: Courier New, Lucida Console, Consolas;
    background-color: #fafafa
}

.layui-font-12 {
    font-size: 12px !important
}

.layui-font-13 {
    font-size: 13px !important
}

.layui-font-14 {
    font-size: 14px !important
}

.layui-font-16 {
    font-size: 16px !important
}

.layui-font-18 {
    font-size: 18px !important
}

.layui-font-20 {
    font-size: 20px !important
}

.layui-font-22 {
    font-size: 22px !important
}

.layui-font-24 {
    font-size: 24px !important
}

.layui-font-26 {
    font-size: 26px !important
}

.layui-font-28 {
    font-size: 28px !important
}

.layui-font-30 {
    font-size: 30px !important
}

.layui-font-32 {
    font-size: 32px !important
}

.layui-font-red {
    color: #ff5722 !important
}

.layui-font-orange {
    color: #ffb800 !important
}

.layui-font-green {
    color: #16baaa !important
}

.layui-font-cyan {
    color: #2f4056 !important
}

.layui-font-blue {
    color: #01aaed !important
}

.layui-font-purple {
    color: #a233c6 !important
}

.layui-font-black {
    color: #000 !important
}

.layui-font-gray {
    color: #c2c2c2 !important
}

.layui-btn {
    display: inline-block;
    vertical-align: middle;
    height: 38px;
    line-height: 38px;
    border: 1px solid transparent;
    padding: 0 18px;
    background-color: #16baaa;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.layui-btn:hover {
    opacity: .8;
    filter: alpha(opacity=80);
    color: #fff
}

.layui-btn:active {
    opacity: 1;
    filter: alpha(opacity=100)
}

.layui-btn + .layui-btn {
    margin-left: 10px
}

.layui-btn-container {
    word-spacing: -5px
}

.layui-btn-container .layui-btn {
    margin-right: 10px;
    margin-bottom: 10px;
    word-spacing: normal
}

.layui-btn-container .layui-btn + .layui-btn {
    margin-left: 0
}

.layui-table .layui-btn-container .layui-btn {
    margin-bottom: 9px
}

.layui-btn-radius {
    border-radius: 100px
}

.layui-btn .layui-icon {
    padding: 0 2px;
    vertical-align: middle \0;
    vertical-align: bottom
}

.layui-btn-primary {
    border-color: #d2d2d2;
    background: 0 0;
    color: #5f5f5f
}

.layui-btn-primary:hover {
    border-color: #16baaa;
    color: #333
}

.layui-btn-normal {
    background-color: #1e9fff
}

.layui-btn-warm {
    background-color: #ffb800
}

.layui-btn-danger {
    background-color: #ff5722
}

.layui-btn-checked {
    background-color: #16b777
}

.layui-btn-disabled, .layui-btn-disabled:active, .layui-btn-disabled:hover {
    border-color: #eee !important;
    background-color: #fbfbfb !important;
    color: #d2d2d2 !important;
    cursor: not-allowed !important;
    opacity: 1
}

.layui-btn-lg {
    height: 44px;
    line-height: 44px;
    padding: 0 25px;
    font-size: 16px
}

.layui-btn-sm {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px
}

.layui-btn-xs {
    height: 22px;
    line-height: 22px;
    padding: 0 5px;
    font-size: 12px
}

.layui-btn-xs i {
    font-size: 12px !important
}

.layui-btn-group {
    display: inline-block;
    vertical-align: middle;
    font-size: 0
}

.layui-btn-group .layui-btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-radius: 0
}

.layui-btn-group .layui-btn-primary {
    border-left: none
}

.layui-btn-group .layui-btn-primary:hover {
    border-color: #d2d2d2;
    color: #16baaa
}

.layui-btn-group .layui-btn:first-child {
    border-left: none;
    border-radius: 2px 0 0 2px
}

.layui-btn-group .layui-btn-primary:first-child {
    border-left: 1px solid #d2d2d2
}

.layui-btn-group .layui-btn:last-child {
    border-radius: 0 2px 2px 0
}

.layui-btn-group .layui-btn + .layui-btn {
    margin-left: 0
}

.layui-btn-group + .layui-btn-group {
    margin-left: 10px
}

.layui-btn-fluid {
    width: 100%
}

.layui-input, .layui-select, .layui-textarea {
    height: 38px;
    line-height: 1.3;
    line-height: 38px \9;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    color: rgba(0, 0, 0, .85);
    border-radius: 2px
}

.layui-input::-webkit-input-placeholder, .layui-select::-webkit-input-placeholder, .layui-textarea::-webkit-input-placeholder {
    line-height: 1.3
}

.layui-input, .layui-textarea {
    display: block;
    width: 100%;
    padding-left: 10px
}

.layui-input:hover, .layui-textarea:hover {
    border-color: #d2d2d2 !important
}

.layui-input:focus, .layui-textarea:focus {
    border-color: #d2d2d2 !important
}

.layui-textarea {
    position: relative;
    min-height: 100px;
    height: auto;
    line-height: 20px;
    padding: 6px 10px;
    resize: vertical
}

.layui-select {
    padding: 0 10px
}

.layui-form input[type=checkbox], .layui-form input[type=radio], .layui-form select {
    display: none
}

.layui-form [lay-ignore] {
    display: initial
}

.layui-form-item {
    position: relative;
    margin-bottom: 15px;
    clear: both;

}

.layui-form-item:after {
    content: '\20';
    clear: both;
    ;
    display: block;
    height: 0
}

.layui-form-label {
    position: relative;
    float: left;
    display: block;
    padding: 9px 15px;
    width: 80px;
    font-weight: 400;
    line-height: 20px;
    text-align: right
}

.layui-form-label-col {
    display: block;
    float: none;
    padding: 9px 0;
    line-height: 20px;
    text-align: left
}

.layui-form-item .layui-inline {
    margin-bottom: 5px;
    margin-right: 10px
}

.layui-input-block, .layui-input-inline {
    position: relative
}

.layui-input-block {
    margin-left: 110px;
    min-height: 36px
}

.layui-input-inline {
    display: inline-block;
    vertical-align: middle
}

.layui-form-item .layui-input-inline {
    float: left;
    width: 190px;
    margin-right: 10px
}

.layui-form-text .layui-input-inline {
    width: auto
}

.layui-form-mid {
    position: relative;
    float: left;
    display: block;
    padding: 9px 0 !important;
    line-height: 20px;
    margin-right: 10px
}

.layui-form-danger + .layui-form-select .layui-input, .layui-form-danger:focus {
    border-color: #ff5722 !important
}

.layui-input-prefix, .layui-input-split, .layui-input-suffix, .layui-input-suffix .layui-input-affix {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 10px;
    width: 35px;
    height: 100%;
    text-align: center;
    transition: all .3s;
    box-sizing: border-box
}

.layui-input-prefix {
    left: 0;
    border-radius: 2px 0 0 2px
}

.layui-input-suffix {
    right: 0;
    border-radius: 0 2px 2px 0
}

.layui-input-split {
    border-width: 1px;
    border-style: solid
}

.layui-input-prefix .layui-icon, .layui-input-split .layui-icon, .layui-input-suffix .layui-icon {
    position: relative;
    font-size: 16px;
    color: #5f5f5f;
    transition: all .3s
}

.layui-input-group {
    position: relative;
    display: table;
    box-sizing: border-box
}

.layui-input-group > * {
    display: table-cell;
    vertical-align: middle;
    position: relative
}

.layui-input-group .layui-input {
    padding-right: 15px
}

.layui-input-group .layui-input-prefix {
    width: auto;
    border-right: 0
}

.layui-input-group .layui-input-suffix {
    width: auto;
    border-left: 0
}

.layui-input-group .layui-input-split {
    white-space: nowrap
}

.layui-input-wrap {
    position: relative;
    line-height: 38px
}

.layui-input-wrap .layui-input {
    padding-right: 35px
}

.layui-input-wrap .layui-input::-ms-clear, .layui-input-wrap .layui-input::-ms-reveal {
    display: none
}

.layui-input-wrap .layui-input-prefix + .layui-input, .layui-input-wrap .layui-input-prefix ~ * .layui-input {
    padding-left: 35px
}

.layui-input-wrap .layui-input-split + .layui-input, .layui-input-wrap .layui-input-split ~ * .layui-input {
    padding-left: 45px
}

.layui-input-wrap .layui-input-prefix ~ .layui-form-select {
    position: static
}

.layui-input-wrap .layui-input-prefix, .layui-input-wrap .layui-input-split, .layui-input-wrap .layui-input-suffix {
    pointer-events: none
}

.layui-input-wrap .layui-input:focus + .layui-input-split {
    border-color: #d2d2d2
}

.layui-input-wrap .layui-input-prefix.layui-input-split {
    border-width: 0;
    border-right-width: 1px
}

.layui-input-affix {
    line-height: 38px
}

.layui-input-suffix .layui-input-affix {
    right: auto;
    left: -35px
}

.layui-input-affix .layui-icon {
    color: rgba(0, 0, 0, .8);
    pointer-events: auto !important;
    cursor: pointer
}

.layui-input-affix .layui-icon-clear {
    color: rgba(0, 0, 0, .3)
}

.layui-input-affix .layui-icon:hover {
    color: rgba(0, 0, 0, .6)
}

.layui-form-select {
    position: relative;
    color: #5f5f5f
}

.layui-form-select .layui-input {
    padding-right: 30px;
    cursor: pointer
}

.layui-form-select .layui-edge {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
    cursor: pointer;
    border-width: 6px;
    border-top-color: #c2c2c2;
    border-top-style: solid;
    transition: all .3s;
    -webkit-transition: all .3s
}

.layui-form-select dl {
    display: none;
    position: absolute;
    left: 0;
    top: 42px;
    padding: 5px 0;
    z-index: 899;
    min-width: 100%;
    border: 1px solid #eee;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 8%);
    box-sizing: border-box
}

.layui-form-select dl dd, .layui-form-select dl dt {
    padding: 0 10px;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.layui-form-select dl dt {
    font-size: 12px;
    color: #999
}

.layui-form-select dl dd {
    cursor: pointer
}

.layui-form-select dl dd:hover {
    background-color: #f8f8f8;
    -webkit-transition: .5s all;
    transition: .5s all
}

.layui-form-select .layui-select-group dd {
    padding-left: 20px
}

.layui-form-select dl dd.layui-select-tips {
    padding-left: 10px !important;
    color: #999
}

.layui-form-select dl dd.layui-this {
    background-color: #f8f8f8;
    color: #16b777;
    font-weight: 700
}

.layui-form-select dl dd.layui-disabled {
    background-color: #fff
}

.layui-form-selected dl {
    display: block
}

.layui-form-selected .layui-edge {
    margin-top: -9px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.layui-form-selected .layui-edge {
    margin-top: -3px \0
}

:root .layui-form-selected .layui-edge {
    margin-top: -9px \0/ IE9
}

.layui-form-selectup dl {
    top: auto;
    bottom: 42px
}

.layui-select-none {
    margin: 5px 0;
    text-align: center;
    color: #999
}

.layui-select-disabled .layui-disabled {
    border-color: #eee !important
}

.layui-select-disabled .layui-edge {
    border-top-color: #d2d2d2
}

.layui-form-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    padding-right: 30px;
    background-color: #fff;
    cursor: pointer;
    font-size: 0;
    -webkit-transition: .1s linear;
    transition: .1s linear;
    box-sizing: border-box
}

.layui-form-checkbox > * {
    display: inline-block;
    vertical-align: middle
}

.layui-form-checkbox > div {
    padding: 0 11px;
    font-size: 14px;
    border-radius: 2px 0 0 2px;
    background-color: #d2d2d2;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.layui-form-checkbox:hover > div {
    background-color: #c2c2c2
}

.layui-form-checkbox > i {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 100%;
    border: 1px solid #d2d2d2;
    border-left: none;
    border-radius: 0 2px 2px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0);
    font-size: 20px;
    text-align: center;
    box-sizing: border-box
}

.layui-form-checkbox:hover > i {
    border-color: #c2c2c2;
    color: #c2c2c2
}

.layui-form-checked, .layui-form-checked:hover {
    border-color: #16b777
}

.layui-form-checked:hover > div, .layui-form-checked > div {
    background-color: #16b777
}

.layui-form-checked:hover > i, .layui-form-checked > i {
    color: #16b777
}

.layui-form-item .layui-form-checkbox {
    margin-top: 4px
}

.layui-form-checkbox.layui-checkbox-disabled > div {
    background-color: #eee !important
}

.layui-form [lay-checkbox] {
    display: none
}

.layui-form-checkbox[lay-skin=primary] {
    height: auto !important;
    line-height: normal !important;
    min-width: 18px;
    min-height: 18px;
    border: none !important;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 0;
    background: 0 0
}

.layui-form-checkbox[lay-skin=primary] > div {
    margin-top: -1px;
    padding-left: 0;
    padding-right: 15px;
    line-height: 18px;
    background: 0 0;
    color: #5f5f5f
}

.layui-form-checkbox[lay-skin=primary] > i {
    right: auto;
    left: 0;
    width: 16px;
    height: 16px;
    line-height: 14px;
    border: 1px solid #d2d2d2;
    font-size: 12px;
    border-radius: 2px;
    background-color: #fff;
    -webkit-transition: .1s linear;
    transition: .1s linear
}

.layui-form-checkbox[lay-skin=primary]:hover > i {
    border-color: #16b777;
    color: #fff
}

.layui-form-checked[lay-skin=primary] > i {
    border-color: #16b777 !important;
    background-color: #16b777;
    color: #fff
}

.layui-checkbox-disabled[lay-skin=primary] > div {
    background: 0 0 !important
}

.layui-form-checked.layui-checkbox-disabled[lay-skin=primary] > i {
    background: #eee !important;
    border-color: #eee !important
}

.layui-checkbox-disabled[lay-skin=primary]:hover > i {
    border-color: #d2d2d2
}

.layui-form-item .layui-form-checkbox[lay-skin=primary] {
    margin-top: 10px
}

.layui-form-checkbox[lay-skin=primary] > .layui-icon-indeterminate {
    border-color: #16b777
}

.layui-form-checkbox[lay-skin=primary] > .layui-icon-indeterminate:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 50%;
    height: 1px;
    margin: -1px auto 0;
    background-color: #16b777
}

.layui-form-switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    line-height: 22px;
    min-width: 44px;
    padding: 0 5px;
    margin-top: 8px;
    border: 1px solid #d2d2d2;
    border-radius: 20px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-transition: .1s linear;
    transition: .1s linear
}

.layui-form-switch > i {
    position: absolute;
    left: 5px;
    top: 3px;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background-color: #d2d2d2;
    -webkit-transition: .1s linear;
    transition: .1s linear
}

.layui-form-switch > div {
    position: relative;
    top: 0;
    margin-left: 21px;
    padding: 0 !important;
    text-align: center !important;
    color: #999 !important;
    font-style: normal !important;
    font-size: 12px
}

.layui-form-onswitch {
    border-color: #16b777;
    background-color: #16b777
}

.layui-form-onswitch > i {
    left: 100%;
    margin-left: -21px;
    background-color: #fff
}

.layui-form-onswitch > div {
    margin-left: 0;
    margin-right: 21px;
    color: #fff !important
}

.layui-checkbox-disabled {
    border-color: #eee !important
}

.layui-checkbox-disabled > div {
    color: #c2c2c2 !important
}

.layui-checkbox-disabled > i {
    border-color: #eee !important
}

.layui-checkbox-disabled:hover > i {
    color: #fff !important
}

.layui-form-radio {
    display: inline-block;
    vertical-align: middle;
    line-height: 28px;
    margin: 6px 10px 0 0;
    padding-right: 10px;
    cursor: pointer;
    font-size: 0
}

.layui-form-radio > * {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px
}

.layui-form-radio > i {
    margin-right: 8px;
    font-size: 22px;
    color: #c2c2c2
}

.layui-form-radio:hover > *, .layui-form-radioed, .layui-form-radioed > i {
    color: #16b777
}

.layui-radio-disabled > i {
    color: #eee !important
}

.layui-radio-disabled > * {
    color: #c2c2c2 !important
}

.layui-form [lay-radio] {
    display: none
}

.layui-form-pane .layui-form-label {
    width: 110px;
    padding: 8px 15px;
    height: 38px;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px 0 0 2px;
    text-align: center;
    background-color: #fafafa;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box
}

.layui-form-pane .layui-input-inline {
    margin-left: -1px
}

.layui-form-pane .layui-input-block {
    margin-left: 110px;
    left: -1px
}

.layui-form-pane .layui-input {
    border-radius: 0 2px 2px 0
}

.layui-form-pane .layui-form-text .layui-form-label {
    float: none;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    text-align: left
}

.layui-form-pane .layui-form-text .layui-input-inline {
    display: block;
    margin: 0;
    top: -1px;
    clear: both
}

.layui-form-pane .layui-form-text .layui-input-block {
    margin: 0;
    left: 0;
    top: -1px
}

.layui-form-pane .layui-form-text .layui-textarea {
    min-height: 100px;
    border-radius: 0 0 2px 2px
}

.layui-form-pane .layui-form-checkbox {
    margin: 4px 0 4px 10px
}

.layui-form-pane .layui-form-radio, .layui-form-pane .layui-form-switch {
    margin-top: 6px;
    margin-left: 10px
}

.layui-form-pane .layui-form-item[pane] {
    position: relative;
    border-width: 1px;
    border-style: solid
}

.layui-form-pane .layui-form-item[pane] .layui-form-label {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-width: 0;
    border-right-width: 1px
}

.layui-form-pane .layui-form-item[pane] .layui-input-inline {
    margin-left: 110px
}

@media screen and (max-width: 450px) {
    .layui-form-item .layui-form-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap
    }

    .layui-form-item .layui-inline {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
        clear: both
    }

    .layui-form-item .layui-inline:after {
        content: '\20';
        clear: both;
        display: block;
        height: 0
    }

    .layui-form-item .layui-input-inline {
        display: block;
        float: none;
        left: -3px;
        width: auto !important;
        margin: 0 0 10px 112px
    }

    .layui-form-item .layui-input-inline + .layui-form-mid {
        margin-left: 110px;
        top: -5px;
        padding: 0
    }

    .layui-form-item .layui-form-checkbox {
        margin-right: 5px;
        margin-bottom: 5px
    }
}

.layui-laypage {
    display: inline-block;

    ;
    vertical-align: middle;
    margin: 10px 0;
    font-size: 0
}

.layui-laypage > a:first-child, .layui-laypage > a:first-child em {
    border-radius: 2px 0 0 2px
}

.layui-laypage > a:last-child, .layui-laypage > a:last-child em {
    border-radius: 0 2px 2px 0
}

.layui-laypage > :first-child {
    margin-left: 0 !important
}

.layui-laypage > :last-child {
    margin-right: 0 !important
}

.layui-laypage a, .layui-laypage button, .layui-laypage input, .layui-laypage select, .layui-laypage span {
    border: 1px solid #eee
}

.layui-laypage a, .layui-laypage span {
    display: inline-block;

    ;
    vertical-align: middle;
    padding: 0 15px;
    height: 28px;
    line-height: 28px;
    margin: 0 -1px 5px 0;
    background-color: #fff;
    color: #333;
    font-size: 12px
}

.layui-laypage a[data-page] {
    color: #333
}

.layui-laypage a {
    text-decoration: none !important;
    cursor: pointer
}

.layui-laypage a:hover {
    color: #16baaa
}

.layui-laypage em {
    font-style: normal
}

.layui-laypage .layui-laypage-spr {
    color: #999;
    font-weight: 700
}

.layui-laypage .layui-laypage-curr {
    position: relative
}

.layui-laypage .layui-laypage-curr em {
    position: relative;
    color: #fff
}

.layui-laypage .layui-laypage-curr .layui-laypage-em {
    position: absolute;
    left: -1px;
    top: -1px;
    padding: 1px;
    width: 100%;
    height: 100%;
    background-color: #16baaa
}

.layui-laypage-em {
    border-radius: 2px
}

.layui-laypage-next em, .layui-laypage-prev em {
    font-family: Sim sun;
    font-size: 16px
}

.layui-laypage .layui-laypage-count, .layui-laypage .layui-laypage-limits, .layui-laypage .layui-laypage-refresh, .layui-laypage .layui-laypage-skip {
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
    border: none
}

.layui-laypage .layui-laypage-limits, .layui-laypage .layui-laypage-refresh {
    vertical-align: top
}

.layui-laypage .layui-laypage-refresh i {
    font-size: 18px;
    cursor: pointer
}

.layui-laypage select {
    height: 22px;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer
}

.layui-laypage .layui-laypage-skip {
    height: 30px;
    line-height: 30px;
    color: #999
}

.layui-laypage button, .layui-laypage input {
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    vertical-align: top;
    background-color: #fff;
    box-sizing: border-box
}

.layui-laypage input {
    display: inline-block;
    width: 40px;
    margin: 0 10px;
    padding: 0 3px;
    text-align: center
}

.layui-laypage input:focus, .layui-laypage select:focus {
    border-color: #16baaa !important
}

.layui-laypage button {
    margin-left: 10px;
    padding: 0 10px;
    cursor: pointer
}

.layui-flow-more {
    margin: 10px 0;
    text-align: center;
    color: #999;
    font-size: 14px;
    clear: both
}

.layui-flow-more a {
    height: 32px;
    line-height: 32px
}

.layui-flow-more a * {
    display: inline-block;
    vertical-align: top
}

.layui-flow-more a cite {
    padding: 0 20px;
    border-radius: 3px;
    background-color: #eee;
    color: #333;
    font-style: normal
}

.layui-flow-more a cite:hover {
    opacity: .8
}

.layui-flow-more a i {
    font-size: 30px;
    color: #737383
}

.layui-table {
    width: 100%;
    margin: 10px 0;
    background-color: #fff;
    color: #5f5f5f
}

.layui-table tr {
    transition: all .3s;
    -webkit-transition: all .3s
}

.layui-table th {
    text-align: left;
    font-weight: 600
}

.layui-table-mend {
    background-color: #fff
}

.layui-table-click, .layui-table-hover, .layui-table[lay-even] tbody tr:nth-child(even) {
    background-color: #f8f8f8
}

.layui-table td, .layui-table th, .layui-table-col-set, .layui-table-fixed-r, .layui-table-grid-down, .layui-table-header, .layui-table-mend, .layui-table-page, .layui-table-tips-main, .layui-table-tool, .layui-table-total, .layui-table-view, .layui-table[lay-skin=line], .layui-table[lay-skin=row] {
    border-width: 1px;
    border-style: solid;
    border-color: #eee
}

.layui-table td, .layui-table th {
    position: relative;
    padding: 9px 15px;
    min-height: 20px;
    line-height: 20px;
    font-size: 14px
}

.layui-table[lay-skin=line] td, .layui-table[lay-skin=line] th {
    border-width: 0;
    border-bottom-width: 1px
}

.layui-table[lay-skin=row] td, .layui-table[lay-skin=row] th {
    border-width: 0;
    border-right-width: 1px
}

.layui-table[lay-skin=nob] td, .layui-table[lay-skin=nob] th {
    border: none
}

.layui-table img {
    max-width: 100px
}

.layui-table[lay-size=lg] td, .layui-table[lay-size=lg] th {
    padding-top: 15px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-left: 30px
}

.layui-table-view .layui-table[lay-size=lg] .layui-table-cell {
    height: 50px;
    line-height: 40px
}

.layui-table[lay-size=sm] td, .layui-table[lay-size=sm] th {
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 12px
}

.layui-table-view .layui-table[lay-size=sm] .layui-table-cell {
    height: 30px;
    line-height: 20px;
    padding-top: 5px;
    padding-left: 11px;
    padding-right: 11px
}

.layui-table[lay-data], .layui-table[lay-options] {
    display: none
}

.layui-table-box {
    position: relative;
    overflow: hidden
}

.layui-table-view {
    clear: both
}

.layui-table-view .layui-table {
    position: relative;
    width: auto;
    margin: 0;
    border: 0;
    border-collapse: separate
}

.layui-table-view .layui-table[lay-skin=line] {
    border-width: 0;
    border-right-width: 1px
}

.layui-table-view .layui-table[lay-skin=row] {
    border-width: 0;
    border-bottom-width: 1px
}

.layui-table-view .layui-table td, .layui-table-view .layui-table th {
    padding: 0;
    border-top: none;
    border-left: none
}

.layui-table-view .layui-table th.layui-unselect .layui-table-cell span {
    cursor: pointer
}

.layui-table-view .layui-table td {
    cursor: default
}

.layui-table-view .layui-table td[data-edit] {
    cursor: text
}

.layui-table-view .layui-table td[data-edit]:hover:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #16b777;
    pointer-events: none;
    content: ""
}

.layui-table-view .layui-form-checkbox[lay-skin=primary] i {
    width: 18px;
    height: 18px;
    line-height: 16px
}

.layui-table-view .layui-form-radio {
    line-height: 0;
    padding: 0
}

.layui-table-view .layui-form-radio > i {
    margin: 0;
    font-size: 20px
}

.layui-table-init {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 199
}

.layui-table-init .layui-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    font-size: 30px;
    color: #c2c2c2
}

.layui-table-header {
    border-width: 0;
    border-bottom-width: 1px;
    overflow: hidden
}

.layui-table-header .layui-table {
    margin-bottom: -1px
}

.layui-table-column {
    position: relative;
    width: 100%;
    min-height: 41px;
    padding: 8px 16px;
    border-width: 0;
    border-bottom-width: 1px
}

.layui-table-column .layui-btn-container {
    margin-bottom: -8px
}

.layui-table-column .layui-btn-container .layui-btn {
    margin-right: 8px;
    margin-bottom: 8px
}

.layui-table-tool .layui-inline[lay-event] {
    position: relative;
    width: 26px;
    height: 26px;
    padding: 5px;
    line-height: 16px;
    margin-right: 10px;
    text-align: center;
    color: #333;
    border: 1px solid #ccc;
    cursor: pointer;
    -webkit-transition: .5s all;
    transition: .5s all
}

.layui-table-tool .layui-inline[lay-event]:hover {
    border: 1px solid #999
}

.layui-table-tool-temp {
    padding-right: 120px
}

.layui-table-tool-self {
    position: absolute;
    right: 17px;
    top: 10px
}

.layui-table-tool .layui-table-tool-self .layui-inline[lay-event] {
    margin: 0 0 0 10px
}

.layui-table-tool-panel {
    position: absolute;
    top: 29px;
    left: -1px;
    z-index: 399;
    padding: 5px 0 !important;
    min-width: 150px;
    min-height: 40px;
    border: 1px solid #d2d2d2;
    text-align: left;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12)
}

.layui-table-tool-panel li {
    padding: 0 10px;
    margin: 0 !important;
    line-height: 30px;
    list-style-type: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: .5s all;
    transition: .5s all
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin=primary] {
    width: 100%
}

.layui-table-tool-panel li:hover {
    background-color: #f8f8f8
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin=primary] {
    padding-left: 28px
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin=primary] i {
    position: absolute;
    left: 0;
    top: 0
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin=primary] span {
    padding: 0
}

.layui-table-tool .layui-table-tool-self .layui-table-tool-panel {
    left: auto;
    right: -1px
}

.layui-table-col-set {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 100%;
    border-width: 0;
    border-left-width: 1px;
    background-color: #fff
}

.layui-table-sort {
    width: 10px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer !important
}

.layui-table-sort .layui-edge {
    position: absolute;
    left: 5px;
    border-width: 5px
}

.layui-table-sort .layui-table-sort-asc {
    top: 3px;
    border-top: none;
    border-bottom-style: solid;
    border-bottom-color: #b2b2b2
}

.layui-table-sort .layui-table-sort-asc:hover {
    border-bottom-color: #5f5f5f
}

.layui-table-sort .layui-table-sort-desc {
    bottom: 5px;
    border-bottom: none;
    border-top-style: solid;
    border-top-color: #b2b2b2
}

.layui-table-sort .layui-table-sort-desc:hover {
    border-top-color: #5f5f5f
}

.layui-table-sort[lay-sort=asc] .layui-table-sort-asc {
    border-bottom-color: #000
}

.layui-table-sort[lay-sort=desc] .layui-table-sort-desc {
    border-top-color: #000
}

.layui-table-cell {
    height: 38px;
    line-height: 28px;
    padding: 6px 15px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box
}

.layui-table-cell .layui-form-checkbox[lay-skin=primary] {
    top: -1px;
    padding: 0
}

.layui-table-cell .layui-table-link {
    color: #01aaed
}

.layui-table-cell .layui-btn {
    vertical-align: inherit
}

.layui-table-cell[align=center] {
    -webkit-box-pack: center
}

.layui-table-cell[align=right] {
    -webkit-box-pack: end
}

.laytable-cell-checkbox, .laytable-cell-numbers, .laytable-cell-radio, .laytable-cell-space {
    text-align: center;
    -webkit-box-pack: center
}

.layui-table-body {
    position: relative;
    overflow: auto;
    margin-right: -1px;
    margin-bottom: -1px
}

.layui-table-body .layui-none {
    line-height: 26px;
    padding: 30px 15px;
    text-align: center;
    color: #999
}

.layui-table-fixed {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 101
}

.layui-table-fixed .layui-table-body {
    overflow: hidden
}

.layui-table-fixed-l {
    box-shadow: 1px 0 8px rgba(0, 0, 0, .08)
}

.layui-table-fixed-r {
    left: auto;
    right: -1px;
    border-width: 0;
    border-left-width: 1px;
    box-shadow: -1px 0 8px rgba(0, 0, 0, .08)
}

.layui-table-fixed-r .layui-table-header {
    position: relative;
    overflow: visible
}

.layui-table-mend {
    position: absolute;
    right: -49px;
    top: 0;
    height: 100%;
    width: 50px;
    border-width: 0;
    border-left-width: 1px
}

.layui-table-tool {
    position: relative;
    width: 100%;
    min-height: 50px;
    line-height: 30px;
    padding: 10px 15px;
    border-width: 0;
    border-bottom-width: 1px
}

.layui-table-tool .layui-btn-container {
    margin-bottom: -10px
}

.layui-table-total {
    margin-bottom: -1px;
    border-width: 0;
    border-top-width: 1px;
    overflow: hidden
}

.layui-table-page {
    border-width: 0;
    border-top-width: 1px;
    margin-bottom: -1px;
    white-space: nowrap;
    overflow: hidden
}

.layui-table-page > div {
    height: 26px
}

.layui-table-page .layui-laypage {
    margin: 0
}

.layui-table-page .layui-laypage a, .layui-table-page .layui-laypage span {
    height: 26px;
    line-height: 26px;
    margin-bottom: 10px;
    border: none;
    background: 0 0
}

.layui-table-page .layui-laypage a, .layui-table-page .layui-laypage span.layui-laypage-curr {
    padding: 0 12px
}

.layui-table-page .layui-laypage span {
    margin-left: 0;
    padding: 0
}

.layui-table-page .layui-laypage .layui-laypage-prev {
    margin-left: -11px !important
}

.layui-table-page .layui-laypage .layui-laypage-curr .layui-laypage-em {
    left: 0;
    top: 0;
    padding: 0
}

.layui-table-page .layui-laypage button, .layui-table-page .layui-laypage input {
    height: 26px;
    line-height: 26px
}

.layui-table-page .layui-laypage input {
    width: 40px
}

.layui-table-page .layui-laypage button {
    padding: 0 10px
}

.layui-table-page select {
    height: 18px
}

.layui-table-pagebar {
    float: right;
    line-height: 23px
}

.layui-table-pagebar .layui-btn-sm {
    margin-top: -1px
}

.layui-table-pagebar .layui-btn-xs {
    margin-top: 2px
}

.layui-table-view select[lay-ignore] {
    display: inline-block
}

.layui-table-patch .layui-table-cell {
    padding: 0;
    width: 30px
}

.layui-table-edit {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 189;
    min-width: 100%;
    min-height: 100%;
    padding: 5px 14px;
    border-radius: 0;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, .15);
    background-color: #fff
}

.layui-table-edit:focus {
    border-color: #16b777 !important
}

input.layui-input.layui-table-edit {
    height: 100%
}

select.layui-table-edit {
    padding: 0 0 0 10px;
    border-color: #d2d2d2
}

.layui-table-view .layui-form-checkbox, .layui-table-view .layui-form-radio, .layui-table-view .layui-form-switch {
    top: 0;
    margin: 0
}

.layui-table-view .layui-form-checkbox {
    top: -1px;
    height: 26px;
    line-height: 26px
}

.layui-table-view .layui-form-checkbox i {
    height: 26px
}

.layui-table-grid .layui-table-cell {
    overflow: visible
}

.layui-table-grid-down {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 100%;
    padding: 5px 0;
    border-width: 0;
    border-left-width: 1px;
    text-align: center;
    background-color: #fff;
    color: #999;
    cursor: pointer
}

.layui-table-grid-down .layui-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px
}

.layui-table-grid-down:hover {
    background-color: #fbfbfb
}

body .layui-table-tips .layui-layer-content {
    background: 0 0;
    padding: 0;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .12)
}

.layui-table-tips-main {
    margin: -49px 0 0 -1px;
    max-height: 150px;
    padding: 8px 15px;
    font-size: 14px;
    overflow-y: scroll;
    background-color: #fff;
    color: #5f5f5f
}

.layui-table-tips-c {
    position: absolute;
    right: -3px;
    top: -13px;
    width: 20px;
    height: 20px;
    padding: 3px;
    cursor: pointer;
    background-color: #5f5f5f;
    border-radius: 50%;
    color: #fff
}

.layui-table-tips-c:hover {
    background-color: #777
}

.layui-table-tips-c:before {
    position: relative;
    right: -2px
}

.layui-table-tree-nodeIcon {
    max-width: 20px
}

.layui-table-tree-nodeIcon > * {
    width: 100%
}

.layui-table-tree-flexIcon, .layui-table-tree-nodeIcon {
    margin-right: 2px
}

.layui-table-tree-flexIcon {
    cursor: pointer
}

.layui-upload-file {
    display: none !important;
    opacity: .01;
    filter: Alpha(opacity=1)
}

.layui-upload-list {
    margin: 11px 0
}

.layui-upload-choose {
    max-width: 200px;
    padding: 0 10px;
    color: #999;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.layui-upload-drag {
    position: relative;
    display: inline-block;
    padding: 30px;
    border: 1px dashed #e2e2e2;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    color: #999
}

.layui-upload-drag .layui-icon {
    font-size: 50px;
    color: #16baaa
}

.layui-upload-drag[lay-over] {
    border-color: #16baaa
}

.layui-upload-form {
    display: inline-block
}

.layui-upload-iframe {
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    visibility: hidden
}

.layui-upload-wrap {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.layui-upload-wrap .layui-upload-file {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    font-size: 100px;
    width: 100%;
    height: 100%;
    opacity: .01;
    filter: Alpha(opacity=1);
    cursor: pointer
}

.layui-btn-container .layui-upload-choose {
    padding-left: 0
}

.layui-menu {
    position: relative;
    margin: 5px 0;
    background-color: #fff;
    box-sizing: border-box
}

.layui-menu * {
    box-sizing: border-box
}

.layui-menu li, .layui-menu-body-title, .layui-menu-body-title a {
    padding: 5px 15px;
    color: initial
}

.layui-menu li {
    position: relative;
    margin: 1px 0;
    line-height: 26px;
    color: rgba(0, 0, 0, .8);
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s
}

.layui-menu li:hover {
    background-color: #f8f8f8
}

.layui-menu li.layui-disabled, .layui-menu li.layui-disabled * {
    background: 0 0 !important;
    color: #d2d2d2 !important;
    cursor: not-allowed !important
}

.layui-menu-item-parent:hover > .layui-menu-body-panel {
    display: block;
    animation-name: layui-fadein;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-delay: .2s
}

.layui-menu-item-group > .layui-menu-body-title, .layui-menu-item-parent > .layui-menu-body-title {
    padding-right: 38px
}

.layui-menu .layui-menu-item-divider:hover, .layui-menu .layui-menu-item-group:hover, .layui-menu .layui-menu-item-none:hover {
    background: 0 0;
    cursor: default
}

.layui-menu .layui-menu-item-group > ul {
    margin: 5px 0 -5px
}

.layui-menu .layui-menu-item-group > .layui-menu-body-title {
    color: rgba(0, 0, 0, .35);
    user-select: none
}

.layui-menu .layui-menu-item-none {
    color: rgba(0, 0, 0, .35);
    cursor: default
}

.layui-menu .layui-menu-item-none {
    text-align: center
}

.layui-menu .layui-menu-item-divider {
    margin: 5px 0;
    padding: 0;
    height: 0;
    line-height: 0;
    border-bottom: 1px solid #eee;
    overflow: hidden
}

.layui-menu .layui-menu-item-down:hover, .layui-menu .layui-menu-item-up:hover {
    cursor: pointer
}

.layui-menu .layui-menu-item-up > .layui-menu-body-title {
    color: rgba(0, 0, 0, .8)
}

.layui-menu .layui-menu-item-up > ul {
    visibility: hidden;
    height: 0;
    overflow: hidden
}

.layui-menu .layui-menu-item-down:hover > .layui-menu-body-title > .layui-icon, .layui-menu .layui-menu-item-up > .layui-menu-body-title:hover > .layui-icon {
    color: #000
}

.layui-menu .layui-menu-item-down > ul {
    visibility: visible;
    height: auto
}

.layui-menu .layui-menu-item-checked, .layui-menu .layui-menu-item-checked2 {
    background-color: #f8f8f8 !important;
    color: #16b777
}

.layui-menu .layui-menu-item-checked a, .layui-menu .layui-menu-item-checked2 a {
    color: #16b777
}

.layui-menu .layui-menu-item-checked:after {
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    border-right: 3px solid #16b777;
    content: ""
}

.layui-menu-body-title {
    position: relative;
    margin: -5px -15px;
    overflow: hidden;
    text-overflow: ellipsis
}

.layui-menu-body-title a {
    display: block;
    margin: -5px -15px;
    color: rgba(0, 0, 0, .8)
}

.layui-menu-body-title a:hover {
    transition: all .3s
}

.layui-menu-body-title > .layui-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -6px;
    line-height: normal;
    font-size: 14px
}

.layui-menu-body-title > .layui-icon:hover {
    transition: all .3s
}

.layui-menu-body-title > .layui-icon-right {
    right: 14px
}

.layui-menu-body-panel {
    display: none;
    position: absolute;
    top: -7px;
    left: 100%;
    z-index: 1000;
    margin-left: 13px;
    padding: 5px 0
}

.layui-menu-body-panel:before {
    content: "";
    position: absolute;
    width: 20px;
    left: -16px;
    top: 0;
    bottom: 0
}

.layui-menu-body-panel-left {
    left: auto;
    right: 100%;
    margin: 0 13px 0
}

.layui-menu-body-panel-left:before {
    left: auto;
    right: -16px
}

.layui-menu-lg li {
    line-height: 32px
}

.layui-menu-lg .layui-menu-body-title a:hover, .layui-menu-lg li:hover {
    background: 0 0;
    color: #16b777
}

.layui-menu-lg li .layui-menu-body-panel {
    margin-left: 14px
}

.layui-menu-lg li .layui-menu-body-panel-left {
    margin: 0 15px 0
}

.layui-dropdown {
    position: absolute;
    left: -999999px;
    top: -999999px;
    z-index: 77777777;
    margin: 5px 0;
    min-width: 100px
}

.layui-dropdown:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 6px;
    left: 0;
    top: -6px
}

.layui-dropdown-shade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    _height: expression(document.body.offsetHeight+"px");
    position: fixed;
    _position: absolute;
    pointer-events: auto
}

.layui-nav {
    position: relative;
    padding: 0 15px;
    background-color: #2f363c;
    color: #fff;
    border-radius: 2px;
    font-size: 0;
    box-sizing: border-box
}

.layui-nav * {
    font-size: 14px
}

.layui-nav .layui-nav-item {
    position: relative;
    display: inline-block;

    ;
    margin-top: 0;
    list-style: none;
    vertical-align: middle;
    line-height: 60px
}

.layui-nav .layui-nav-item a {
    display: block;
    padding: 0 20px;
    color: #fff;
    color: rgba(255, 255, 255, .7);
    transition: all .3s;
    -webkit-transition: all .3s
}

.layui-nav .layui-this:after, .layui-nav-bar {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 3px;
    background-color: #16b777;
    transition: all .2s;
    -webkit-transition: all .2s;
    pointer-events: none
}

.layui-nav-bar {
    z-index: 1000
}

.layui-nav[lay-bar=disabled] .layui-nav-bar {
    display: none
}

.layui-nav .layui-nav-item a:hover, .layui-nav .layui-this a {
    color: #fff;
    text-decoration: none
}

.layui-nav .layui-this:after {
    top: auto;
    bottom: 0;
    width: 100%
}

.layui-nav-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%
}

.layui-nav .layui-nav-more {
    position: absolute;
    top: 0;
    right: 3px;
    left: auto !important;
    margin-top: 0;
    font-size: 12px;
    cursor: pointer;
    transition: all .2s;
    -webkit-transition: all .2s
}

.layui-nav .layui-nav-mored, .layui-nav-itemed > a .layui-nav-more {
    transform: rotate(180deg)
}

.layui-nav-child {
    display: none;
    position: absolute;
    left: 0;
    top: 65px;
    min-width: 100%;
    line-height: 36px;
    padding: 5px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border: 1px solid #eee;
    background-color: #fff;
    z-index: 100;
    border-radius: 2px;
    white-space: nowrap;
    box-sizing: border-box
}

.layui-nav .layui-nav-child a {
    color: #5f5f5f;
    color: rgba(0, 0, 0, .8)
}

.layui-nav .layui-nav-child a:hover {
    background-color: #f8f8f8;
    color: rgba(0, 0, 0, .8)
}

.layui-nav-child dd {
    margin: 1px 0;
    position: relative
}

.layui-nav-child dd.layui-this {
    background-color: #f8f8f8;
    color: #000
}

.layui-nav-child dd.layui-this:after {
    display: none
}

.layui-nav-child-r {
    left: auto;
    right: 0
}

.layui-nav-child-c {
    text-align: center
}

.layui-nav.layui-nav-tree {
    width: 200px;
    padding: 0
}

.layui-nav-tree .layui-nav-item {
    display: block;
    width: 100%;
    line-height: 40px
}

.layui-nav-tree .layui-nav-item a {
    position: relative;
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.layui-nav-tree .layui-nav-item > a {
    padding-top: 5px;
    padding-bottom: 5px
}

.layui-nav-tree .layui-nav-more {
    right: 15px
}

.layui-nav-tree .layui-nav-item > a .layui-nav-more {
    padding: 5px 0
}

.layui-nav-tree .layui-nav-bar {
    width: 5px;
    height: 0
}

.layui-side .layui-nav-tree .layui-nav-bar {
    width: 2px
}

.layui-nav-tree .layui-nav-child dd.layui-this, .layui-nav-tree .layui-nav-child dd.layui-this a, .layui-nav-tree .layui-this, .layui-nav-tree .layui-this > a, .layui-nav-tree .layui-this > a:hover {
    background-color: #16baaa;
    color: #fff
}

.layui-nav-tree .layui-this:after {
    display: none
}

.layui-nav-itemed > a, .layui-nav-tree .layui-nav-title a, .layui-nav-tree .layui-nav-title a:hover {
    color: #fff !important
}

.layui-nav-tree .layui-nav-bar {
    background-color: #16baaa
}

.layui-nav-tree .layui-nav-child {
    position: relative;
    z-index: 0;
    top: 0;
    border: none;
    box-shadow: none
}

.layui-nav-tree .layui-nav-child dd {
    margin: 0
}

.layui-nav-tree .layui-nav-child a {
    color: #fff;
    color: rgba(255, 255, 255, .7)
}

.layui-nav-tree .layui-nav-child, .layui-nav-tree .layui-nav-child a:hover {
    background: 0 0;
    color: #fff
}

.layui-nav-itemed > .layui-nav-child {
    display: block;
    background-color: rgba(0, 0, 0, .3) !important
}

.layui-nav-itemed > .layui-nav-child > .layui-this > .layui-nav-child {
    display: block
}

.layui-nav-side {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    z-index: 999
}

.layui-nav-tree.layui-bg-gray a, .layui-nav.layui-bg-gray .layui-nav-item a {
    color: rgba(0, 0, 0, .8)
}

.layui-nav-tree.layui-bg-gray {
    padding: 6px 0
}

.layui-nav-tree.layui-bg-gray .layui-nav-itemed > a {
    color: #000 !important
}

.layui-nav.layui-bg-gray .layui-this a {
    color: #16b777
}

.layui-nav-tree.layui-bg-gray .layui-nav-itemed > .layui-nav-child {
    padding-left: 11px;
    background: 0 0 !important
}

.layui-nav-tree.layui-bg-gray .layui-nav-item > a {
    padding-top: 0;
    padding-bottom: 0
}

.layui-nav-tree.layui-bg-gray .layui-nav-item > a .layui-nav-more {
    padding: 0
}

.layui-nav-tree.layui-bg-gray .layui-nav-child dd.layui-this, .layui-nav-tree.layui-bg-gray .layui-nav-child dd.layui-this a, .layui-nav-tree.layui-bg-gray .layui-this, .layui-nav-tree.layui-bg-gray .layui-this > a {
    background: 0 0 !important;
    color: #16b777 !important;
    font-weight: 700
}

.layui-nav-tree.layui-bg-gray .layui-nav-bar {
    background-color: #16b777
}

.layui-breadcrumb {
    visibility: hidden;
    font-size: 0
}

.layui-breadcrumb > * {
    font-size: 14px
}

.layui-breadcrumb a {
    color: #999 !important
}

.layui-breadcrumb a:hover {
    color: #16b777 !important
}

.layui-breadcrumb a cite {
    color: #5f5f5f;
    font-style: normal
}

.layui-breadcrumb span[lay-separator] {
    margin: 0 10px;
    color: #999
}

.layui-tab {
    margin: 10px 0;
    text-align: left !important
}

.layui-tab[overflow] > .layui-tab-title {
    overflow: hidden
}

.layui-tab .layui-tab-title {
    position: relative;
    left: 0;
    height: 40px;
    white-space: nowrap;
    font-size: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    transition: all .2s;
    -webkit-transition: all .2s
}

.layui-tab .layui-tab-title li {
    display: inline-block;

    ;
    vertical-align: middle;
    font-size: 14px;
    transition: all .2s;
    -webkit-transition: all .2s
}

.layui-tab .layui-tab-title li {
    position: relative;
    line-height: 40px;
    min-width: 65px;
    margin: 0;
    padding: 0 15px;
    text-align: center;
    cursor: pointer
}

.layui-tab .layui-tab-title li a {
    display: block;
    padding: 0 15px;
    margin: 0 -15px
}

.layui-tab-title .layui-this {
    color: #000
}

.layui-tab-title .layui-this:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 41px;
    border-width: 1px;
    border-style: solid;
    border-bottom-color: #fff;
    border-radius: 2px 2px 0 0;
    box-sizing: border-box;
    pointer-events: none
}

.layui-tab-bar {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    width: 30px;
    height: 39px;
    line-height: 39px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    text-align: center;
    background-color: #fff;
    cursor: pointer
}

.layui-tab-bar .layui-icon {
    position: relative;
    display: inline-block;
    top: 3px;
    transition: all .3s;
    -webkit-transition: all .3s
}

.layui-tab-item {
    display: none
}

.layui-tab-more {
    padding-right: 30px;
    height: auto !important;
    white-space: normal !important
}

.layui-tab-more li.layui-this:after {
    border-bottom-color: #eee;
    border-radius: 2px
}

.layui-tab-more .layui-tab-bar .layui-icon {
    top: -2px;
    top: 3px \0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

:root .layui-tab-more .layui-tab-bar .layui-icon {
    top: -2px \0/ IE9
}

.layui-tab-content {
    padding: 15px 0
}

.layui-tab-title li .layui-tab-close {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 20px;
    margin-left: 8px;
    top: 1px;
    text-align: center;
    font-size: 14px;
    color: #c2c2c2;
    transition: all .2s;
    -webkit-transition: all .2s
}

.layui-tab-title li .layui-tab-close:hover {
    border-radius: 2px;
    background-color: #ff5722;
    color: #fff
}

.layui-tab-brief > .layui-tab-title .layui-this {
    color: #16baaa
}

.layui-tab-brief > .layui-tab-more li.layui-this:after, .layui-tab-brief > .layui-tab-title .layui-this:after {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #16b777
}

.layui-tab-brief[overflow] > .layui-tab-title .layui-this:after {
    top: -1px
}

.layui-tab-card {
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1)
}

.layui-tab-card > .layui-tab-title {
    background-color: #fafafa
}

.layui-tab-card > .layui-tab-title li {
    margin-right: -1px;
    margin-left: -1px
}

.layui-tab-card > .layui-tab-title .layui-this {
    background-color: #fff
}

.layui-tab-card > .layui-tab-title .layui-this:after {
    border-top: none;
    border-width: 1px;
    border-bottom-color: #fff
}

.layui-tab-card > .layui-tab-title .layui-tab-bar {
    height: 40px;
    line-height: 40px;
    border-radius: 0;
    border-top: none;
    border-right: none
}

.layui-tab-card > .layui-tab-more .layui-this {
    background: 0 0;
    color: #16b777
}

.layui-tab-card > .layui-tab-more .layui-this:after {
    border: none
}

.layui-timeline {
    padding-left: 5px
}

.layui-timeline-item {
    position: relative;
    padding-bottom: 20px
}

.layui-timeline-axis {
    position: absolute;
    left: -5px;
    top: 0;
    z-index: 10;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: #fff;
    color: #16b777;
    border-radius: 50%;
    text-align: center;
    cursor: pointer
}

.layui-timeline-axis:hover {
    color: #ff5722
}

.layui-timeline-item:before {
    content: "";
    position: absolute;
    left: 5px;
    top: 0;
    z-index: 0;
    width: 1px;
    height: 100%
}

.layui-timeline-item:first-child:before {
    display: block
}

.layui-timeline-item:last-child:before {
    display: none
}

.layui-timeline-content {
    padding-left: 25px
}

.layui-timeline-title {
    position: relative;
    margin-bottom: 10px;
    line-height: 22px
}

.layui-badge, .layui-badge-dot, .layui-badge-rim {
    position: relative;
    display: inline-block;
    padding: 0 6px;
    font-size: 12px;
    text-align: center;
    background-color: #ff5722;
    color: #fff;
    border-radius: 2px
}

.layui-badge {
    height: 18px;
    line-height: 18px
}

.layui-badge-dot {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%
}

.layui-badge-rim {
    height: 18px;
    line-height: 18px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    color: #5f5f5f
}

.layui-btn .layui-badge, .layui-btn .layui-badge-dot {
    margin-left: 5px
}

.layui-nav .layui-badge, .layui-nav .layui-badge-dot {
    position: absolute;
    top: 50%;
    margin: -5px 6px 0
}

.layui-nav .layui-badge {
    margin-top: -10px
}

.layui-tab-title .layui-badge, .layui-tab-title .layui-badge-dot {
    left: 5px;
    top: -2px
}

.layui-carousel {
    position: relative;
    left: 0;
    top: 0;
    background-color: #f8f8f8
}

.layui-carousel > [carousel-item] {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.layui-carousel > [carousel-item]:before {
    position: absolute;
    content: '\e63d';
    left: 50%;
    top: 50%;
    width: 100px;
    line-height: 20px;
    margin: -10px 0 0 -50px;
    text-align: center;
    color: #c2c2c2;
    font-family: layui-icon !important;
    font-size: 30px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.layui-carousel > [carousel-item] > * {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    transition-duration: .3s;
    -webkit-transition-duration: .3s
}

.layui-carousel-updown > * {
    -webkit-transition: .3s ease-in-out up;
    transition: .3s ease-in-out up
}

.layui-carousel-arrow {
    display: none \0;
    opacity: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -18px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 20px;
    border: none 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .2);
    color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    cursor: pointer
}

.layui-carousel-arrow[lay-type=add] {
    left: auto !important;
    right: 10px
}

.layui-carousel[lay-arrow=always] .layui-carousel-arrow {
    opacity: 1;
    left: 20px
}

.layui-carousel[lay-arrow=always] .layui-carousel-arrow[lay-type=add] {
    right: 20px
}

.layui-carousel[lay-arrow=none] .layui-carousel-arrow {
    display: none
}

.layui-carousel-arrow:hover, .layui-carousel-ind ul:hover {
    background-color: rgba(0, 0, 0, .35)
}

.layui-carousel:hover .layui-carousel-arrow {
    display: block \0;
    opacity: 1;
    left: 20px
}

.layui-carousel:hover .layui-carousel-arrow[lay-type=add] {
    right: 20px
}

.layui-carousel-ind {
    position: relative;
    top: -35px;
    width: 100%;
    line-height: 0 !important;
    text-align: center;
    font-size: 0
}

.layui-carousel[lay-indicator=outside] {
    margin-bottom: 30px
}

.layui-carousel[lay-indicator=outside] .layui-carousel-ind {
    top: 10px
}

.layui-carousel[lay-indicator=outside] .layui-carousel-ind ul {
    background-color: rgba(0, 0, 0, .5)
}

.layui-carousel[lay-indicator=none] .layui-carousel-ind {
    display: none
}

.layui-carousel-ind ul {
    display: inline-block;
    padding: 5px;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.layui-carousel-ind ul li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 3px;
    font-size: 14px;
    background-color: #eee;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    cursor: pointer;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.layui-carousel-ind ul li:hover {
    background-color: rgba(255, 255, 255, .7)
}

.layui-carousel-ind ul li.layui-this {
    background-color: #fff
}

.layui-carousel > [carousel-item] > .layui-carousel-next, .layui-carousel > [carousel-item] > .layui-carousel-prev, .layui-carousel > [carousel-item] > .layui-this {
    display: block
}

.layui-carousel > [carousel-item] > .layui-this {
    left: 0
}

.layui-carousel > [carousel-item] > .layui-carousel-prev {
    left: -100%
}

.layui-carousel > [carousel-item] > .layui-carousel-next {
    left: 100%
}

.layui-carousel > [carousel-item] > .layui-carousel-next.layui-carousel-left, .layui-carousel > [carousel-item] > .layui-carousel-prev.layui-carousel-right {
    left: 0
}

.layui-carousel > [carousel-item] > .layui-this.layui-carousel-left {
    left: -100%
}

.layui-carousel > [carousel-item] > .layui-this.layui-carousel-right {
    left: 100%
}

.layui-carousel[lay-anim=updown] .layui-carousel-arrow {
    left: 50% !important;
    top: 20px;
    margin: 0 0 0 -18px
}

.layui-carousel[lay-anim=updown] .layui-carousel-arrow[lay-type=add] {
    top: auto !important;
    bottom: 20px
}

.layui-carousel[lay-anim=updown] .layui-carousel-ind {
    position: absolute;
    top: 50%;
    right: 20px;
    width: auto;
    height: auto
}

.layui-carousel[lay-anim=updown] .layui-carousel-ind ul {
    padding: 3px 5px
}

.layui-carousel[lay-anim=updown] .layui-carousel-ind li {
    display: block;
    margin: 6px 0
}

.layui-carousel[lay-anim=updown] > [carousel-item] > * {
    left: 0 !important
}

.layui-carousel[lay-anim=updown] > [carousel-item] > .layui-this {
    top: 0
}

.layui-carousel[lay-anim=updown] > [carousel-item] > .layui-carousel-prev {
    top: -100%
}

.layui-carousel[lay-anim=updown] > [carousel-item] > .layui-carousel-next {
    top: 100%
}

.layui-carousel[lay-anim=updown] > [carousel-item] > .layui-carousel-next.layui-carousel-left, .layui-carousel[lay-anim=updown] > [carousel-item] > .layui-carousel-prev.layui-carousel-right {
    top: 0
}

.layui-carousel[lay-anim=updown] > [carousel-item] > .layui-this.layui-carousel-left {
    top: -100%
}

.layui-carousel[lay-anim=updown] > [carousel-item] > .layui-this.layui-carousel-right {
    top: 100%
}

.layui-carousel[lay-anim=fade] > [carousel-item] > * {
    left: 0 !important
}

.layui-carousel[lay-anim=fade] > [carousel-item] > .layui-carousel-next, .layui-carousel[lay-anim=fade] > [carousel-item] > .layui-carousel-prev {
    opacity: 0
}

.layui-carousel[lay-anim=fade] > [carousel-item] > .layui-carousel-next.layui-carousel-left, .layui-carousel[lay-anim=fade] > [carousel-item] > .layui-carousel-prev.layui-carousel-right {
    opacity: 1
}

.layui-carousel[lay-anim=fade] > [carousel-item] > .layui-this.layui-carousel-left, .layui-carousel[lay-anim=fade] > [carousel-item] > .layui-this.layui-carousel-right {
    opacity: 0
}

.layui-fixbar {
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 999999
}

.layui-fixbar li {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 1px;
    text-align: center;
    cursor: pointer;
    font-size: 30px;
    background-color: #9f9f9f;
    color: #fff;
    border-radius: 2px;
    opacity: .95
}

.layui-fixbar li:hover {
    opacity: .85
}

.layui-fixbar li:active {
    opacity: 1
}

.layui-fixbar .layui-fixbar-top {
    display: none;
    font-size: 40px
}

body .layui-util-face {
    border: none;
    background: 0 0
}

body .layui-util-face .layui-layer-content {
    padding: 0;
    background-color: #fff;
    color: #5f5f5f;
    box-shadow: none
}

.layui-util-face .layui-layer-TipsG {
    display: none
}

.layui-util-face ul {
    position: relative;
    width: 372px;
    padding: 10px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

.layui-util-face ul li {
    cursor: pointer;
    float: left;
    border: 1px solid #e8e8e8;
    height: 22px;
    width: 26px;
    overflow: hidden;
    margin: -1px 0 0 -1px;
    padding: 4px 2px;
    text-align: center
}

.layui-util-face ul li:hover {
    position: relative;
    z-index: 2;
    border: 1px solid #eb7350;
    background: #fff9ec
}

.layui-code {
    position: relative;
    margin: 10px 0;
    padding: 15px;
    line-height: 20px;
    border: 1px solid #eee;
    border-left-width: 6px;
    background-color: #fafafa;
    color: #333;
    font-family: Courier New, Lucida Console, Consolas;
    font-size: 12px
}

.layui-transfer-box, .layui-transfer-header, .layui-transfer-search {
    border-width: 0;
    border-style: solid;
    border-color: #eee
}

.layui-transfer-box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-width: 1px;
    width: 200px;
    height: 360px;
    border-radius: 2px;
    background-color: #fff
}

.layui-transfer-box .layui-form-checkbox {
    width: 100%;
    margin: 0 !important
}

.layui-transfer-header {
    height: 38px;
    line-height: 38px;
    padding: 0 11px;
    border-bottom-width: 1px
}

.layui-transfer-search {
    position: relative;
    padding: 11px;
    border-bottom-width: 1px
}

.layui-transfer-search .layui-input {
    height: 32px;
    padding-left: 30px;
    font-size: 12px
}

.layui-transfer-search .layui-icon-search {
    position: absolute;
    left: 20px;
    top: 50%;
    line-height: normal;
    margin-top: -8px;
    color: #5f5f5f
}

.layui-transfer-active {
    margin: 0 15px;
    display: inline-block;
    vertical-align: middle
}

.layui-transfer-active .layui-btn {
    display: block;
    margin: 0;
    padding: 0 15px;
    background-color: #16b777;
    border-color: #16b777;
    color: #fff
}

.layui-transfer-active .layui-btn-disabled {
    background-color: #fbfbfb;
    border-color: #eee;
    color: #d2d2d2
}

.layui-transfer-active .layui-btn:first-child {
    margin-bottom: 15px
}

.layui-transfer-active .layui-btn .layui-icon {
    margin: 0;
    font-size: 14px !important
}

.layui-transfer-data {
    padding: 5px 0;
    overflow: auto
}

.layui-transfer-data li {
    height: 32px;
    line-height: 32px;
    margin-top: 0 !important;
    padding: 0 11px;
    list-style-type: none !important
}

.layui-transfer-data li:hover {
    background-color: #f8f8f8;
    transition: .5s all
}

.layui-transfer-data .layui-none {
    padding: 15px 11px;
    text-align: center;
    color: #999
}

.layui-rate, .layui-rate * {
    display: inline-block;
    vertical-align: middle
}

.layui-rate {
    padding: 11px 6px 11px 0;
    font-size: 0
}

.layui-rate li {
    margin-top: 0 !important
}

.layui-rate li i.layui-icon {
    font-size: 20px;
    color: #ffb800
}

.layui-rate li i.layui-icon {
    margin-right: 5px;
    transition: all .3s;
    -webkit-transition: all .3s
}

.layui-rate li i:hover {
    cursor: pointer;
    transform: scale(1.12);
    -webkit-transform: scale(1.12)
}

.layui-rate[readonly] li i:hover {
    cursor: default;
    transform: scale(1)
}

.layui-colorpicker {
    width: 38px;
    height: 38px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 2px;
    line-height: 24px;
    display: inline-block;
    cursor: pointer;
    transition: all .3s;
    -webkit-transition: all .3s;
    box-sizing: border-box
}

.layui-colorpicker:hover {
    border-color: #d2d2d2
}

.layui-colorpicker.layui-colorpicker-lg {
    width: 44px;
    height: 44px;
    line-height: 30px
}

.layui-colorpicker.layui-colorpicker-sm {
    width: 30px;
    height: 30px;
    line-height: 20px;
    padding: 3px
}

.layui-colorpicker.layui-colorpicker-xs {
    width: 22px;
    height: 22px;
    line-height: 16px;
    padding: 1px
}

.layui-colorpicker-trigger-bgcolor {
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
    border-radius: 2px
}

.layui-colorpicker-trigger-span {
    display: block;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 2px;
    text-align: center
}

.layui-colorpicker-trigger-i {
    display: inline-block;
    color: #fff;
    font-size: 12px
}

.layui-colorpicker-trigger-i.layui-icon-close {
    color: #999
}

.layui-colorpicker-main {
    position: absolute;
    left: -999999px;
    top: -999999px;
    z-index: 77777777;
    width: 280px;
    margin: 5px 0;
    padding: 7px;
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12)
}

.layui-colorpicker-main-wrapper {
    height: 180px;
    position: relative
}

.layui-colorpicker-basis {
    width: 260px;
    height: 100%;
    position: relative
}

.layui-colorpicker-basis-white {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0))
}

.layui-colorpicker-basis-black {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #000, transparent)
}

.layui-colorpicker-basis-cursor {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -3px;
    cursor: pointer
}

.layui-colorpicker-side {
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 100%;
    background: linear-gradient(red, #ff0, #0f0, #0ff, #00f, #f0f, red)
}

.layui-colorpicker-side-slider {
    width: 100%;
    height: 5px;
    box-shadow: 0 0 1px #888;
    box-sizing: border-box;
    background: #fff;
    border-radius: 1px;
    border: 1px solid #f0f0f0;
    cursor: pointer;
    position: absolute;
    left: 0
}

.layui-colorpicker-main-alpha {
    display: none;
    height: 12px;
    margin-top: 7px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)
}

.layui-colorpicker-alpha-bgcolor {
    height: 100%;
    position: relative
}

.layui-colorpicker-alpha-slider {
    width: 5px;
    height: 100%;
    box-shadow: 0 0 1px #888;
    box-sizing: border-box;
    background: #fff;
    border-radius: 1px;
    border: 1px solid #f0f0f0;
    cursor: pointer;
    position: absolute;
    top: 0
}

.layui-colorpicker-main-pre {
    padding-top: 7px;
    font-size: 0
}

.layui-colorpicker-pre {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    display: inline-block;
    margin-left: 6px;
    margin-bottom: 7px;
    cursor: pointer
}

.layui-colorpicker-pre:nth-child(11n+1) {
    margin-left: 0
}

.layui-colorpicker-pre-isalpha {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)
}

.layui-colorpicker-pre.layui-this {
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, .15)
}

.layui-colorpicker-pre > div {
    height: 100%;
    border-radius: 2px
}

.layui-colorpicker-main-input {
    text-align: right;
    padding-top: 7px
}

.layui-colorpicker-main-input .layui-btn-container .layui-btn {
    margin: 0 0 0 10px
}

.layui-colorpicker-main-input div.layui-inline {
    float: left;
    margin-right: 10px;
    font-size: 14px
}

.layui-colorpicker-main-input input.layui-input {
    width: 150px;
    height: 30px;
    color: #5f5f5f
}

.layui-slider {
    height: 4px;
    background: #eee;
    border-radius: 3px;
    position: relative;
    cursor: pointer
}

.layui-slider-bar {
    border-radius: 3px;
    position: absolute;
    height: 100%
}

.layui-slider-step {
    position: absolute;
    top: 0;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.layui-slider-wrap {
    width: 36px;
    height: 36px;
    position: absolute;
    top: -16px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    text-align: center
}

.layui-slider-wrap-btn {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transition: .3s
}

.layui-slider-wrap:after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle
}

.layui-slider-wrap-btn.layui-slider-hover, .layui-slider-wrap-btn:hover {
    transform: scale(1.2)
}

.layui-slider-wrap-btn.layui-disabled:hover {
    transform: scale(1) !important
}

.layui-slider-tips {
    position: absolute;
    top: -42px;
    z-index: 77777777;
    white-space: nowrap;
    display: none;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff;
    background: #000;
    border-radius: 3px;
    height: 25px;
    line-height: 25px;
    padding: 0 10px
}

.layui-slider-tips:after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-color: #000 transparent transparent transparent
}

.layui-slider-input {
    width: 70px;
    height: 32px;
    border: 1px solid #eee;
    border-radius: 3px;
    font-size: 16px;
    line-height: 32px;
    position: absolute;
    right: 0;
    top: -14px
}

.layui-slider-input-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    border-left: 1px solid #eee
}

.layui-slider-input-btn i {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 50%;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #999
}

.layui-slider-input-btn i:first-child {
    top: 0;
    border-bottom: 1px solid #eee
}

.layui-slider-input-txt {
    height: 100%;
    font-size: 14px
}

.layui-slider-input-txt input {
    height: 100%;
    border: none
}

.layui-slider-input-btn i:hover {
    color: #16baaa
}

.layui-slider-vertical {
    width: 4px;
    margin-left: 33px
}

.layui-slider-vertical .layui-slider-bar {
    width: 4px
}

.layui-slider-vertical .layui-slider-step {
    top: auto;
    left: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.layui-slider-vertical .layui-slider-wrap {
    top: auto;
    left: -16px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.layui-slider-vertical .layui-slider-tips {
    top: auto;
    left: 2px
}

@media \0screen {
    .layui-slider-wrap-btn {
        margin-left: -20px
    }

    .layui-slider-vertical .layui-slider-wrap-btn {
        margin-left: 0;
        margin-bottom: -20px
    }

    .layui-slider-vertical .layui-slider-tips {
        margin-left: -8px
    }

    .layui-slider > span {
        margin-left: 8px
    }
}

.layui-tree {
    line-height: 22px
}

.layui-tree .layui-form-checkbox {
    margin: 0 !important
}

.layui-tree-set {
    width: 100%;
    position: relative
}

.layui-tree-pack {
    display: none;
    padding-left: 20px;
    position: relative
}

.layui-tree-line .layui-tree-pack {
    padding-left: 27px
}

.layui-tree-line .layui-tree-set .layui-tree-set:after {
    content: "";
    position: absolute;
    top: 14px;
    left: -9px;
    width: 17px;
    height: 0;
    border-top: 1px dotted #c0c4cc
}

.layui-tree-entry {
    position: relative;
    padding: 3px 0;
    height: 20px;
    white-space: nowrap
}

.layui-tree-entry:hover {
    background-color: #eee
}

.layui-tree-line .layui-tree-entry:hover {
    background-color: rgba(0, 0, 0, 0)
}

.layui-tree-line .layui-tree-entry:hover .layui-tree-txt {
    color: #999;
    text-decoration: underline;
    transition: .3s
}

.layui-tree-main {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    padding-right: 10px
}

.layui-tree-line .layui-tree-set:before {
    content: "";
    position: absolute;
    top: 0;
    left: -9px;
    width: 0;
    height: 100%;
    border-left: 1px dotted #c0c4cc
}

.layui-tree-line .layui-tree-set.layui-tree-setLineShort:before {
    height: 13px
}

.layui-tree-line .layui-tree-set.layui-tree-setHide:before {
    height: 0
}

.layui-tree-iconClick {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 20px;
    line-height: 20px;
    margin: 0 10px;
    color: #c0c4cc
}

.layui-tree-icon {
    height: 12px;
    line-height: 12px;
    width: 12px;
    text-align: center;
    border: 1px solid #c0c4cc
}

.layui-tree-iconClick .layui-icon {
    font-size: 18px
}

.layui-tree-icon .layui-icon {
    font-size: 12px;
    color: #5f5f5f
}

.layui-tree-iconArrow {
    padding: 0 5px
}

.layui-tree-iconArrow:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 3px;
    z-index: 100;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #c0c4cc;
    transition: .5s
}

.layui-tree-spread > .layui-tree-entry .layui-tree-iconClick > .layui-tree-iconArrow:after {
    transform: rotate(90deg) translate(3px, 4px)
}

.layui-tree-txt {
    display: inline-block;
    vertical-align: middle;
    color: #555
}

.layui-tree-search {
    margin-bottom: 15px;
    color: #5f5f5f
}

.layui-tree-btnGroup {
    visibility: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative
}

.layui-tree-btnGroup .layui-icon {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
    cursor: pointer
}

.layui-tree-btnGroup .layui-icon:hover {
    color: #999;
    transition: .3s
}

.layui-tree-entry:hover .layui-tree-btnGroup {
    visibility: visible
}

.layui-tree-editInput {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    line-height: 20px;
    padding: 0 3px;
    border: none;
    background-color: rgba(0, 0, 0, .05)
}

.layui-tree-emptyText {
    text-align: center;
    color: #999
}

.layui-anim {
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-duration: .3s;
    animation-fill-mode: both
}

.layui-anim.layui-icon {
    display: inline-block
}

.layui-anim-loop {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.layui-trans, .layui-trans a {
    transition: all .2s;
    -webkit-transition: all .2s
}

@-webkit-keyframes layui-rotate {
    from {
        -webkit-transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes layui-rotate {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(360deg)
    }
}

.layui-anim-rotate {
    -webkit-animation-name: layui-rotate;
    animation-name: layui-rotate;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
}

@-webkit-keyframes layui-up {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        opacity: .3
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes layui-up {
    from {
        transform: translate3d(0, 100%, 0);
        opacity: .3
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.layui-anim-up {
    -webkit-animation-name: layui-up;
    animation-name: layui-up
}

@-webkit-keyframes layui-upbit {
    from {
        -webkit-transform: translate3d(0, 15px, 0);
        opacity: .3
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes layui-upbit {
    from {
        transform: translate3d(0, 15px, 0);
        opacity: .3
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.layui-anim-upbit {
    -webkit-animation-name: layui-upbit;
    animation-name: layui-upbit
}

@keyframes layui-down {
    0% {
        opacity: .3;
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0)
    }
}

.layui-anim-down {
    animation-name: layui-down
}

@keyframes layui-downbit {
    0% {
        opacity: .3;
        transform: translate3d(0, -5px, 0)
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0)
    }
}

.layui-anim-downbit {
    animation-name: layui-downbit
}

@-webkit-keyframes layui-scale {
    0% {
        opacity: .3;
        -webkit-transform: scale(.5)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1)
    }
}

@keyframes layui-scale {
    0% {
        opacity: .3;
        -ms-transform: scale(.5);
        transform: scale(.5)
    }
    100% {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.layui-anim-scale {
    -webkit-animation-name: layui-scale;
    animation-name: layui-scale
}

@-webkit-keyframes layui-scale-spring {
    0% {
        opacity: .5;
        -webkit-transform: scale(.5)
    }
    80% {
        opacity: .8;
        -webkit-transform: scale(1.1)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1)
    }
}

@keyframes layui-scale-spring {
    0% {
        opacity: .5;
        transform: scale(.5)
    }
    80% {
        opacity: .8;
        transform: scale(1.1)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.layui-anim-scaleSpring {
    -webkit-animation-name: layui-scale-spring;
    animation-name: layui-scale-spring
}

@keyframes layui-scalesmall {
    0% {
        opacity: .3;
        transform: scale(1.5)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.layui-anim-scalesmall {
    animation-name: layui-scalesmall
}

@keyframes layui-scalesmall-spring {
    0% {
        opacity: .3;
        transform: scale(1.5)
    }
    80% {
        opacity: .8;
        transform: scale(.9)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.layui-anim-scalesmall-spring {
    animation-name: layui-scalesmall-spring
}

@-webkit-keyframes layui-fadein {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes layui-fadein {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.layui-anim-fadein {
    -webkit-animation-name: layui-fadein;
    animation-name: layui-fadein
}

@-webkit-keyframes layui-fadeout {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes layui-fadeout {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.layui-anim-fadeout {
    -webkit-animation-name: layui-fadeout;
    animation-name: layui-fadeout
}

html #layuicss-skincodecss {
    display: none;
    position: absolute;
    width: 1989px
}

.layui-code-view {
    display: block;
    position: relative;
    margin: 11px 0;
    padding: 0;
    border: 1px solid #eee;
    border-left-width: 6px;
    background-color: #fafafa;
    color: #333;
    font-family: Courier New;
    font-size: 13px
}

.layui-code-title {
    position: relative;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 12px
}

.layui-code-title > .layui-code-about {
    position: absolute;
    right: 10px;
    top: 0;
    color: #b7b7b7
}

.layui-code-about > a {
    padding-left: 10px
}

.layui-code-view > .layui-code-ol, .layui-code-view > .layui-code-ul {
    max-height: 100%;
    padding: 0 !important;
    position: relative;
    overflow: auto
}

.layui-code-view > .layui-code-ol > li {
    position: relative;
    margin-top: 0 !important;
    margin-left: 45px !important;
    line-height: 20px;
    padding: 0 10px !important;
    border-left: 1px solid #e2e2e2;
    list-style-type: decimal-leading-zero;

    background-color: #fff
}

.layui-code-view > .layui-code-ol > li:first-child, .layui-code-view > .layui-code-ul > li:first-child {
    padding-top: 10px !important
}

.layui-code-view > .layui-code-ol > li:last-child, .layui-code-view > .layui-code-ul > li:last-child {
    padding-bottom: 10px !important
}

.layui-code-view > .layui-code-ul > li {
    position: relative;
    line-height: 20px;
    padding: 0 10px !important;
    list-style-type: none;

    background-color: #fff
}

.layui-code-view pre {
    margin: 0
}

.layui-code-dark {
    border: 1px solid #0c0c0c;
    border-left-color: #3f3f3f;
    background-color: #0c0c0c;
    color: #c2be9e
}

.layui-code-dark > .layui-code-title {
    border-bottom: none
}

.layui-code-dark > .layui-code-ol > li, .layui-code-dark > .layui-code-ul > li {
    background-color: #3f3f3f;
    border-left: none
}

.layui-code-dark > .layui-code-ul > li {
    margin-left: 6px
}

.layui-code textarea {
    display: none
}

.layui-code-preview > .layui-code {
    margin: 0
}

.layui-code-preview > .layui-tab {
    position: relative;
    z-index: 1;
    margin-bottom: 0
}

.layui-code-preview > .layui-tab > .layui-tab-title {
    border-bottom: none
}

.layui-code-preview > .layui-code > .layui-code-title {
    display: none
}

.layui-code-preview .layui-code-item {
    display: none
}

.layui-code-item-preview {
    position: relative;
    padding: 16px
}

.layui-code-item-preview > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none
}

.layui-code-tools {
    position: absolute;
    right: 11px;
    top: 3px
}

.layui-code-tools > i {
    display: inline-block;
    margin-left: 6px;
    padding: 3px;
    cursor: pointer
}

.layui-code-tools > i.layui-icon-file-b {
    color: #999
}

.layui-code-tools > i:hover {
    color: #16b777
}

.layui-code-copy {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    display: none
}

.layui-code-copy .layui-icon {
    color: #777;
    transition: all .3s
}

.layui-code-copy:hover .layui-icon {
    color: #16b777
}

.layui-code-view:hover > .layui-code-copy {
    display: block
}

.layui-code-copy-offset {
    margin-right: 17px
}

.layui-code-preview > .layui-code-view > .layui-code-copy {
    display: none !important
}

.layui-code-full {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1111111;
    width: 100%;
    height: 100%;
    background-color: #fff
}

.layui-code-full .layui-code-item {
    width: 100% !important;
    border-width: 0 !important;
    border-top-width: 1px !important
}

.layui-code-full .layui-code-item, .layui-code-full .layui-code-ol, .layui-code-full .layui-code-ul {
    height: calc(100vh - 51px) !important;
    box-sizing: border-box
}

html #layuicss-laydate {
    display: none;
    position: absolute;
    width: 1989px
}

.layui-laydate * {
    margin: 0;
    padding: 0
}

.layui-laydate, .layui-laydate * {
    box-sizing: border-box
}

.layui-laydate {
    position: absolute;
    z-index: 99999999;
    margin: 5px 0;
    border-radius: 2px;
    font-size: 14px;
    line-height: normal;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.layui-laydate-main {
    width: 272px
}

.layui-laydate-content td, .layui-laydate-header *, .layui-laydate-list li {
    transition-duration: .3s;
    -webkit-transition-duration: .3s
}

.layui-laydate-shade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    _height: expression(document.body.offsetHeight+"px");
    position: fixed;
    _position: absolute;
    pointer-events: auto
}

@keyframes laydate-downbit {
    0% {
        opacity: .3;
        transform: translate3d(0, -5px, 0)
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0)
    }
}

.layui-laydate {
    animation-name: laydate-downbit
}

.layui-laydate-static {
    position: relative;
    z-index: 0;
    display: inline-block;
    margin: 0;
    -webkit-animation: none;
    animation: none
}

.laydate-ym-show .laydate-next-m, .laydate-ym-show .laydate-prev-m {
    display: none !important
}

.laydate-ym-show .laydate-next-y, .laydate-ym-show .laydate-prev-y {
    display: inline-block !important
}

.laydate-ym-show .laydate-set-ym span[lay-type=month] {
    display: none !important
}

.laydate-time-show .laydate-set-ym span[lay-type=month], .laydate-time-show .laydate-set-ym span[lay-type=year], .laydate-time-show .layui-laydate-header .layui-icon {
    display: none !important
}

.layui-laydate-header {
    position: relative;
    line-height: 30px;
    padding: 10px 70px 5px
}

.layui-laydate-header * {
    display: inline-block;
    vertical-align: bottom
}

.layui-laydate-header i {
    position: absolute;
    top: 10px;
    padding: 0 5px;
    color: #999;
    font-size: 18px;
    cursor: pointer
}

.layui-laydate-header i.laydate-prev-y {
    left: 15px
}

.layui-laydate-header i.laydate-prev-m {
    left: 45px
}

.layui-laydate-header i.laydate-next-y {
    right: 15px
}

.layui-laydate-header i.laydate-next-m {
    right: 45px
}

.laydate-set-ym {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.laydate-set-ym span {
    padding: 0 10px;
    cursor: pointer
}

.laydate-time-text {
    cursor: default !important
}

.layui-laydate-content {
    position: relative;
    padding: 10px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.layui-laydate-content table {
    border-collapse: collapse;
    border-spacing: 0
}

.layui-laydate-content td, .layui-laydate-content th {
    width: 36px;
    height: 30px;
    padding: 0;
    text-align: center
}

.layui-laydate-content th {
    font-weight: 400
}

.layui-laydate-content td {
    position: relative;
    cursor: pointer
}

.laydate-day-mark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    line-height: 30px;
    font-size: 12px;
    overflow: hidden
}

.laydate-day-mark::after {
    position: absolute;
    content: '';
    right: 2px;
    top: 2px;
    width: 5px;
    height: 5px;
    border-radius: 50%
}

.laydate-day-holidays:before {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    transform: scale(.7)
}

.laydate-day-holidays:before {
    content: '\4F11';
    color: #ff5722
}

.laydate-day-holidays[type=work]:before {
    content: '\73ED';
    color: inherit
}

.layui-laydate .layui-this .laydate-day-holidays:before {
    color: #fff
}

.layui-laydate-footer {
    position: relative;
    height: 46px;
    line-height: 26px;
    padding: 10px
}

.layui-laydate-footer span {
    display: inline-block;
    vertical-align: top;
    height: 26px;
    line-height: 24px;
    padding: 0 10px;
    border: 1px solid #c9c9c9;
    border-radius: 2px;
    background-color: #fff;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    transition: all .3s
}

.layui-laydate-footer span:hover {
    color: #16b777
}

.layui-laydate-footer span.layui-laydate-preview {
    cursor: default;
    border-color: transparent !important
}

.layui-laydate-footer span.layui-laydate-preview:hover {
    color: #777
}

.layui-laydate-footer span:first-child.layui-laydate-preview {
    padding-left: 0
}

.laydate-footer-btns {
    position: absolute;
    right: 10px;
    top: 10px
}

.laydate-footer-btns span {
    margin: 0 0 0 -1px;
    border-radius: 0
}

.laydate-footer-btns span:first-child {
    border-radius: 2px 0 0 2px
}

.laydate-footer-btns span:last-child {
    border-radius: 0 2px 2px 0
}

.layui-laydate-shortcut {
    width: 80px;
    padding: 6px 0;
    display: inline-block;
    vertical-align: top;
    overflow: auto;
    max-height: 276px;
    text-align: center
}

.layui-laydate-shortcut + .layui-laydate-main {
    display: inline-block;
    border-left: 1px solid #e2e2e2
}

.layui-laydate-shortcut > li {
    padding: 5px 8px;
    cursor: pointer;
    line-height: 18px
}

.layui-laydate .layui-laydate-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff
}

.layui-laydate .layui-laydate-list > li {
    position: relative;
    display: inline-block;
    width: 33.3%;
    height: 36px;
    line-height: 36px;
    margin: 3px 0;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    list-style: none
}

.layui-laydate .laydate-month-list > li {
    width: 25%;
    margin: 17px 0
}

.layui-laydate .laydate-time-list > li {
    height: 100%;
    margin: 0;
    line-height: normal;
    cursor: default
}

.layui-laydate .laydate-time-list p {
    position: relative;
    top: -4px;
    margin: 0;
    line-height: 29px
}

.layui-laydate .laydate-time-list ol {
    height: 181px;
    overflow: hidden
}

.layui-laydate .laydate-time-list > li:hover ol {
    overflow-y: auto
}

.layui-laydate .laydate-time-list ol li {
    width: 130%;
    padding-left: 33px;
    height: 30px;
    line-height: 30px;
    text-align: left;
    cursor: pointer
}

.layui-laydate-hint {
    position: absolute;
    top: 115px;
    left: 50%;
    width: 250px;
    margin-left: -125px;
    line-height: 20px;
    padding: 15px;
    text-align: center;
    font-size: 12px;
    color: #ff5722
}

.layui-laydate-range {
    width: 546px
}

.layui-laydate-range .layui-laydate-main {
    display: inline-block;
    vertical-align: middle;
    max-width: 50%
}

.layui-laydate-range .laydate-main-list-1 .layui-laydate-content, .layui-laydate-range .laydate-main-list-1 .layui-laydate-header {
    border-left: 1px solid #e2e2e2
}

.layui-laydate-range.layui-laydate-linkage .laydate-main-list-0 .laydate-next-m, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-0 .laydate-next-y, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .laydate-prev-m, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .laydate-prev-y {
    display: none
}

.layui-laydate, .layui-laydate-hint {
    border: 1px solid #d2d2d2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    background-color: #fff;
    color: #777
}

.layui-laydate-header {
    border-bottom: 1px solid #e2e2e2
}

.layui-laydate-header i:hover, .layui-laydate-header span:hover {
    color: #16b777
}

.layui-laydate-content {
    border-top: none 0;
    border-bottom: none 0
}

.layui-laydate-content th {
    color: #333
}

.layui-laydate-content td {
    color: #777
}

.layui-laydate-content td.laydate-day-now {
    color: #16b777
}

.layui-laydate-content td.laydate-day-now:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;
    left: 0;
    top: 0;
    border: 1px solid #16b777;
    box-sizing: border-box
}

.layui-laydate-linkage .layui-laydate-content td.laydate-selected > div {
    background-color: #00f7de
}

.layui-laydate-linkage .laydate-selected:hover > div {
    background-color: #00f7de !important
}

.layui-laydate-content td.laydate-selected:after, .layui-laydate-content td:hover:after {
    content: none
}

.layui-laydate-content td > div:hover, .layui-laydate-list li:hover, .layui-laydate-shortcut > li:hover {
    background-color: #eee;
    color: #333
}

.laydate-time-list li ol {
    margin: 0;
    padding: 0;
    border: 1px solid #e2e2e2;
    border-left-width: 0
}

.laydate-time-list li:first-child ol {
    border-left-width: 1px
}

.laydate-time-list > li:hover {
    background: 0 0
}

.layui-laydate-content .laydate-day-next, .layui-laydate-content .laydate-day-prev {
    color: #d2d2d2
}

.layui-laydate-linkage .laydate-selected.laydate-day-next > div, .layui-laydate-linkage .laydate-selected.laydate-day-prev > div {
    background-color: #f8f8f8 !important
}

.layui-laydate-footer {
    border-top: 1px solid #e2e2e2
}

.layui-laydate-hint {
    color: #ff5722
}

.laydate-day-mark::after {
    background-color: #16b777
}

.layui-laydate-content td.layui-this .laydate-day-mark::after {
    display: none
}

.layui-laydate-footer span[lay-type=date] {
    color: #16b777
}

.layui-laydate .layui-this, .layui-laydate .layui-this > div {
    background-color: #16baaa !important;
    color: #fff !important
}

.layui-laydate .laydate-disabled, .layui-laydate .laydate-disabled:hover {
    background: 0 0 !important;
    color: #d2d2d2 !important;
    cursor: not-allowed !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.layui-laydate-content td > div {
    padding: 7px 0;
    height: 100%
}

.laydate-theme-molv {
    border: none
}

.laydate-theme-molv.layui-laydate-range {
    width: 548px
}

.laydate-theme-molv .layui-laydate-main {
    width: 274px
}

.laydate-theme-molv .layui-laydate-header {
    border: none;
    background-color: #16baaa
}

.laydate-theme-molv .layui-laydate-header i, .laydate-theme-molv .layui-laydate-header span {
    color: #f6f6f6
}

.laydate-theme-molv .layui-laydate-header i:hover, .laydate-theme-molv .layui-laydate-header span:hover {
    color: #fff
}

.laydate-theme-molv .layui-laydate-content {
    border: 1px solid #e2e2e2;
    border-top: none;
    border-bottom: none
}

.laydate-theme-molv .laydate-main-list-1 .layui-laydate-content {
    border-left: none
}

.laydate-theme-molv .layui-laydate-footer {
    border: 1px solid #e2e2e2
}

.laydate-theme-grid .laydate-month-list > li, .laydate-theme-grid .laydate-year-list > li, .laydate-theme-grid .layui-laydate-content td, .laydate-theme-grid .layui-laydate-content thead {
    border: 1px solid #e2e2e2
}

.layui-laydate-linkage.laydate-theme-grid .laydate-selected, .layui-laydate-linkage.laydate-theme-grid .laydate-selected:hover {
    background-color: #f2f2f2 !important;
    color: #16baaa !important
}

.layui-laydate-linkage.laydate-theme-grid .laydate-selected.laydate-day-next, .layui-laydate-linkage.laydate-theme-grid .laydate-selected.laydate-day-prev {
    color: #d2d2d2 !important
}

.laydate-theme-grid .laydate-month-list, .laydate-theme-grid .laydate-year-list {
    margin: 1px 0 0 1px
}

.laydate-theme-grid .laydate-month-list > li, .laydate-theme-grid .laydate-year-list > li {
    margin: 0 -1px -1px 0
}

.laydate-theme-grid .laydate-year-list > li {
    height: 43px;
    line-height: 43px
}

.laydate-theme-grid .laydate-month-list > li {
    height: 71px;
    line-height: 71px
}

.laydate-theme-grid .layui-laydate-content td > div {
    height: 29px;
    margin-top: -1px
}

.laydate-theme-circle .layui-laydate-content td.layui-this > div, .laydate-theme-circle .layui-laydate-content td > div {
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    margin: 0 4px;
    padding: 0
}

.layui-laydate.laydate-theme-circle .layui-laydate-content table td.layui-this {
    background-color: transparent !important
}

.laydate-theme-grid.laydate-theme-circle .layui-laydate-content td > div {
    margin: 0 3.5px
}

.laydate-theme-fullpanel .layui-laydate-main {
    width: 526px
}

.laydate-theme-fullpanel .layui-laydate-list {
    width: 252px;
    left: 272px
}

.laydate-theme-fullpanel .laydate-set-ym span {
    display: none
}

.laydate-theme-fullpanel .laydate-time-show .laydate-set-ym span[lay-type=month], .laydate-theme-fullpanel .laydate-time-show .laydate-set-ym span[lay-type=year], .laydate-theme-fullpanel .laydate-time-show .layui-laydate-header .layui-icon {
    display: inline-block !important
}

.laydate-theme-fullpanel .laydate-btns-time {
    display: none
}

html #layuicss-layer {
    display: none;
    position: absolute;
    width: 1989px
}

.layui-layer, .layui-layer-shade {
    position: fixed;
    _position: absolute;
    pointer-events: auto
}

.layui-layer-shade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    _height: expression(document.body.offsetHeight+"px")
}

.layui-layer {
    -webkit-overflow-scrolling: touch
}

.layui-layer {
    top: 150px;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #fff;
    -webkit-background-clip: content;
    border-radius: 2px;
    box-shadow: 1px 1px 50px rgba(0, 0, 0, .3)
}

.layui-layer-close {
    position: absolute
}

.layui-layer-content {
    position: relative
}

.layui-layer-border {
    border: 1px solid #b2b2b2;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2)
}

.layui-layer-btn a, .layui-layer-setwin span {
    display: inline-block;

    ;
    vertical-align: top
}

.layui-layer-move {
    display: none;
    position: fixed;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: move;
    opacity: 0;
    filter: alpha(opacity=0);
    background-color: #fff;
    z-index: 2147483647
}

.layui-layer-resize {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 0;
    bottom: 0;
    cursor: se-resize
}

.layer-anim {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    animation-duration: .3s
}

@-webkit-keyframes layer-bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes layer-bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.layer-anim-00 {
    -webkit-animation-name: layer-bounceIn;
    animation-name: layer-bounceIn
}

@-webkit-keyframes layer-zoomInDown {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateY(-2000px);
        transform: scale(.1) translateY(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateY(60px);
        transform: scale(.475) translateY(60px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes layer-zoomInDown {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateY(-2000px);
        -ms-transform: scale(.1) translateY(-2000px);
        transform: scale(.1) translateY(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateY(60px);
        -ms-transform: scale(.475) translateY(60px);
        transform: scale(.475) translateY(60px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

.layer-anim-01 {
    -webkit-animation-name: layer-zoomInDown;
    animation-name: layer-zoomInDown
}

@-webkit-keyframes layer-fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes layer-fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.layer-anim-02 {
    -webkit-animation-name: layer-fadeInUpBig;
    animation-name: layer-fadeInUpBig
}

@-webkit-keyframes layer-zoomInLeft {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateX(-2000px);
        transform: scale(.1) translateX(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateX(48px);
        transform: scale(.475) translateX(48px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes layer-zoomInLeft {
    0% {
        opacity: 0;
        -webkit-transform: scale(.1) translateX(-2000px);
        -ms-transform: scale(.1) translateX(-2000px);
        transform: scale(.1) translateX(-2000px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(.475) translateX(48px);
        -ms-transform: scale(.475) translateX(48px);
        transform: scale(.475) translateX(48px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

.layer-anim-03 {
    -webkit-animation-name: layer-zoomInLeft;
    animation-name: layer-zoomInLeft
}

@-webkit-keyframes layer-rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0) rotate(0);
        transform: translateX(0) rotate(0)
    }
}

@keyframes layer-rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0) rotate(0);
        -ms-transform: translateX(0) rotate(0);
        transform: translateX(0) rotate(0)
    }
}

.layer-anim-04 {
    -webkit-animation-name: layer-rollIn;
    animation-name: layer-rollIn
}

@keyframes layer-fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.layer-anim-05 {
    -webkit-animation-name: layer-fadeIn;
    animation-name: layer-fadeIn
}

@-webkit-keyframes layer-shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px)
    }
}

@keyframes layer-shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        transform: translateX(-10px)
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px)
    }
}

.layer-anim-06 {
    -webkit-animation-name: layer-shake;
    animation-name: layer-shake
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes layer-slide-down {
    from {
        transform: translate3d(0, -100%, 0)
    }
    to {
        transform: translate3d(0, 0, 0)
    }
}

@keyframes layer-slide-down-out {
    from {
        transform: translate3d(0, 0, 0)
    }
    to {
        transform: translate3d(0, -100%, 0)
    }
}

.layer-anim-slide-down {
    animation-name: layer-slide-down
}

.layer-anim-slide-down-out {
    animation-name: layer-slide-down-out
}

@keyframes layer-slide-left {
    from {
        transform: translate3d(100%, 0, 0)
    }
    to {
        transform: translate3d(0, 0, 0)
    }
}

@keyframes layer-slide-left-out {
    from {
        transform: translate3d(0, 0, 0)
    }
    to {
        transform: translate3d(100%, 0, 0)
    }
}

.layer-anim-slide-left {
    animation-name: layer-slide-left
}

.layer-anim-slide-left-out {
    animation-name: layer-slide-left-out
}

@keyframes layer-slide-up {
    from {
        transform: translate3d(0, 100%, 0)
    }
    to {
        transform: translate3d(0, 0, 0)
    }
}

@keyframes layer-slide-up-out {
    from {
        transform: translate3d(0, 0, 0)
    }
    to {
        transform: translate3d(0, 100%, 0)
    }
}

.layer-anim-slide-up {
    animation-name: layer-slide-up
}

.layer-anim-slide-up-out {
    animation-name: layer-slide-up-out
}

@keyframes layer-slide-right {
    from {
        transform: translate3d(-100%, 0, 0)
    }
    to {
        transform: translate3d(0, 0, 0)
    }
}

@keyframes layer-slide-right-out {
    from {
        transform: translate3d(0, 0, 0)
    }
    to {
        transform: translate3d(-100%, 0, 0)
    }
}

.layer-anim-slide-right {
    animation-name: layer-slide-right
}

.layer-anim-slide-right-out {
    animation-name: layer-slide-right-out
}

.layui-layer-title {
    padding: 0 81px 0 16px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px 2px 0 0
}

.layui-layer-setwin {
    position: absolute;
    right: 15px;

    top: 16px;
    font-size: 0;
    line-height: initial
}

.layui-layer-setwin span {
    position: relative;
    width: 16px;
    height: 16px;
    line-height: 18px;
    margin-left: 10px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    color: #000;
    _overflow: hidden
}

.layui-layer-setwin .layui-layer-min:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 1px;
    left: 50%;
    top: 50%;
    margin: -.5px 0 0 -6px;
    background-color: #2e2d3c;
    cursor: pointer;
    _overflow: hidden
}

.layui-layer-setwin .layui-layer-min:hover:before {
    background-color: #2d93ca
}

.layui-layer-setwin .layui-layer-max:after, .layui-layer-setwin .layui-layer-max:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 9px;
    height: 9px;
    margin: -5px 0 0 -5px;
    border: 1px solid #2e2d3c
}

.layui-layer-setwin .layui-layer-max:hover:after, .layui-layer-setwin .layui-layer-max:hover:before {
    border-color: #2d93ca
}

.layui-layer-setwin .layui-layer-min:hover:before {
    background-color: #2d93ca
}

.layui-layer-setwin .layui-layer-maxmin:after, .layui-layer-setwin .layui-layer-maxmin:before {
    width: 7px;
    height: 7px;
    margin: -3px 0 0 -3px;
    background-color: #fff
}

.layui-layer-setwin .layui-layer-maxmin:after {
    z-index: 0;
    margin: -5px 0 0 -1px
}

.layui-layer-setwin .layui-layer-close {
    cursor: pointer
}

.layui-layer-setwin .layui-layer-close:hover {
    opacity: .7
}

.layui-layer-setwin .layui-layer-close2 {
    position: absolute;
    right: -28px;
    top: -28px;
    color: #fff;
    background-color: #787878;
    padding: 3px;
    border: 3px solid;
    width: 18px;
    height: 18px;
    font-size: 18px;
    font-weight: bolder;
    border-radius: 50%;
    margin-left: 0;

    _display: none
}

.layui-layer-setwin .layui-layer-close2:hover {
    opacity: unset;
    background-color: #3888f6
}

.layui-layer-btn {
    text-align: right;
    padding: 0 15px 12px;
    pointer-events: auto;
    user-select: none;
    -webkit-user-select: none
}

.layui-layer-btn a {
    height: 28px;
    line-height: 28px;
    margin: 5px 5px 0;
    padding: 0 15px;
    border: 1px solid #dedede;
    background-color: #fff;
    color: #333;
    border-radius: 2px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none
}

.layui-layer-btn a:hover {
    opacity: .9;
    text-decoration: none
}

.layui-layer-btn a:active {
    opacity: .8
}

.layui-layer-btn .layui-layer-btn0 {
    border-color: #1e9fff;
    background-color: #1e9fff;
    color: #fff
}

.layui-layer-btn-l {
    text-align: left
}

.layui-layer-btn-c {
    text-align: center
}

.layui-layer-dialog {
    min-width: 240px
}

.layui-layer-dialog .layui-layer-content {
    position: relative;
    padding: 16px;
    line-height: 24px;
    word-break: break-all;
    overflow: hidden;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto
}

.layui-layer-dialog .layui-layer-content .layui-layer-face {
    position: absolute;
    top: 18px;
    left: 16px;
    color: #959595;
    font-size: 32px;
    _left: -40px
}

.layui-layer-dialog .layui-layer-content .layui-icon-tips {
    color: #f39b12
}

.layui-layer-dialog .layui-layer-content .layui-icon-success {
    color: #16b777
}

.layui-layer-dialog .layui-layer-content .layui-icon-error {
    top: 19px;
    color: #ff5722
}

.layui-layer-dialog .layui-layer-content .layui-icon-question {
    color: #ffb800
}

.layui-layer-dialog .layui-layer-content .layui-icon-lock {
    color: #787878
}

.layui-layer-dialog .layui-layer-content .layui-icon-face-cry {
    color: #ff5722
}

.layui-layer-dialog .layui-layer-content .layui-icon-face-smile {
    color: #16b777
}

.layui-layer-rim {
    border: 6px solid #8d8d8d;
    border: 6px solid rgba(0, 0, 0, .3);
    border-radius: 5px;
    box-shadow: none
}

.layui-layer-msg {
    min-width: 180px;
    border: 1px solid #d3d4d3;
    box-shadow: none
}

.layui-layer-hui {
    min-width: 100px;
    background-color: #000;
    filter: alpha(opacity=60);
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    border: none
}

.layui-layer-hui .layui-layer-close {
    color: #fff
}

.layui-layer-hui .layui-layer-content {
    padding: 11px 24px;
    text-align: center
}

.layui-layer-dialog .layui-layer-padding {
    padding: 18px 24px 18px 58px;
    text-align: left
}

.layui-layer-page .layui-layer-content {
    position: relative;
    overflow: auto
}

.layui-layer-iframe .layui-layer-btn, .layui-layer-page .layui-layer-btn {
    padding-top: 10px
}

.layui-layer-nobg {
    background: 0 0
}

.layui-layer-iframe iframe {
    display: block;
    width: 100%
}

.layui-layer-loading {
    border-radius: 100%;
    background: 0 0;
    box-shadow: none;
    border: none
}

.layui-layer-loading .layui-layer-content {
    width: 76px;
    height: 38px;
    line-height: 38px;
    text-align: center
}

.layui-layer-loading-icon {
    font-size: 38px;
    color: #959595
}

.layui-layer-loading2 {
    text-align: center
}

.layui-layer-loading-2 {
    position: relative;
    height: 38px
}

.layui-layer-loading-2:after, .layui-layer-loading-2:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 38px;
    height: 38px;
    margin: -19px 0 0 -19px;
    border-radius: 50%;
    border: 3px solid #d2d2d2;
    box-sizing: border-box
}

.layui-layer-loading-2:after {
    border-color: transparent;
    border-left-color: #1e9fff
}

.layui-layer-tips {
    background: 0 0;
    box-shadow: none;
    border: none
}

.layui-layer-tips .layui-layer-content {
    position: relative;
    line-height: 22px;
    min-width: 12px;
    padding: 8px 15px;
    font-size: 12px;
    _float: left;
    border-radius: 2px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
    background-color: #000;
    color: #fff
}

.layui-layer-tips .layui-layer-close {
    right: -2px;
    top: -1px
}

.layui-layer-tips i.layui-layer-TipsG {
    position: absolute;
    width: 0;
    height: 0;
    border-width: 8px;
    border-color: transparent;
    border-style: dashed;

}

.layui-layer-tips i.layui-layer-TipsB, .layui-layer-tips i.layui-layer-TipsT {
    left: 5px;
    border-right-style: solid;
    border-right-color: #000
}

.layui-layer-tips i.layui-layer-TipsT {
    bottom: -8px
}

.layui-layer-tips i.layui-layer-TipsB {
    top: -8px
}

.layui-layer-tips i.layui-layer-TipsL, .layui-layer-tips i.layui-layer-TipsR {
    top: 5px;
    border-bottom-style: solid;
    border-bottom-color: #000
}

.layui-layer-tips i.layui-layer-TipsR {
    left: -8px
}

.layui-layer-tips i.layui-layer-TipsL {
    right: -8px
}

.layui-layer-lan .layui-layer-title {
    background: #4476a7;
    color: #fff;
    border: none
}

.layui-layer-lan .layui-layer-btn {
    padding: 5px 10px 10px;
    border-top: 1px solid #e9e7e7
}

.layui-layer-lan .layui-layer-btn a {
    background: #fff;
    border-color: #e9e7e7;
    color: #333
}

.layui-layer-lan .layui-layer-btn .layui-layer-btn1 {
    background: #c9c5c5
}

.layui-layer-molv .layui-layer-title {
    background: #009f95;
    color: #fff;
    border: none
}

.layui-layer-molv .layui-layer-btn a {
    background: #009f95;
    border-color: #009f95
}

.layui-layer-molv .layui-layer-btn .layui-layer-btn1 {
    background: #92b8b1
}

.layui-layer-lan .layui-layer-setwin .layui-icon, .layui-layer-molv .layui-layer-setwin .layui-icon {
    color: #fff
}

.layui-layer-win10 {
    border: 1px solid #aaa;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, .3);
    border-radius: none
}

.layui-layer-win10 .layui-layer-title {
    height: 32px;
    line-height: 32px;
    padding-left: 8px;
    border-bottom: none;
    font-size: 12px
}

.layui-layer-win10 .layui-layer-setwin {
    right: 0;
    top: 0
}

.layui-layer-win10 .layui-layer-setwin span {
    margin-left: 0;
    padding: 8px
}

.layui-layer-win10.layui-layer-page .layui-layer-setwin span {
    padding: 8px 11px
}

.layui-layer-win10 .layui-layer-setwin span:hover {
    background-color: #e5e5e5
}

.layui-layer-win10 .layui-layer-setwin span.layui-icon-close:hover {
    background-color: #e81123;
    color: #fff
}

.layui-layer-win10.layui-layer-dialog .layui-layer-content {
    padding: 8px 16px 32px;
    color: #0033bc
}

.layui-layer-win10.layui-layer-dialog .layui-layer-padding {
    padding-top: 18px;
    padding-left: 58px
}

.layui-layer-win10 .layui-layer-btn {
    padding: 5px 5px 10px;
    border-top: 1px solid #dfdfdf;
    background-color: #f0f0f0
}

.layui-layer-win10 .layui-layer-btn a {
    height: 18px;
    line-height: 18px;
    background-color: #e1e1e1;
    border-color: #adadad;
    color: #000;
    font-size: 12px;
    transition: all .3s
}

.layui-layer-win10 .layui-layer-btn a:hover {
    border-color: #2a8edd;
    background-color: #e5f1fb
}

.layui-layer-win10 .layui-layer-btn .layui-layer-btn0 {
    border-color: #0078d7
}

.layui-layer-prompt .layui-layer-input {
    display: block;
    width: 260px;
    height: 36px;
    margin: 0 auto;
    line-height: 30px;
    padding-left: 10px;
    border: 1px solid #e6e6e6;
    color: #333
}

.layui-layer-prompt textarea.layui-layer-input {
    width: 300px;
    height: 100px;
    line-height: 20px;
    padding: 6px 10px
}

.layui-layer-prompt .layui-layer-content {
    padding: 16px
}

.layui-layer-prompt .layui-layer-btn {
    padding-top: 0
}

.layui-layer-tab {
    box-shadow: 1px 1px 50px rgba(0, 0, 0, .4)
}

.layui-layer-tab .layui-layer-title {
    padding-left: 0;
    overflow: visible
}

.layui-layer-tab .layui-layer-title span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    min-width: 80px;
    max-width: 300px;
    padding: 0 16px;
    text-align: center;
    cursor: default;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer
}

.layui-layer-tab .layui-layer-title span.layui-this {
    height: 51px;
    border-left-color: #eee;
    border-right-color: #eee;
    background-color: #fff;
    z-index: 10
}

.layui-layer-tab .layui-layer-title span:first-child {
    border-left-color: transparent
}

.layui-layer-tabmain {
    line-height: 24px;
    clear: both
}

.layui-layer-tabmain .layui-layer-tabli {
    display: none
}

.layui-layer-tabmain .layui-layer-tabli.layui-this {
    display: block
}

.layui-layer-photos {
    background: 0 0;
    box-shadow: none
}

.layui-layer-photos .layui-layer-content {
    overflow: hidden;
    text-align: center
}

.layui-layer-photos .layui-layer-phimg img {
    position: relative;
    width: 100%;
    display: inline-block;

    ;
    vertical-align: top
}

.layui-layer-imgnext, .layui-layer-imgprev {
    position: fixed;
    top: 50%;
    width: 52px;
    height: 52px;
    line-height: 52px;
    margin-top: -26px;
    cursor: pointer;
    font-size: 52px;
    color: #717171
}

.layui-layer-imgprev {
    left: 32px
}

.layui-layer-imgnext {
    right: 32px
}

.layui-layer-imgnext:hover, .layui-layer-imgprev:hover {
    color: #959595
}

.layui-layer-imgbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #000 \9;
    filter: Alpha(opacity=60);
    background-color: rgba(2, 0, 0, .35);
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0
}

.layui-layer-imgtit * {
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    font-size: 12px;
    color: #fff
}

.layui-layer-imgtit h3 {
    max-width: 65%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 300
}

.layui-layer-imgtit a:hover {
    color: #fff;
    text-decoration: underline
}

.layui-layer-imgtit em {
    font-style: normal
}

@-webkit-keyframes layer-bounceOut {
    100% {
        opacity: 0;
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }
    30% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes layer-bounceOut {
    100% {
        opacity: 0;
        -webkit-transform: scale(.7);
        -ms-transform: scale(.7);
        transform: scale(.7)
    }
    30% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05)
    }
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.layer-anim-close {
    -webkit-animation-name: layer-bounceOut;
    animation-name: layer-bounceOut;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .2s;
    animation-duration: .2s
}

@media screen and (max-width: 1100px) {
    .layui-layer-iframe {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch
    }
}


.grid-demo{padding: 10px; line-height: 50px; text-align: center; background-color: #79C48C; color: #fff;}
.grid-demo-bg1{background-color: #63BA79;}
.grid-demo-bg2{background-color: #49A761;}
.grid-demo-bg3{background-color: #38814A;}
.fishcalc {
    cursor: pointer
}
tbody .layui-table-cell {
    height: 250px;
    /*font-size: 20px;*/
    /*line-height: 100px;*/
}

.biaoti {
    font-size: 20px;
    margin: 20px;
    text-align: center;
}

@media print {

    .noprint { display: none }

}
html,body,#main,#rightmain,#leftmain {
    height: 100%;
    overflow: hidden;
}

.jh_point{
    position: absolute;
    padding: 5px;
    background: rgba(255, 255, 255, 0.5);
    line-height: 1;
    border-radius: 5px;
    color: #ffffff;
    user-select: none;
}

.jh_shuoming{
    position: absolute;
    padding: 10px;
    color: #00f7de;
    background: rgba(255, 255, 255, 0.5);
    line-height: 1;
    border-radius: 5px;
}

.ruler_kedu{
    position: absolute;
    /*padding: 10px;*/
    background: rgba(255, 255, 255, 0.0);
    line-height: 1;
    border-radius: 5px;
    color: #00f7de;
    user-select: none;
}
