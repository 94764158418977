blockquote, body, button, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, input, li, ol, p, pre, td, textarea, th, ul {
  -webkit-tap-highlight-color: #0000;
  margin: 0;
  padding: 0;
}

a:active, a:hover {
  outline: 0;
}

img {
  vertical-align: middle;
  border: none;
  display: inline-block;
}

li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1, h2, h3, h4 {
  font-weight: 700;
}

h5, h6 {
  font-size: 100%;
  font-weight: 500;
}

button, input, select, textarea {
  font-size: 100%;
}

button, input, optgroup, option, select, textarea {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

body {
  color: #000000d9;
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
}

hr {
  clear: both;
  background: none;
  height: 0;
  margin: 10px 0;
  padding: 0;
  line-height: 0;
  overflow: hidden;
  border: none !important;
  border-bottom: 1px solid #eee !important;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #777;
}

a cite {
  font-style: normal;
}

.layui-border-box, .layui-border-box * {
  box-sizing: border-box;
}

.layui-box, .layui-box * {
  box-sizing: content-box;
}

.layui-clear {
  clear: both;
}

.layui-clear:after {
  content: " ";
  clear: both;
  height: 0;
  display: block;
}

.layui-clear-space {
  word-spacing: -5px;
}

.layui-inline {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.layui-edge {
  vertical-align: middle;
  border: 6px dashed #0000;
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.layui-edge-top {
  border-bottom-style: solid;
  border-bottom-color: #999;
  top: -4px;
}

.layui-edge-right {
  border-left-style: solid;
  border-left-color: #999;
}

.layui-edge-bottom {
  border-top-style: solid;
  border-top-color: #999;
  top: 2px;
}

.layui-edge-left {
  border-right-style: solid;
  border-right-color: #999;
}

.layui-elip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layui-disabled, .layui-icon, .layui-unselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.layui-disabled, .layui-disabled:hover {
  color: #d2d2d2 !important;
  cursor: not-allowed !important;
}

.layui-circle {
  border-radius: 100%;
}

.layui-show {
  display: block !important;
}

.layui-hide {
  display: none !important;
}

.layui-show-v {
  visibility: visible !important;
}

.layui-hide-v {
  visibility: hidden !important;
}

@font-face {
  font-family: layui-icon;
  src: url("iconfont.0f2f23c5.eot");
  src: url("iconfont.0f2f23c5.eot#iefix") format("embedded-opentype"), url("iconfont.11085093.woff2") format("woff2"), url("iconfont.ff92e793.woff") format("woff"), url("iconfont.949c371e.ttf") format("truetype"), url("iconfont.2b9705c1.svg#layui-icon") format("svg");
}

.layui-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-style: normal;
  font-family: layui-icon !important;
}

.layui-icon-leaf:before {
  content: "";
}

.layui-icon-folder:before {
  content: "";
}

.layui-icon-folder-open:before {
  content: "";
}

.layui-icon-gitee:before {
  content: "";
}

.layui-icon-github:before {
  content: "";
}

.layui-icon-disabled:before {
  content: "";
}

.layui-icon-moon:before {
  content: "";
}

.layui-icon-error:before {
  content: "";
}

.layui-icon-success:before {
  content: "";
}

.layui-icon-question:before {
  content: "";
}

.layui-icon-lock:before {
  content: "";
}

.layui-icon-eye:before {
  content: "";
}

.layui-icon-eye-invisible:before {
  content: "";
}

.layui-icon-backspace:before {
  content: "";
}

.layui-icon-tips-fill:before {
  content: "";
}

.layui-icon-test:before {
  content: "";
}

.layui-icon-clear:before {
  content: "";
}

.layui-icon-heart-fill:before {
  content: "";
}

.layui-icon-light:before {
  content: "";
}

.layui-icon-music:before {
  content: "";
}

.layui-icon-time:before {
  content: "";
}

.layui-icon-ie:before {
  content: "";
}

.layui-icon-firefox:before {
  content: "";
}

.layui-icon-at:before {
  content: "";
}

.layui-icon-bluetooth:before {
  content: "";
}

.layui-icon-chrome:before {
  content: "";
}

.layui-icon-edge:before {
  content: "";
}

.layui-icon-heart:before {
  content: "";
}

.layui-icon-key:before {
  content: "";
}

.layui-icon-android:before {
  content: "";
}

.layui-icon-mike:before {
  content: "";
}

.layui-icon-mute:before {
  content: "";
}

.layui-icon-gift:before {
  content: "";
}

.layui-icon-windows:before {
  content: "";
}

.layui-icon-ios:before {
  content: "";
}

.layui-icon-logout:before {
  content: "";
}

.layui-icon-wifi:before {
  content: "";
}

.layui-icon-rss:before {
  content: "";
}

.layui-icon-email:before {
  content: "";
}

.layui-icon-reduce-circle:before {
  content: "";
}

.layui-icon-transfer:before {
  content: "";
}

.layui-icon-service:before {
  content: "";
}

.layui-icon-addition:before {
  content: "";
}

.layui-icon-subtraction:before {
  content: "";
}

.layui-icon-slider:before {
  content: "";
}

.layui-icon-print:before {
  content: "";
}

.layui-icon-export:before {
  content: "";
}

.layui-icon-cols:before {
  content: "";
}

.layui-icon-screen-full:before {
  content: "";
}

.layui-icon-screen-restore:before {
  content: "";
}

.layui-icon-rate-half:before {
  content: "";
}

.layui-icon-rate-solid:before {
  content: "";
}

.layui-icon-rate:before {
  content: "";
}

.layui-icon-cellphone:before {
  content: "";
}

.layui-icon-vercode:before {
  content: "";
}

.layui-icon-login-weibo:before {
  content: "";
}

.layui-icon-login-qq:before {
  content: "";
}

.layui-icon-login-wechat:before {
  content: "";
}

.layui-icon-username:before {
  content: "";
}

.layui-icon-password:before {
  content: "";
}

.layui-icon-refresh-3:before {
  content: "";
}

.layui-icon-auz:before {
  content: "";
}

.layui-icon-shrink-right:before {
  content: "";
}

.layui-icon-spread-left:before {
  content: "";
}

.layui-icon-snowflake:before {
  content: "";
}

.layui-icon-tips:before {
  content: "";
}

.layui-icon-note:before {
  content: "";
}

.layui-icon-senior:before {
  content: "";
}

.layui-icon-refresh-1:before {
  content: "";
}

.layui-icon-refresh:before {
  content: "";
}

.layui-icon-flag:before {
  content: "";
}

.layui-icon-theme:before {
  content: "";
}

.layui-icon-notice:before {
  content: "";
}

.layui-icon-console:before {
  content: "";
}

.layui-icon-website:before {
  content: "";
}

.layui-icon-face-surprised:before {
  content: "";
}

.layui-icon-set:before {
  content: "";
}

.layui-icon-template:before {
  content: "";
}

.layui-icon-app:before {
  content: "";
}

.layui-icon-template-1:before {
  content: "";
}

.layui-icon-home:before {
  content: "";
}

.layui-icon-female:before {
  content: "";
}

.layui-icon-male:before {
  content: "";
}

.layui-icon-tread:before {
  content: "";
}

.layui-icon-praise:before {
  content: "";
}

.layui-icon-rmb:before {
  content: "";
}

.layui-icon-more:before {
  content: "";
}

.layui-icon-camera:before {
  content: "";
}

.layui-icon-cart-simple:before {
  content: "";
}

.layui-icon-face-cry:before {
  content: "";
}

.layui-icon-face-smile:before {
  content: "";
}

.layui-icon-survey:before {
  content: "";
}

.layui-icon-read:before {
  content: "";
}

.layui-icon-location:before {
  content: "";
}

.layui-icon-dollar:before {
  content: "";
}

.layui-icon-diamond:before {
  content: "";
}

.layui-icon-return:before {
  content: "";
}

.layui-icon-camera-fill:before {
  content: "";
}

.layui-icon-fire:before {
  content: "";
}

.layui-icon-more-vertical:before {
  content: "";
}

.layui-icon-cart:before {
  content: "";
}

.layui-icon-star-fill:before {
  content: "";
}

.layui-icon-prev:before {
  content: "";
}

.layui-icon-next:before {
  content: "";
}

.layui-icon-upload:before {
  content: "";
}

.layui-icon-upload-drag:before {
  content: "";
}

.layui-icon-user:before {
  content: "";
}

.layui-icon-file-b:before {
  content: "";
}

.layui-icon-component:before {
  content: "";
}

.layui-icon-find-fill:before {
  content: "";
}

.layui-icon-loading:before {
  content: "";
}

.layui-icon-loading-1:before {
  content: "";
}

.layui-icon-add-1:before {
  content: "";
}

.layui-icon-pause:before {
  content: "";
}

.layui-icon-play:before {
  content: "";
}

.layui-icon-video:before {
  content: "";
}

.layui-icon-headset:before {
  content: "";
}

.layui-icon-voice:before {
  content: "";
}

.layui-icon-speaker:before {
  content: "";
}

.layui-icon-fonts-del:before {
  content: "";
}

.layui-icon-fonts-html:before {
  content: "";
}

.layui-icon-fonts-code:before {
  content: "";
}

.layui-icon-fonts-strong:before {
  content: "";
}

.layui-icon-unlink:before {
  content: "";
}

.layui-icon-picture:before {
  content: "";
}

.layui-icon-link:before {
  content: "";
}

.layui-icon-face-smile-b:before {
  content: "";
}

.layui-icon-align-center:before {
  content: "";
}

.layui-icon-align-right:before {
  content: "";
}

.layui-icon-align-left:before {
  content: "";
}

.layui-icon-fonts-u:before {
  content: "";
}

.layui-icon-fonts-i:before {
  content: "";
}

.layui-icon-tabs:before {
  content: "";
}

.layui-icon-circle:before {
  content: "";
}

.layui-icon-radio:before {
  content: "";
}

.layui-icon-share:before {
  content: "";
}

.layui-icon-edit:before {
  content: "";
}

.layui-icon-delete:before {
  content: "";
}

.layui-icon-engine:before {
  content: "";
}

.layui-icon-chart-screen:before {
  content: "";
}

.layui-icon-chart:before {
  content: "";
}

.layui-icon-table:before {
  content: "";
}

.layui-icon-tree:before {
  content: "";
}

.layui-icon-upload-circle:before {
  content: "";
}

.layui-icon-templeate-1:before {
  content: "";
}

.layui-icon-util:before {
  content: "";
}

.layui-icon-layouts:before {
  content: "";
}

.layui-icon-prev-circle:before {
  content: "";
}

.layui-icon-carousel:before {
  content: "";
}

.layui-icon-code-circle:before {
  content: "";
}

.layui-icon-water:before {
  content: "";
}

.layui-icon-date:before {
  content: "";
}

.layui-icon-layer:before {
  content: "";
}

.layui-icon-fonts-clear:before {
  content: "";
}

.layui-icon-dialogue:before {
  content: "";
}

.layui-icon-cellphone-fine:before {
  content: "";
}

.layui-icon-form:before {
  content: "";
}

.layui-icon-file:before {
  content: "";
}

.layui-icon-triangle-r:before {
  content: "";
}

.layui-icon-triangle-d:before {
  content: "";
}

.layui-icon-set-sm:before {
  content: "";
}

.layui-icon-add-circle:before {
  content: "";
}

.layui-icon-layim-download:before {
  content: "";
}

.layui-icon-layim-uploadfile:before {
  content: "";
}

.layui-icon-404:before {
  content: "";
}

.layui-icon-about:before {
  content: "";
}

.layui-icon-layim-theme:before {
  content: "";
}

.layui-icon-down:before {
  content: "";
}

.layui-icon-up:before {
  content: "";
}

.layui-icon-circle-dot:before {
  content: "";
}

.layui-icon-set-fill:before {
  content: "";
}

.layui-icon-search:before {
  content: "";
}

.layui-icon-friends:before {
  content: "";
}

.layui-icon-group:before {
  content: "";
}

.layui-icon-reply-fill:before {
  content: "";
}

.layui-icon-menu-fill:before {
  content: "";
}

.layui-icon-face-smile-fine:before {
  content: "";
}

.layui-icon-picture-fine:before {
  content: "";
}

.layui-icon-log:before {
  content: "";
}

.layui-icon-list:before {
  content: "";
}

.layui-icon-release:before {
  content: "";
}

.layui-icon-add-circle-fine:before {
  content: "";
}

.layui-icon-ok:before {
  content: "";
}

.layui-icon-help:before {
  content: "";
}

.layui-icon-chat:before {
  content: "";
}

.layui-icon-top:before {
  content: "";
}

.layui-icon-right:before {
  content: "";
}

.layui-icon-left:before {
  content: "";
}

.layui-icon-star:before {
  content: "";
}

.layui-icon-download-circle:before {
  content: "";
}

.layui-icon-close:before {
  content: "ဆ";
}

.layui-icon-close-fill:before {
  content: "ဇ";
}

.layui-icon-ok-circle:before {
  content: "စ";
}

.layui-main {
  width: 1160px;
  margin: 0 auto;
  position: relative;
}

.layui-header {
  z-index: 1000;
  height: 60px;
  position: relative;
}

.layui-header a:hover {
  transition: all .5s;
}

.layui-side {
  z-index: 999;
  width: 200px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.layui-side-scroll {
  width: 220px;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.layui-body {
  z-index: 900;
  box-sizing: border-box;
  width: auto;
  position: relative;
  inset: 0 0 0 200px;
}

.layui-layout-body {
  overflow-x: hidden;
}

.layui-layout-admin .layui-header {
  background-color: #23292e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.layui-layout-admin .layui-side {
  width: 200px;
  top: 60px;
  overflow-x: hidden;
}

.layui-layout-admin .layui-body {
  padding-bottom: 44px;
  position: absolute;
  top: 60px;
}

.layui-layout-admin .layui-main {
  width: auto;
  margin: 0 15px;
}

.layui-layout-admin .layui-footer {
  z-index: 990;
  background-color: #fafafa;
  height: 44px;
  padding: 0 15px;
  line-height: 44px;
  position: fixed;
  bottom: 0;
  left: 200px;
  right: 0;
  box-shadow: -1px 0 4px #0000001f;
}

.layui-layout-admin .layui-logo {
  text-align: center;
  color: #16baaa;
  width: 200px;
  height: 100%;
  font-size: 16px;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 2px #00000026;
}

.layui-layout-admin .layui-header .layui-nav {
  background: none;
}

.layui-layout-left {
  top: 0;
  left: 200px;
  position: absolute !important;
}

.layui-layout-right {
  top: 0;
  right: 0;
  position: absolute !important;
}

.layui-container {
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.layui-fluid {
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.layui-row:after, .layui-row:before {
  content: "";
  clear: both;
  display: block;
}

.layui-col-lg1, .layui-col-lg10, .layui-col-lg11, .layui-col-lg12, .layui-col-lg2, .layui-col-lg3, .layui-col-lg4, .layui-col-lg5, .layui-col-lg6, .layui-col-lg7, .layui-col-lg8, .layui-col-lg9, .layui-col-md1, .layui-col-md10, .layui-col-md11, .layui-col-md12, .layui-col-md2, .layui-col-md3, .layui-col-md4, .layui-col-md5, .layui-col-md6, .layui-col-md7, .layui-col-md8, .layui-col-md9, .layui-col-sm1, .layui-col-sm10, .layui-col-sm11, .layui-col-sm12, .layui-col-sm2, .layui-col-sm3, .layui-col-sm4, .layui-col-sm5, .layui-col-sm6, .layui-col-sm7, .layui-col-sm8, .layui-col-sm9, .layui-col-xl1, .layui-col-xl10, .layui-col-xl11, .layui-col-xl12, .layui-col-xl2, .layui-col-xl3, .layui-col-xl4, .layui-col-xl5, .layui-col-xl6, .layui-col-xl7, .layui-col-xl8, .layui-col-xl9, .layui-col-xs1, .layui-col-xs10, .layui-col-xs11, .layui-col-xs12, .layui-col-xs2, .layui-col-xs3, .layui-col-xs4, .layui-col-xs5, .layui-col-xs6, .layui-col-xs7, .layui-col-xs8, .layui-col-xs9 {
  box-sizing: border-box;
  display: block;
  position: relative;
}

.layui-col-xs1, .layui-col-xs10, .layui-col-xs11, .layui-col-xs12, .layui-col-xs2, .layui-col-xs3, .layui-col-xs4, .layui-col-xs5, .layui-col-xs6, .layui-col-xs7, .layui-col-xs8, .layui-col-xs9 {
  float: left;
}

.layui-col-xs1 {
  width: 8.33333%;
}

.layui-col-xs2 {
  width: 16.6667%;
}

.layui-col-xs3 {
  width: 25%;
}

.layui-col-xs4 {
  width: 33.3333%;
}

.layui-col-xs5 {
  width: 41.6667%;
}

.layui-col-xs6 {
  width: 50%;
}

.layui-col-xs7 {
  width: 58.3333%;
}

.layui-col-xs8 {
  width: 66.6667%;
}

.layui-col-xs9 {
  width: 75%;
}

.layui-col-xs10 {
  width: 83.3333%;
}

.layui-col-xs11 {
  width: 91.6667%;
}

.layui-col-xs12 {
  width: 100%;
}

.layui-col-xs-offset1 {
  margin-left: 8.33333%;
}

.layui-col-xs-offset2 {
  margin-left: 16.6667%;
}

.layui-col-xs-offset3 {
  margin-left: 25%;
}

.layui-col-xs-offset4 {
  margin-left: 33.3333%;
}

.layui-col-xs-offset5 {
  margin-left: 41.6667%;
}

.layui-col-xs-offset6 {
  margin-left: 50%;
}

.layui-col-xs-offset7 {
  margin-left: 58.3333%;
}

.layui-col-xs-offset8 {
  margin-left: 66.6667%;
}

.layui-col-xs-offset9 {
  margin-left: 75%;
}

.layui-col-xs-offset10 {
  margin-left: 83.3333%;
}

.layui-col-xs-offset11 {
  margin-left: 91.6667%;
}

.layui-col-xs-offset12 {
  margin-left: 100%;
}

@media screen and (width <= 767.98px) {
  .layui-container {
    padding: 0 15px;
  }

  .layui-hide-xs {
    display: none !important;
  }

  .layui-show-xs-block {
    display: block !important;
  }

  .layui-show-xs-inline {
    display: inline !important;
  }

  .layui-show-xs-inline-block {
    display: inline-block !important;
  }
}

@media screen and (width >= 768px) {
  .layui-container {
    width: 720px;
  }

  .layui-hide-sm {
    display: none !important;
  }

  .layui-show-sm-block {
    display: block !important;
  }

  .layui-show-sm-inline {
    display: inline !important;
  }

  .layui-show-sm-inline-block {
    display: inline-block !important;
  }

  .layui-col-sm1, .layui-col-sm10, .layui-col-sm11, .layui-col-sm12, .layui-col-sm2, .layui-col-sm3, .layui-col-sm4, .layui-col-sm5, .layui-col-sm6, .layui-col-sm7, .layui-col-sm8, .layui-col-sm9 {
    float: left;
  }

  .layui-col-sm1 {
    width: 8.33333%;
  }

  .layui-col-sm2 {
    width: 16.6667%;
  }

  .layui-col-sm3 {
    width: 25%;
  }

  .layui-col-sm4 {
    width: 33.3333%;
  }

  .layui-col-sm5 {
    width: 41.6667%;
  }

  .layui-col-sm6 {
    width: 50%;
  }

  .layui-col-sm7 {
    width: 58.3333%;
  }

  .layui-col-sm8 {
    width: 66.6667%;
  }

  .layui-col-sm9 {
    width: 75%;
  }

  .layui-col-sm10 {
    width: 83.3333%;
  }

  .layui-col-sm11 {
    width: 91.6667%;
  }

  .layui-col-sm12 {
    width: 100%;
  }

  .layui-col-sm-offset1 {
    margin-left: 8.33333%;
  }

  .layui-col-sm-offset2 {
    margin-left: 16.6667%;
  }

  .layui-col-sm-offset3 {
    margin-left: 25%;
  }

  .layui-col-sm-offset4 {
    margin-left: 33.3333%;
  }

  .layui-col-sm-offset5 {
    margin-left: 41.6667%;
  }

  .layui-col-sm-offset6 {
    margin-left: 50%;
  }

  .layui-col-sm-offset7 {
    margin-left: 58.3333%;
  }

  .layui-col-sm-offset8 {
    margin-left: 66.6667%;
  }

  .layui-col-sm-offset9 {
    margin-left: 75%;
  }

  .layui-col-sm-offset10 {
    margin-left: 83.3333%;
  }

  .layui-col-sm-offset11 {
    margin-left: 91.6667%;
  }

  .layui-col-sm-offset12 {
    margin-left: 100%;
  }
}

@media screen and (width >= 992px) {
  .layui-container {
    width: 960px;
  }

  .layui-hide-md {
    display: none !important;
  }

  .layui-show-md-block {
    display: block !important;
  }

  .layui-show-md-inline {
    display: inline !important;
  }

  .layui-show-md-inline-block {
    display: inline-block !important;
  }

  .layui-col-md1, .layui-col-md10, .layui-col-md11, .layui-col-md12, .layui-col-md2, .layui-col-md3, .layui-col-md4, .layui-col-md5, .layui-col-md6, .layui-col-md7, .layui-col-md8, .layui-col-md9 {
    float: left;
  }

  .layui-col-md1 {
    width: 8.33333%;
  }

  .layui-col-md2 {
    width: 16.6667%;
  }

  .layui-col-md3 {
    width: 25%;
  }

  .layui-col-md4 {
    width: 33.3333%;
  }

  .layui-col-md5 {
    width: 41.6667%;
  }

  .layui-col-md6 {
    width: 50%;
  }

  .layui-col-md7 {
    width: 58.3333%;
  }

  .layui-col-md8 {
    width: 66.6667%;
  }

  .layui-col-md9 {
    width: 75%;
  }

  .layui-col-md10 {
    width: 83.3333%;
  }

  .layui-col-md11 {
    width: 91.6667%;
  }

  .layui-col-md12 {
    width: 100%;
  }

  .layui-col-md-offset1 {
    margin-left: 8.33333%;
  }

  .layui-col-md-offset2 {
    margin-left: 16.6667%;
  }

  .layui-col-md-offset3 {
    margin-left: 25%;
  }

  .layui-col-md-offset4 {
    margin-left: 33.3333%;
  }

  .layui-col-md-offset5 {
    margin-left: 41.6667%;
  }

  .layui-col-md-offset6 {
    margin-left: 50%;
  }

  .layui-col-md-offset7 {
    margin-left: 58.3333%;
  }

  .layui-col-md-offset8 {
    margin-left: 66.6667%;
  }

  .layui-col-md-offset9 {
    margin-left: 75%;
  }

  .layui-col-md-offset10 {
    margin-left: 83.3333%;
  }

  .layui-col-md-offset11 {
    margin-left: 91.6667%;
  }

  .layui-col-md-offset12 {
    margin-left: 100%;
  }
}

@media screen and (width >= 1200px) {
  .layui-container {
    width: 1150px;
  }

  .layui-hide-lg {
    display: none !important;
  }

  .layui-show-lg-block {
    display: block !important;
  }

  .layui-show-lg-inline {
    display: inline !important;
  }

  .layui-show-lg-inline-block {
    display: inline-block !important;
  }

  .layui-col-lg1, .layui-col-lg10, .layui-col-lg11, .layui-col-lg12, .layui-col-lg2, .layui-col-lg3, .layui-col-lg4, .layui-col-lg5, .layui-col-lg6, .layui-col-lg7, .layui-col-lg8, .layui-col-lg9 {
    float: left;
  }

  .layui-col-lg1 {
    width: 8.33333%;
  }

  .layui-col-lg2 {
    width: 16.6667%;
  }

  .layui-col-lg3 {
    width: 25%;
  }

  .layui-col-lg4 {
    width: 33.3333%;
  }

  .layui-col-lg5 {
    width: 41.6667%;
  }

  .layui-col-lg6 {
    width: 50%;
  }

  .layui-col-lg7 {
    width: 58.3333%;
  }

  .layui-col-lg8 {
    width: 66.6667%;
  }

  .layui-col-lg9 {
    width: 75%;
  }

  .layui-col-lg10 {
    width: 83.3333%;
  }

  .layui-col-lg11 {
    width: 91.6667%;
  }

  .layui-col-lg12 {
    width: 100%;
  }

  .layui-col-lg-offset1 {
    margin-left: 8.33333%;
  }

  .layui-col-lg-offset2 {
    margin-left: 16.6667%;
  }

  .layui-col-lg-offset3 {
    margin-left: 25%;
  }

  .layui-col-lg-offset4 {
    margin-left: 33.3333%;
  }

  .layui-col-lg-offset5 {
    margin-left: 41.6667%;
  }

  .layui-col-lg-offset6 {
    margin-left: 50%;
  }

  .layui-col-lg-offset7 {
    margin-left: 58.3333%;
  }

  .layui-col-lg-offset8 {
    margin-left: 66.6667%;
  }

  .layui-col-lg-offset9 {
    margin-left: 75%;
  }

  .layui-col-lg-offset10 {
    margin-left: 83.3333%;
  }

  .layui-col-lg-offset11 {
    margin-left: 91.6667%;
  }

  .layui-col-lg-offset12 {
    margin-left: 100%;
  }
}

@media screen and (width >= 1400px) {
  .layui-container {
    width: 1330px;
  }

  .layui-hide-xl {
    display: none !important;
  }

  .layui-show-xl-block {
    display: block !important;
  }

  .layui-show-xl-inline {
    display: inline !important;
  }

  .layui-show-xl-inline-block {
    display: inline-block !important;
  }

  .layui-col-xl1, .layui-col-xl10, .layui-col-xl11, .layui-col-xl12, .layui-col-xl2, .layui-col-xl3, .layui-col-xl4, .layui-col-xl5, .layui-col-xl6, .layui-col-xl7, .layui-col-xl8, .layui-col-xl9 {
    float: left;
  }

  .layui-col-xl1 {
    width: 8.33333%;
  }

  .layui-col-xl2 {
    width: 16.6667%;
  }

  .layui-col-xl3 {
    width: 25%;
  }

  .layui-col-xl4 {
    width: 33.3333%;
  }

  .layui-col-xl5 {
    width: 41.6667%;
  }

  .layui-col-xl6 {
    width: 50%;
  }

  .layui-col-xl7 {
    width: 58.3333%;
  }

  .layui-col-xl8 {
    width: 66.6667%;
  }

  .layui-col-xl9 {
    width: 75%;
  }

  .layui-col-xl10 {
    width: 83.3333%;
  }

  .layui-col-xl11 {
    width: 91.6667%;
  }

  .layui-col-xl12 {
    width: 100%;
  }

  .layui-col-xl-offset1 {
    margin-left: 8.33333%;
  }

  .layui-col-xl-offset2 {
    margin-left: 16.6667%;
  }

  .layui-col-xl-offset3 {
    margin-left: 25%;
  }

  .layui-col-xl-offset4 {
    margin-left: 33.3333%;
  }

  .layui-col-xl-offset5 {
    margin-left: 41.6667%;
  }

  .layui-col-xl-offset6 {
    margin-left: 50%;
  }

  .layui-col-xl-offset7 {
    margin-left: 58.3333%;
  }

  .layui-col-xl-offset8 {
    margin-left: 66.6667%;
  }

  .layui-col-xl-offset9 {
    margin-left: 75%;
  }

  .layui-col-xl-offset10 {
    margin-left: 83.3333%;
  }

  .layui-col-xl-offset11 {
    margin-left: 91.6667%;
  }

  .layui-col-xl-offset12 {
    margin-left: 100%;
  }
}

.layui-col-space1 {
  margin: -.5px;
}

.layui-col-space1 > * {
  padding: .5px;
}

.layui-col-space2 {
  margin: -1px;
}

.layui-col-space2 > * {
  padding: 1px;
}

.layui-col-space4 {
  margin: -2px;
}

.layui-col-space4 > * {
  padding: 2px;
}

.layui-col-space5 {
  margin: -2.5px;
}

.layui-col-space5 > * {
  padding: 2.5px;
}

.layui-col-space6 {
  margin: -3px;
}

.layui-col-space6 > * {
  padding: 3px;
}

.layui-col-space8 {
  margin: -4px;
}

.layui-col-space8 > * {
  padding: 4px;
}

.layui-col-space10 {
  margin: -5px;
}

.layui-col-space10 > * {
  padding: 5px;
}

.layui-col-space12 {
  margin: -6px;
}

.layui-col-space12 > * {
  padding: 6px;
}

.layui-col-space14 {
  margin: -7px;
}

.layui-col-space14 > * {
  padding: 7px;
}

.layui-col-space15 {
  margin: -7.5px;
}

.layui-col-space15 > * {
  padding: 7.5px;
}

.layui-col-space16 {
  margin: -8px;
}

.layui-col-space16 > * {
  padding: 8px;
}

.layui-col-space18 {
  margin: -9px;
}

.layui-col-space18 > * {
  padding: 9px;
}

.layui-col-space20 {
  margin: -10px;
}

.layui-col-space20 > * {
  padding: 10px;
}

.layui-col-space22 {
  margin: -11px;
}

.layui-col-space22 > * {
  padding: 11px;
}

.layui-col-space24 {
  margin: -12px;
}

.layui-col-space24 > * {
  padding: 12px;
}

.layui-col-space25 {
  margin: -12.5px;
}

.layui-col-space25 > * {
  padding: 12.5px;
}

.layui-col-space26 {
  margin: -13px;
}

.layui-col-space26 > * {
  padding: 13px;
}

.layui-col-space28 {
  margin: -14px;
}

.layui-col-space28 > * {
  padding: 14px;
}

.layui-col-space30 {
  margin: -15px;
}

.layui-col-space30 > * {
  padding: 15px;
}

.layui-col-space32 {
  margin: -15px;
}

.layui-col-space32 > * {
  padding: 15px;
}

.layui-btn, .layui-input, .layui-select, .layui-textarea, .layui-upload-button {
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: 0;
  transition: all .3s;
}

.layui-elem-quote {
  background-color: #fafafa;
  border-left: 5px solid #16b777;
  border-radius: 0 2px 2px 0;
  margin-bottom: 10px;
  padding: 15px;
  line-height: 1.8;
}

.layui-quote-nm {
  background: none;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
}

.layui-elem-field {
  border-style: solid;
  border-width: 1px;
  margin-bottom: 10px;
  padding: 0;
}

.layui-elem-field legend {
  margin-left: 20px;
  padding: 0 10px;
  font-size: 20px;
}

.layui-field-title {
  border-width: 1px 0 0;
  margin: 16px 0;
}

.layui-field-box {
  padding: 15px;
}

.layui-field-title .layui-field-box {
  padding: 10px 0;
}

.layui-progress {
  background-color: #eee;
  border-radius: 20px;
  height: 6px;
  position: relative;
}

.layui-progress-bar {
  text-align: right;
  background-color: #16b777;
  border-radius: 20px;
  width: 0;
  max-width: 100%;
  height: 6px;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-progress-big, .layui-progress-big .layui-progress-bar {
  height: 18px;
  line-height: 18px;
}

.layui-progress-text {
  color: #5f5f5f;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  top: -20px;
}

.layui-progress-big .layui-progress-text {
  color: #fff;
  padding: 0 10px;
  position: static;
}

.layui-collapse {
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.layui-colla-content, .layui-colla-item {
  border-top-style: solid;
  border-top-width: 1px;
}

.layui-colla-item:first-child {
  border-top: none;
}

.layui-colla-title {
  color: #333;
  cursor: pointer;
  background-color: #fafafa;
  height: 42px;
  padding: 0 15px 0 35px;
  font-size: 14px;
  line-height: 42px;
  position: relative;
  overflow: hidden;
}

.layui-colla-content {
  color: #5f5f5f;
  padding: 10px 15px;
  line-height: 1.6;
  display: none;
}

.layui-colla-icon {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 15px;
}

.layui-card {
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px #0000000d;
}

.layui-card:last-child {
  margin-bottom: 0;
}

.layui-card-header {
  color: #333;
  border-bottom: 1px solid #f8f8f8;
  border-radius: 2px 2px 0 0;
  height: 42px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 42px;
  position: relative;
}

.layui-card-body {
  padding: 10px 15px;
  line-height: 24px;
  position: relative;
}

.layui-card-body[pad15] {
  padding: 15px;
}

.layui-card-body[pad20] {
  padding: 20px;
}

.layui-card-body .layui-table {
  margin: 5px 0;
}

.layui-card .layui-tab {
  margin: 0;
}

.layui-panel {
  color: #5f5f5f;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  position: relative;
  box-shadow: 1px 1px 4px #00000014;
}

.layui-panel-window {
  background-color: #fff;
  border-top: 5px solid #eee;
  border-radius: 0;
  padding: 15px;
  position: relative;
}

.layui-auxiliar-moving {
  z-index: 2147483647;
  background: none;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.layui-scollbar-hide {
  overflow: hidden !important;
}

.layui-bg-red {
  color: #fff !important;
  background-color: #ff5722 !important;
}

.layui-bg-orange {
  color: #fff !important;
  background-color: #ffb800 !important;
}

.layui-bg-green {
  color: #fff !important;
  background-color: #16baaa !important;
}

.layui-bg-cyan {
  color: #fff !important;
  background-color: #2f4056 !important;
}

.layui-bg-blue {
  color: #fff !important;
  background-color: #1e9fff !important;
}

.layui-bg-purple {
  color: #fff !important;
  background-color: #a233c6 !important;
}

.layui-bg-black {
  color: #fff !important;
  background-color: #2f363c !important;
}

.layui-bg-gray {
  color: #5f5f5f !important;
  background-color: #fafafa !important;
}

.layui-badge-rim, .layui-border, .layui-colla-content, .layui-colla-item, .layui-collapse, .layui-elem-field, .layui-form-pane .layui-form-item[pane], .layui-form-pane .layui-form-label, .layui-input, .layui-input-split, .layui-panel, .layui-quote-nm, .layui-select, .layui-tab-bar, .layui-tab-card, .layui-tab-title, .layui-tab-title .layui-this:after, .layui-textarea {
  border-color: #eee;
}

.layui-border {
  border-style: solid;
  border-width: 1px;
  color: #5f5f5f !important;
}

.layui-border-red {
  border-style: solid;
  border-width: 1px;
  color: #ff5722 !important;
  border-color: #ff5722 !important;
}

.layui-border-orange {
  border-style: solid;
  border-width: 1px;
  color: #ffb800 !important;
  border-color: #ffb800 !important;
}

.layui-border-green {
  border-style: solid;
  border-width: 1px;
  color: #16baaa !important;
  border-color: #16baaa !important;
}

.layui-border-cyan {
  border-style: solid;
  border-width: 1px;
  color: #2f4056 !important;
  border-color: #2f4056 !important;
}

.layui-border-blue {
  border-style: solid;
  border-width: 1px;
  color: #1e9fff !important;
  border-color: #1e9fff !important;
}

.layui-border-purple {
  border-style: solid;
  border-width: 1px;
  color: #a233c6 !important;
  border-color: #a233c6 !important;
}

.layui-border-black {
  border-style: solid;
  border-width: 1px;
  color: #2f363c !important;
  border-color: #2f363c !important;
}

.layui-timeline-item:before {
  background-color: #eee;
}

.layui-text {
  font-size: 14px;
  line-height: 1.8;
}

.layui-text h1, .layui-text h2, .layui-text h3, .layui-text h4, .layui-text h5, .layui-text h6 {
  color: #3a3a3a;
}

.layui-text h1 {
  font-size: 32px;
}

.layui-text h2 {
  font-size: 24px;
}

.layui-text h3 {
  font-size: 18px;
}

.layui-text h4 {
  font-size: 16px;
}

.layui-text h5 {
  font-size: 14px;
}

.layui-text h6 {
  font-size: 13px;
}

.layui-text ol, .layui-text ul {
  padding-left: 15px;
}

.layui-text ul li {
  margin-top: 5px;
  list-style-type: disc;
}

.layui-text ol li {
  margin-top: 5px;
  list-style-type: decimal;
}

.layui-text-em, .layui-word-aux {
  color: #999 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.layui-text p {
  margin: 15px 0;
}

.layui-text p:first-child {
  margin-top: 0;
}

.layui-text p:last-child {
  margin-bottom: 0;
}

.layui-text a:not(.layui-btn) {
  color: #01aaed;
}

.layui-text a:not(.layui-btn):hover {
  text-decoration: underline;
}

.layui-text blockquote:not(.layui-elem-quote) {
  border-left: 5px solid #eee;
  padding: 5px 15px;
}

.layui-text pre > code:not(.layui-code) {
  background-color: #fafafa;
  padding: 15px;
  font-family: Courier New, Lucida Console, Consolas;
}

.layui-font-12 {
  font-size: 12px !important;
}

.layui-font-13 {
  font-size: 13px !important;
}

.layui-font-14 {
  font-size: 14px !important;
}

.layui-font-16 {
  font-size: 16px !important;
}

.layui-font-18 {
  font-size: 18px !important;
}

.layui-font-20 {
  font-size: 20px !important;
}

.layui-font-22 {
  font-size: 22px !important;
}

.layui-font-24 {
  font-size: 24px !important;
}

.layui-font-26 {
  font-size: 26px !important;
}

.layui-font-28 {
  font-size: 28px !important;
}

.layui-font-30 {
  font-size: 30px !important;
}

.layui-font-32 {
  font-size: 32px !important;
}

.layui-font-red {
  color: #ff5722 !important;
}

.layui-font-orange {
  color: #ffb800 !important;
}

.layui-font-green {
  color: #16baaa !important;
}

.layui-font-cyan {
  color: #2f4056 !important;
}

.layui-font-blue {
  color: #01aaed !important;
}

.layui-font-purple {
  color: #a233c6 !important;
}

.layui-font-black {
  color: #000 !important;
}

.layui-font-gray {
  color: #c2c2c2 !important;
}

.layui-btn {
  vertical-align: middle;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: #16baaa;
  border: 1px solid #0000;
  border-radius: 2px;
  height: 38px;
  padding: 0 18px;
  font-size: 14px;
  line-height: 38px;
  display: inline-block;
}

.layui-btn:hover {
  opacity: .8;
  filter: alpha(opacity= 80);
  color: #fff;
}

.layui-btn:active {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.layui-btn + .layui-btn {
  margin-left: 10px;
}

.layui-btn-container {
  word-spacing: -5px;
}

.layui-btn-container .layui-btn {
  word-spacing: normal;
  margin-bottom: 10px;
  margin-right: 10px;
}

.layui-btn-container .layui-btn + .layui-btn {
  margin-left: 0;
}

.layui-table .layui-btn-container .layui-btn {
  margin-bottom: 9px;
}

.layui-btn-radius {
  border-radius: 100px;
}

.layui-btn .layui-icon {
  vertical-align: middle �;
  vertical-align: bottom;
  padding: 0 2px;
}

.layui-btn-primary {
  color: #5f5f5f;
  background: none;
  border-color: #d2d2d2;
}

.layui-btn-primary:hover {
  color: #333;
  border-color: #16baaa;
}

.layui-btn-normal {
  background-color: #1e9fff;
}

.layui-btn-warm {
  background-color: #ffb800;
}

.layui-btn-danger {
  background-color: #ff5722;
}

.layui-btn-checked {
  background-color: #16b777;
}

.layui-btn-disabled, .layui-btn-disabled:active, .layui-btn-disabled:hover {
  opacity: 1;
  color: #d2d2d2 !important;
  cursor: not-allowed !important;
  background-color: #fbfbfb !important;
  border-color: #eee !important;
}

.layui-btn-lg {
  height: 44px;
  padding: 0 25px;
  font-size: 16px;
  line-height: 44px;
}

.layui-btn-sm {
  height: 30px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 30px;
}

.layui-btn-xs {
  height: 22px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 22px;
}

.layui-btn-xs i {
  font-size: 12px !important;
}

.layui-btn-group {
  vertical-align: middle;
  font-size: 0;
  display: inline-block;
}

.layui-btn-group .layui-btn {
  border-left: 1px solid #ffffff80;
  border-radius: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.layui-btn-group .layui-btn-primary {
  border-left: none;
}

.layui-btn-group .layui-btn-primary:hover {
  color: #16baaa;
  border-color: #d2d2d2;
}

.layui-btn-group .layui-btn:first-child {
  border-left: none;
  border-radius: 2px 0 0 2px;
}

.layui-btn-group .layui-btn-primary:first-child {
  border-left: 1px solid #d2d2d2;
}

.layui-btn-group .layui-btn:last-child {
  border-radius: 0 2px 2px 0;
}

.layui-btn-group .layui-btn + .layui-btn {
  margin-left: 0;
}

.layui-btn-group + .layui-btn-group {
  margin-left: 10px;
}

.layui-btn-fluid {
  width: 100%;
}

.layui-input, .layui-select, .layui-textarea {
  line-height: 1.3;
  line-height: 38px \9 ;
  color: #000000d9;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  height: 38px;
}

.layui-input::-webkit-input-placeholder {
  line-height: 1.3;
}

.layui-select::-webkit-input-placeholder {
  line-height: 1.3;
}

.layui-textarea::-webkit-input-placeholder {
  line-height: 1.3;
}

.layui-input, .layui-textarea {
  width: 100%;
  padding-left: 10px;
  display: block;
}

.layui-input:hover, .layui-textarea:hover, .layui-input:focus, .layui-textarea:focus {
  border-color: #d2d2d2 !important;
}

.layui-textarea {
  resize: vertical;
  height: auto;
  min-height: 100px;
  padding: 6px 10px;
  line-height: 20px;
  position: relative;
}

.layui-select {
  padding: 0 10px;
}

.layui-form input[type="checkbox"], .layui-form input[type="radio"], .layui-form select {
  display: none;
}

.layui-form [lay-ignore] {
  display: initial;
}

.layui-form-item {
  clear: both;
  margin-bottom: 15px;
  position: relative;
}

.layui-form-item:after {
  content: " ";
  clear: both;
  height: 0;
  display: block;
}

.layui-form-label {
  float: left;
  text-align: right;
  width: 80px;
  padding: 9px 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  position: relative;
}

.layui-form-label-col {
  float: none;
  text-align: left;
  padding: 9px 0;
  line-height: 20px;
  display: block;
}

.layui-form-item .layui-inline {
  margin-bottom: 5px;
  margin-right: 10px;
}

.layui-input-block, .layui-input-inline {
  position: relative;
}

.layui-input-block {
  min-height: 36px;
  margin-left: 110px;
}

.layui-input-inline {
  vertical-align: middle;
  display: inline-block;
}

.layui-form-item .layui-input-inline {
  float: left;
  width: 190px;
  margin-right: 10px;
}

.layui-form-text .layui-input-inline {
  width: auto;
}

.layui-form-mid {
  float: left;
  margin-right: 10px;
  line-height: 20px;
  display: block;
  position: relative;
  padding: 9px 0 !important;
}

.layui-form-danger + .layui-form-select .layui-input, .layui-form-danger:focus {
  border-color: #ff5722 !important;
}

.layui-input-prefix, .layui-input-split, .layui-input-suffix, .layui-input-suffix .layui-input-affix {
  text-align: center;
  box-sizing: border-box;
  width: 35px;
  height: 100%;
  padding: 0 10px;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-input-prefix {
  border-radius: 2px 0 0 2px;
  left: 0;
}

.layui-input-suffix {
  border-radius: 0 2px 2px 0;
  right: 0;
}

.layui-input-split {
  border-style: solid;
  border-width: 1px;
}

.layui-input-prefix .layui-icon, .layui-input-split .layui-icon, .layui-input-suffix .layui-icon {
  color: #5f5f5f;
  font-size: 16px;
  transition: all .3s;
  position: relative;
}

.layui-input-group {
  box-sizing: border-box;
  display: table;
  position: relative;
}

.layui-input-group > * {
  vertical-align: middle;
  display: table-cell;
  position: relative;
}

.layui-input-group .layui-input {
  padding-right: 15px;
}

.layui-input-group .layui-input-prefix {
  border-right: 0;
  width: auto;
}

.layui-input-group .layui-input-suffix {
  border-left: 0;
  width: auto;
}

.layui-input-group .layui-input-split {
  white-space: nowrap;
}

.layui-input-wrap {
  line-height: 38px;
  position: relative;
}

.layui-input-wrap .layui-input {
  padding-right: 35px;
}

:is(.layui-input-wrap .layui-input::-ms-clear, .layui-input-wrap .layui-input::-ms-reveal) {
  display: none;
}

.layui-input-wrap .layui-input-prefix + .layui-input, .layui-input-wrap .layui-input-prefix ~ * .layui-input {
  padding-left: 35px;
}

.layui-input-wrap .layui-input-split + .layui-input, .layui-input-wrap .layui-input-split ~ * .layui-input {
  padding-left: 45px;
}

.layui-input-wrap .layui-input-prefix ~ .layui-form-select {
  position: static;
}

.layui-input-wrap .layui-input-prefix, .layui-input-wrap .layui-input-split, .layui-input-wrap .layui-input-suffix {
  pointer-events: none;
}

.layui-input-wrap .layui-input:focus + .layui-input-split {
  border-color: #d2d2d2;
}

.layui-input-wrap .layui-input-prefix.layui-input-split {
  border-width: 0 1px 0 0;
}

.layui-input-affix {
  line-height: 38px;
}

.layui-input-suffix .layui-input-affix {
  left: -35px;
  right: auto;
}

.layui-input-affix .layui-icon {
  color: #000c;
  cursor: pointer;
  pointer-events: auto !important;
}

.layui-input-affix .layui-icon-clear {
  color: #0000004d;
}

.layui-input-affix .layui-icon:hover {
  color: #0009;
}

.layui-form-select {
  color: #5f5f5f;
  position: relative;
}

.layui-form-select .layui-input {
  cursor: pointer;
  padding-right: 30px;
}

.layui-form-select .layui-edge {
  cursor: pointer;
  border-width: 6px;
  border-top-style: solid;
  border-top-color: #c2c2c2;
  margin-top: -3px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  right: 10px;
}

.layui-form-select dl {
  z-index: 899;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  min-width: 100%;
  max-height: 300px;
  padding: 5px 0;
  display: none;
  position: absolute;
  top: 42px;
  left: 0;
  overflow-y: auto;
  box-shadow: 1px 1px 4px #00000014;
}

.layui-form-select dl dd, .layui-form-select dl dt {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  line-height: 36px;
  overflow: hidden;
}

.layui-form-select dl dt {
  color: #999;
  font-size: 12px;
}

.layui-form-select dl dd {
  cursor: pointer;
}

.layui-form-select dl dd:hover {
  background-color: #f8f8f8;
  transition: all .5s;
}

.layui-form-select .layui-select-group dd {
  padding-left: 20px;
}

.layui-form-select dl dd.layui-select-tips {
  color: #999;
  padding-left: 10px !important;
}

.layui-form-select dl dd.layui-this {
  color: #16b777;
  background-color: #f8f8f8;
  font-weight: 700;
}

.layui-form-select dl dd.layui-disabled {
  background-color: #fff;
}

.layui-form-selected dl {
  display: block;
}

.layui-form-selected .layui-edge {
  margin-top: -9px;
  margin-top: -3px �;
  transform: rotate(180deg);
}

:root .layui-form-selected .layui-edge {
  margin-top: -9px � / IE9;
}

.layui-form-selectup dl {
  top: auto;
  bottom: 42px;
}

.layui-select-none {
  text-align: center;
  color: #999;
  margin: 5px 0;
}

.layui-select-disabled .layui-disabled {
  border-color: #eee !important;
}

.layui-select-disabled .layui-edge {
  border-top-color: #d2d2d2;
}

.layui-form-checkbox {
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  height: 30px;
  margin-right: 10px;
  padding-right: 30px;
  font-size: 0;
  line-height: 30px;
  transition: all .1s linear;
  display: inline-block;
  position: relative;
}

.layui-form-checkbox > * {
  vertical-align: middle;
  display: inline-block;
}

.layui-form-checkbox > div {
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #d2d2d2;
  border-radius: 2px 0 0 2px;
  padding: 0 11px;
  font-size: 14px;
  overflow: hidden;
}

.layui-form-checkbox:hover > div {
  background-color: #c2c2c2;
}

.layui-form-checkbox > i {
  color: #fff0;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  border-left: none;
  border-radius: 0 2px 2px 0;
  width: 30px;
  height: 100%;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-form-checkbox:hover > i {
  color: #c2c2c2;
  border-color: #c2c2c2;
}

.layui-form-checked, .layui-form-checked:hover {
  border-color: #16b777;
}

.layui-form-checked:hover > div, .layui-form-checked > div {
  background-color: #16b777;
}

.layui-form-checked:hover > i, .layui-form-checked > i {
  color: #16b777;
}

.layui-form-item .layui-form-checkbox {
  margin-top: 4px;
}

.layui-form-checkbox.layui-checkbox-disabled > div {
  background-color: #eee !important;
}

.layui-form [lay-checkbox] {
  display: none;
}

.layui-form-checkbox[lay-skin="primary"] {
  background: none;
  min-width: 18px;
  min-height: 18px;
  margin-right: 0;
  padding-left: 24px;
  padding-right: 0;
  border: none !important;
  height: auto !important;
  line-height: normal !important;
}

.layui-form-checkbox[lay-skin="primary"] > div {
  color: #5f5f5f;
  background: none;
  margin-top: -1px;
  padding-left: 0;
  padding-right: 15px;
  line-height: 18px;
}

.layui-form-checkbox[lay-skin="primary"] > i {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 14px;
  transition: all .1s linear;
  left: 0;
  right: auto;
}

.layui-form-checkbox[lay-skin="primary"]:hover > i {
  color: #fff;
  border-color: #16b777;
}

.layui-form-checked[lay-skin="primary"] > i {
  color: #fff;
  background-color: #16b777;
  border-color: #16b777 !important;
}

.layui-checkbox-disabled[lay-skin="primary"] > div {
  background: none !important;
}

.layui-form-checked.layui-checkbox-disabled[lay-skin="primary"] > i {
  background: #eee !important;
  border-color: #eee !important;
}

.layui-checkbox-disabled[lay-skin="primary"]:hover > i {
  border-color: #d2d2d2;
}

.layui-form-item .layui-form-checkbox[lay-skin="primary"] {
  margin-top: 10px;
}

.layui-form-checkbox[lay-skin="primary"] > .layui-icon-indeterminate {
  border-color: #16b777;
}

.layui-form-checkbox[lay-skin="primary"] > .layui-icon-indeterminate:before {
  content: "";
  vertical-align: middle;
  background-color: #16b777;
  width: 50%;
  height: 1px;
  margin: -1px auto 0;
  display: inline-block;
  position: relative;
}

.layui-form-switch {
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  min-width: 44px;
  height: 24px;
  margin-top: 8px;
  padding: 0 5px;
  line-height: 22px;
  transition: all .1s linear;
  display: inline-block;
  position: relative;
}

.layui-form-switch > i {
  background-color: #d2d2d2;
  border-radius: 20px;
  width: 16px;
  height: 16px;
  transition: all .1s linear;
  position: absolute;
  top: 3px;
  left: 5px;
}

.layui-form-switch > div {
  margin-left: 21px;
  font-size: 12px;
  position: relative;
  top: 0;
  text-align: center !important;
  color: #999 !important;
  padding: 0 !important;
  font-style: normal !important;
}

.layui-form-onswitch {
  background-color: #16b777;
  border-color: #16b777;
}

.layui-form-onswitch > i {
  background-color: #fff;
  margin-left: -21px;
  left: 100%;
}

.layui-form-onswitch > div {
  margin-left: 0;
  margin-right: 21px;
  color: #fff !important;
}

.layui-checkbox-disabled {
  border-color: #eee !important;
}

.layui-checkbox-disabled > div {
  color: #c2c2c2 !important;
}

.layui-checkbox-disabled > i {
  border-color: #eee !important;
}

.layui-checkbox-disabled:hover > i {
  color: #fff !important;
}

.layui-form-radio {
  vertical-align: middle;
  cursor: pointer;
  margin: 6px 10px 0 0;
  padding-right: 10px;
  font-size: 0;
  line-height: 28px;
  display: inline-block;
}

.layui-form-radio > * {
  vertical-align: middle;
  font-size: 14px;
  display: inline-block;
}

.layui-form-radio > i {
  color: #c2c2c2;
  margin-right: 8px;
  font-size: 22px;
}

.layui-form-radio:hover > *, .layui-form-radioed, .layui-form-radioed > i {
  color: #16b777;
}

.layui-radio-disabled > i {
  color: #eee !important;
}

.layui-radio-disabled > * {
  color: #c2c2c2 !important;
}

.layui-form [lay-radio] {
  display: none;
}

.layui-form-pane .layui-form-label {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background-color: #fafafa;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px 0 0 2px;
  width: 110px;
  height: 38px;
  padding: 8px 15px;
  line-height: 20px;
  overflow: hidden;
}

.layui-form-pane .layui-input-inline {
  margin-left: -1px;
}

.layui-form-pane .layui-input-block {
  margin-left: 110px;
  left: -1px;
}

.layui-form-pane .layui-input {
  border-radius: 0 2px 2px 0;
}

.layui-form-pane .layui-form-text .layui-form-label {
  float: none;
  box-sizing: border-box;
  text-align: left;
  border-radius: 2px;
  width: 100%;
}

.layui-form-pane .layui-form-text .layui-input-inline {
  clear: both;
  margin: 0;
  display: block;
  top: -1px;
}

.layui-form-pane .layui-form-text .layui-input-block {
  margin: 0;
  top: -1px;
  left: 0;
}

.layui-form-pane .layui-form-text .layui-textarea {
  border-radius: 0 0 2px 2px;
  min-height: 100px;
}

.layui-form-pane .layui-form-checkbox {
  margin: 4px 0 4px 10px;
}

.layui-form-pane .layui-form-radio, .layui-form-pane .layui-form-switch {
  margin-top: 6px;
  margin-left: 10px;
}

.layui-form-pane .layui-form-item[pane] {
  border-style: solid;
  border-width: 1px;
  position: relative;
}

.layui-form-pane .layui-form-item[pane] .layui-form-label {
  border-width: 0 1px 0 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-form-pane .layui-form-item[pane] .layui-input-inline {
  margin-left: 110px;
}

@media screen and (width <= 450px) {
  .layui-form-item .layui-form-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .layui-form-item .layui-inline {
    clear: both;
    margin-bottom: 20px;
    margin-right: 0;
    display: block;
  }

  .layui-form-item .layui-inline:after {
    content: " ";
    clear: both;
    height: 0;
    display: block;
  }

  .layui-form-item .layui-input-inline {
    float: none;
    margin: 0 0 10px 112px;
    display: block;
    left: -3px;
    width: auto !important;
  }

  .layui-form-item .layui-input-inline + .layui-form-mid {
    margin-left: 110px;
    padding: 0;
    top: -5px;
  }

  .layui-form-item .layui-form-checkbox {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.layui-laypage {
  vertical-align: middle;
  margin: 10px 0;
  font-size: 0;
  display: inline-block;
}

.layui-laypage > a:first-child, .layui-laypage > a:first-child em {
  border-radius: 2px 0 0 2px;
}

.layui-laypage > a:last-child, .layui-laypage > a:last-child em {
  border-radius: 0 2px 2px 0;
}

.layui-laypage > :first-child {
  margin-left: 0 !important;
}

.layui-laypage > :last-child {
  margin-right: 0 !important;
}

.layui-laypage a, .layui-laypage button, .layui-laypage input, .layui-laypage select, .layui-laypage span {
  border: 1px solid #eee;
}

.layui-laypage a, .layui-laypage span {
  vertical-align: middle;
  color: #333;
  background-color: #fff;
  height: 28px;
  margin: 0 -1px 5px 0;
  padding: 0 15px;
  font-size: 12px;
  line-height: 28px;
  display: inline-block;
}

.layui-laypage a[data-page] {
  color: #333;
}

.layui-laypage a {
  cursor: pointer;
  text-decoration: none !important;
}

.layui-laypage a:hover {
  color: #16baaa;
}

.layui-laypage em {
  font-style: normal;
}

.layui-laypage .layui-laypage-spr {
  color: #999;
  font-weight: 700;
}

.layui-laypage .layui-laypage-curr {
  position: relative;
}

.layui-laypage .layui-laypage-curr em {
  color: #fff;
  position: relative;
}

.layui-laypage .layui-laypage-curr .layui-laypage-em {
  background-color: #16baaa;
  width: 100%;
  height: 100%;
  padding: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.layui-laypage-em {
  border-radius: 2px;
}

.layui-laypage-next em, .layui-laypage-prev em {
  font-family: Sim sun;
  font-size: 16px;
}

.layui-laypage .layui-laypage-count, .layui-laypage .layui-laypage-limits, .layui-laypage .layui-laypage-refresh, .layui-laypage .layui-laypage-skip {
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
}

.layui-laypage .layui-laypage-limits, .layui-laypage .layui-laypage-refresh {
  vertical-align: top;
}

.layui-laypage .layui-laypage-refresh i {
  cursor: pointer;
  font-size: 18px;
}

.layui-laypage select {
  cursor: pointer;
  border-radius: 2px;
  height: 22px;
  padding: 3px;
}

.layui-laypage .layui-laypage-skip {
  color: #999;
  height: 30px;
  line-height: 30px;
}

.layui-laypage button, .layui-laypage input {
  vertical-align: top;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2px;
  height: 30px;
  line-height: 30px;
}

.layui-laypage input {
  text-align: center;
  width: 40px;
  margin: 0 10px;
  padding: 0 3px;
  display: inline-block;
}

.layui-laypage input:focus, .layui-laypage select:focus {
  border-color: #16baaa !important;
}

.layui-laypage button {
  cursor: pointer;
  margin-left: 10px;
  padding: 0 10px;
}

.layui-flow-more {
  text-align: center;
  color: #999;
  clear: both;
  margin: 10px 0;
  font-size: 14px;
}

.layui-flow-more a {
  height: 32px;
  line-height: 32px;
}

.layui-flow-more a * {
  vertical-align: top;
  display: inline-block;
}

.layui-flow-more a cite {
  color: #333;
  background-color: #eee;
  border-radius: 3px;
  padding: 0 20px;
  font-style: normal;
}

.layui-flow-more a cite:hover {
  opacity: .8;
}

.layui-flow-more a i {
  color: #737383;
  font-size: 30px;
}

.layui-table {
  color: #5f5f5f;
  background-color: #fff;
  width: 100%;
  margin: 10px 0;
}

.layui-table tr {
  transition: all .3s;
}

.layui-table th {
  text-align: left;
  font-weight: 600;
}

.layui-table-mend {
  background-color: #fff;
}

.layui-table-click, .layui-table-hover, .layui-table[lay-even] tbody tr:nth-child(2n) {
  background-color: #f8f8f8;
}

.layui-table td, .layui-table th, .layui-table-col-set, .layui-table-fixed-r, .layui-table-grid-down, .layui-table-header, .layui-table-mend, .layui-table-page, .layui-table-tips-main, .layui-table-tool, .layui-table-total, .layui-table-view, .layui-table[lay-skin="line"], .layui-table[lay-skin="row"] {
  border: 1px solid #eee;
}

.layui-table td, .layui-table th {
  min-height: 20px;
  padding: 9px 15px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.layui-table[lay-skin="line"] td, .layui-table[lay-skin="line"] th {
  border-width: 0 0 1px;
}

.layui-table[lay-skin="row"] td, .layui-table[lay-skin="row"] th {
  border-width: 0 1px 0 0;
}

.layui-table[lay-skin="nob"] td, .layui-table[lay-skin="nob"] th {
  border: none;
}

.layui-table img {
  max-width: 100px;
}

.layui-table[lay-size="lg"] td, .layui-table[lay-size="lg"] th {
  padding: 15px 30px;
}

.layui-table-view .layui-table[lay-size="lg"] .layui-table-cell {
  height: 50px;
  line-height: 40px;
}

.layui-table[lay-size="sm"] td, .layui-table[lay-size="sm"] th {
  padding: 5px 10px;
  font-size: 12px;
}

.layui-table-view .layui-table[lay-size="sm"] .layui-table-cell {
  height: 30px;
  padding-top: 5px;
  padding-left: 11px;
  padding-right: 11px;
  line-height: 20px;
}

.layui-table[lay-data], .layui-table[lay-options] {
  display: none;
}

.layui-table-box {
  position: relative;
  overflow: hidden;
}

.layui-table-view {
  clear: both;
}

.layui-table-view .layui-table {
  border-collapse: separate;
  border: 0;
  width: auto;
  margin: 0;
  position: relative;
}

.layui-table-view .layui-table[lay-skin="line"] {
  border-width: 0 1px 0 0;
}

.layui-table-view .layui-table[lay-skin="row"] {
  border-width: 0 0 1px;
}

.layui-table-view .layui-table td, .layui-table-view .layui-table th {
  border-top: none;
  border-left: none;
  padding: 0;
}

.layui-table-view .layui-table th.layui-unselect .layui-table-cell span {
  cursor: pointer;
}

.layui-table-view .layui-table td {
  cursor: default;
}

.layui-table-view .layui-table td[data-edit] {
  cursor: text;
}

.layui-table-view .layui-table td[data-edit]:hover:after {
  box-sizing: border-box;
  pointer-events: none;
  content: "";
  border: 1px solid #16b777;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-table-view .layui-form-checkbox[lay-skin="primary"] i {
  width: 18px;
  height: 18px;
  line-height: 16px;
}

.layui-table-view .layui-form-radio {
  padding: 0;
  line-height: 0;
}

.layui-table-view .layui-form-radio > i {
  margin: 0;
  font-size: 20px;
}

.layui-table-init {
  text-align: center;
  z-index: 199;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-table-init .layui-icon {
  color: #c2c2c2;
  margin: -15px 0 0 -15px;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.layui-table-header {
  border-width: 0 0 1px;
  overflow: hidden;
}

.layui-table-header .layui-table {
  margin-bottom: -1px;
}

.layui-table-column {
  border-width: 0 0 1px;
  width: 100%;
  min-height: 41px;
  padding: 8px 16px;
  position: relative;
}

.layui-table-column .layui-btn-container {
  margin-bottom: -8px;
}

.layui-table-column .layui-btn-container .layui-btn {
  margin-bottom: 8px;
  margin-right: 8px;
}

.layui-table-tool .layui-inline[lay-event] {
  text-align: center;
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  padding: 5px;
  line-height: 16px;
  transition: all .5s;
  position: relative;
}

.layui-table-tool .layui-inline[lay-event]:hover {
  border: 1px solid #999;
}

.layui-table-tool-temp {
  padding-right: 120px;
}

.layui-table-tool-self {
  position: absolute;
  top: 10px;
  right: 17px;
}

.layui-table-tool .layui-table-tool-self .layui-inline[lay-event] {
  margin: 0 0 0 10px;
}

.layui-table-tool-panel {
  z-index: 399;
  text-align: left;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  min-width: 150px;
  min-height: 40px;
  position: absolute;
  top: 29px;
  left: -1px;
  overflow-y: auto;
  box-shadow: 0 2px 4px #0000001f;
  padding: 5px 0 !important;
}

.layui-table-tool-panel li {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  line-height: 30px;
  transition: all .5s;
  overflow: hidden;
  margin: 0 !important;
  list-style-type: none !important;
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin="primary"] {
  width: 100%;
}

.layui-table-tool-panel li:hover {
  background-color: #f8f8f8;
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin="primary"] {
  padding-left: 28px;
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin="primary"] i {
  position: absolute;
  top: 0;
  left: 0;
}

.layui-table-tool-panel li .layui-form-checkbox[lay-skin="primary"] span {
  padding: 0;
}

.layui-table-tool .layui-table-tool-self .layui-table-tool-panel {
  left: auto;
  right: -1px;
}

.layui-table-col-set {
  background-color: #fff;
  border-width: 0 0 0 1px;
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-table-sort {
  width: 10px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer !important;
}

.layui-table-sort .layui-edge {
  border-width: 5px;
  position: absolute;
  left: 5px;
}

.layui-table-sort .layui-table-sort-asc {
  border-top: none;
  border-bottom-style: solid;
  border-bottom-color: #b2b2b2;
  top: 3px;
}

.layui-table-sort .layui-table-sort-asc:hover {
  border-bottom-color: #5f5f5f;
}

.layui-table-sort .layui-table-sort-desc {
  border-top-style: solid;
  border-top-color: #b2b2b2;
  border-bottom: none;
  bottom: 5px;
}

.layui-table-sort .layui-table-sort-desc:hover {
  border-top-color: #5f5f5f;
}

.layui-table-sort[lay-sort="asc"] .layui-table-sort-asc {
  border-bottom-color: #000;
}

.layui-table-sort[lay-sort="desc"] .layui-table-sort-desc {
  border-top-color: #000;
}

.layui-table-cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  height: 38px;
  padding: 6px 15px;
  line-height: 28px;
  position: relative;
  overflow: hidden;
}

.layui-table-cell .layui-form-checkbox[lay-skin="primary"] {
  padding: 0;
  top: -1px;
}

.layui-table-cell .layui-table-link {
  color: #01aaed;
}

.layui-table-cell .layui-btn {
  vertical-align: inherit;
}

.layui-table-cell[align="center"] {
  -webkit-box-pack: center;
}

.layui-table-cell[align="right"] {
  -webkit-box-pack: end;
}

.laytable-cell-checkbox, .laytable-cell-numbers, .laytable-cell-radio, .laytable-cell-space {
  text-align: center;
  -webkit-box-pack: center;
}

.layui-table-body {
  margin-bottom: -1px;
  margin-right: -1px;
  position: relative;
  overflow: auto;
}

.layui-table-body .layui-none {
  text-align: center;
  color: #999;
  padding: 30px 15px;
  line-height: 26px;
}

.layui-table-fixed {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-table-fixed .layui-table-body {
  overflow: hidden;
}

.layui-table-fixed-l {
  box-shadow: 1px 0 8px #00000014;
}

.layui-table-fixed-r {
  border-width: 0 0 0 1px;
  left: auto;
  right: -1px;
  box-shadow: -1px 0 8px #00000014;
}

.layui-table-fixed-r .layui-table-header {
  position: relative;
  overflow: visible;
}

.layui-table-mend {
  border-width: 0 0 0 1px;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -49px;
}

.layui-table-tool {
  border-width: 0 0 1px;
  width: 100%;
  min-height: 50px;
  padding: 10px 15px;
  line-height: 30px;
  position: relative;
}

.layui-table-tool .layui-btn-container {
  margin-bottom: -10px;
}

.layui-table-total {
  border-width: 1px 0 0;
  margin-bottom: -1px;
  overflow: hidden;
}

.layui-table-page {
  white-space: nowrap;
  border-width: 1px 0 0;
  margin-bottom: -1px;
  overflow: hidden;
}

.layui-table-page > div {
  height: 26px;
}

.layui-table-page .layui-laypage {
  margin: 0;
}

.layui-table-page .layui-laypage a, .layui-table-page .layui-laypage span {
  background: none;
  border: none;
  height: 26px;
  margin-bottom: 10px;
  line-height: 26px;
}

.layui-table-page .layui-laypage a, .layui-table-page .layui-laypage span.layui-laypage-curr {
  padding: 0 12px;
}

.layui-table-page .layui-laypage span {
  margin-left: 0;
  padding: 0;
}

.layui-table-page .layui-laypage .layui-laypage-prev {
  margin-left: -11px !important;
}

.layui-table-page .layui-laypage .layui-laypage-curr .layui-laypage-em {
  padding: 0;
  top: 0;
  left: 0;
}

.layui-table-page .layui-laypage button, .layui-table-page .layui-laypage input {
  height: 26px;
  line-height: 26px;
}

.layui-table-page .layui-laypage input {
  width: 40px;
}

.layui-table-page .layui-laypage button {
  padding: 0 10px;
}

.layui-table-page select {
  height: 18px;
}

.layui-table-pagebar {
  float: right;
  line-height: 23px;
}

.layui-table-pagebar .layui-btn-sm {
  margin-top: -1px;
}

.layui-table-pagebar .layui-btn-xs {
  margin-top: 2px;
}

.layui-table-view select[lay-ignore] {
  display: inline-block;
}

.layui-table-patch .layui-table-cell {
  width: 30px;
  padding: 0;
}

.layui-table-edit {
  z-index: 189;
  background-color: #fff;
  border-radius: 0;
  min-width: 100%;
  min-height: 100%;
  padding: 5px 14px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 20px #00000026;
}

.layui-table-edit:focus {
  border-color: #16b777 !important;
}

input.layui-input.layui-table-edit {
  height: 100%;
}

select.layui-table-edit {
  border-color: #d2d2d2;
  padding: 0 0 0 10px;
}

.layui-table-view .layui-form-checkbox, .layui-table-view .layui-form-radio, .layui-table-view .layui-form-switch {
  margin: 0;
  top: 0;
}

.layui-table-view .layui-form-checkbox {
  height: 26px;
  line-height: 26px;
  top: -1px;
}

.layui-table-view .layui-form-checkbox i {
  height: 26px;
}

.layui-table-grid .layui-table-cell {
  overflow: visible;
}

.layui-table-grid-down {
  text-align: center;
  color: #999;
  cursor: pointer;
  background-color: #fff;
  border-width: 0 0 0 1px;
  width: 26px;
  height: 100%;
  padding: 5px 0;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-table-grid-down .layui-icon {
  margin: -8px 0 0 -8px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.layui-table-grid-down:hover {
  background-color: #fbfbfb;
}

body .layui-table-tips .layui-layer-content {
  background: none;
  padding: 0;
  box-shadow: 0 1px 6px #0000001f;
}

.layui-table-tips-main {
  color: #5f5f5f;
  background-color: #fff;
  max-height: 150px;
  margin: -49px 0 0 -1px;
  padding: 8px 15px;
  font-size: 14px;
  overflow-y: scroll;
}

.layui-table-tips-c {
  cursor: pointer;
  color: #fff;
  background-color: #5f5f5f;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px;
  position: absolute;
  top: -13px;
  right: -3px;
}

.layui-table-tips-c:hover {
  background-color: #777;
}

.layui-table-tips-c:before {
  position: relative;
  right: -2px;
}

.layui-table-tree-nodeIcon {
  max-width: 20px;
}

.layui-table-tree-nodeIcon > * {
  width: 100%;
}

.layui-table-tree-flexIcon, .layui-table-tree-nodeIcon {
  margin-right: 2px;
}

.layui-table-tree-flexIcon {
  cursor: pointer;
}

.layui-upload-file {
  opacity: .01;
  filter: Alpha(opacity= 1);
  display: none !important;
}

.layui-upload-list {
  margin: 11px 0;
}

.layui-upload-choose {
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  padding: 0 10px;
  font-size: 14px;
  overflow: hidden;
}

.layui-upload-drag {
  text-align: center;
  cursor: pointer;
  color: #999;
  background-color: #fff;
  border: 1px dashed #e2e2e2;
  padding: 30px;
  display: inline-block;
  position: relative;
}

.layui-upload-drag .layui-icon {
  color: #16baaa;
  font-size: 50px;
}

.layui-upload-drag[lay-over] {
  border-color: #16baaa;
}

.layui-upload-form {
  display: inline-block;
}

.layui-upload-iframe {
  visibility: hidden;
  border: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.layui-upload-wrap {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.layui-upload-wrap .layui-upload-file {
  z-index: 10;
  opacity: .01;
  filter: Alpha(opacity= 1);
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 100px;
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
}

.layui-btn-container .layui-upload-choose {
  padding-left: 0;
}

.layui-menu {
  box-sizing: border-box;
  background-color: #fff;
  margin: 5px 0;
  position: relative;
}

.layui-menu * {
  box-sizing: border-box;
}

.layui-menu li, .layui-menu-body-title, .layui-menu-body-title a {
  color: initial;
  padding: 5px 15px;
}

.layui-menu li {
  color: #000c;
  white-space: nowrap;
  cursor: pointer;
  margin: 1px 0;
  font-size: 14px;
  line-height: 26px;
  transition: all .3s;
  position: relative;
}

.layui-menu li:hover {
  background-color: #f8f8f8;
}

.layui-menu li.layui-disabled, .layui-menu li.layui-disabled * {
  color: #d2d2d2 !important;
  cursor: not-allowed !important;
  background: none !important;
}

.layui-menu-item-parent:hover > .layui-menu-body-panel {
  animation-name: layui-fadein;
  animation-duration: .3s;
  animation-delay: .2s;
  animation-fill-mode: both;
  display: block;
}

.layui-menu-item-group > .layui-menu-body-title, .layui-menu-item-parent > .layui-menu-body-title {
  padding-right: 38px;
}

.layui-menu .layui-menu-item-divider:hover, .layui-menu .layui-menu-item-group:hover, .layui-menu .layui-menu-item-none:hover {
  cursor: default;
  background: none;
}

.layui-menu .layui-menu-item-group > ul {
  margin: 5px 0 -5px;
}

.layui-menu .layui-menu-item-group > .layui-menu-body-title {
  color: #00000059;
  -webkit-user-select: none;
  user-select: none;
}

.layui-menu .layui-menu-item-none {
  color: #00000059;
  cursor: default;
  text-align: center;
}

.layui-menu .layui-menu-item-divider {
  border-bottom: 1px solid #eee;
  height: 0;
  margin: 5px 0;
  padding: 0;
  line-height: 0;
  overflow: hidden;
}

.layui-menu .layui-menu-item-down:hover, .layui-menu .layui-menu-item-up:hover {
  cursor: pointer;
}

.layui-menu .layui-menu-item-up > .layui-menu-body-title {
  color: #000c;
}

.layui-menu .layui-menu-item-up > ul {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.layui-menu .layui-menu-item-down:hover > .layui-menu-body-title > .layui-icon, .layui-menu .layui-menu-item-up > .layui-menu-body-title:hover > .layui-icon {
  color: #000;
}

.layui-menu .layui-menu-item-down > ul {
  visibility: visible;
  height: auto;
}

.layui-menu .layui-menu-item-checked, .layui-menu .layui-menu-item-checked2 {
  color: #16b777;
  background-color: #f8f8f8 !important;
}

.layui-menu .layui-menu-item-checked a, .layui-menu .layui-menu-item-checked2 a {
  color: #16b777;
}

.layui-menu .layui-menu-item-checked:after {
  content: "";
  border-right: 3px solid #16b777;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
}

.layui-menu-body-title {
  text-overflow: ellipsis;
  margin: -5px -15px;
  position: relative;
  overflow: hidden;
}

.layui-menu-body-title a {
  color: #000c;
  margin: -5px -15px;
  display: block;
}

.layui-menu-body-title a:hover {
  transition: all .3s;
}

.layui-menu-body-title > .layui-icon {
  margin-top: -6px;
  font-size: 14px;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 15px;
}

.layui-menu-body-title > .layui-icon:hover {
  transition: all .3s;
}

.layui-menu-body-title > .layui-icon-right {
  right: 14px;
}

.layui-menu-body-panel {
  z-index: 1000;
  margin-left: 13px;
  padding: 5px 0;
  display: none;
  position: absolute;
  top: -7px;
  left: 100%;
}

.layui-menu-body-panel:before {
  content: "";
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -16px;
}

.layui-menu-body-panel-left {
  margin: 0 13px;
  left: auto;
  right: 100%;
}

.layui-menu-body-panel-left:before {
  left: auto;
  right: -16px;
}

.layui-menu-lg li {
  line-height: 32px;
}

.layui-menu-lg .layui-menu-body-title a:hover, .layui-menu-lg li:hover {
  color: #16b777;
  background: none;
}

.layui-menu-lg li .layui-menu-body-panel {
  margin-left: 14px;
}

.layui-menu-lg li .layui-menu-body-panel-left {
  margin: 0 15px;
}

.layui-dropdown {
  z-index: 77777777;
  min-width: 100px;
  margin: 5px 0;
  position: absolute;
  top: -999999px;
  left: -999999px;
}

.layui-dropdown:before {
  content: "";
  width: 100%;
  height: 6px;
  position: absolute;
  top: -6px;
  left: 0;
}

.layui-dropdown-shade {
  _height: expression(document. body. offsetHeight + "px");
  _position: absolute;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.layui-nav {
  color: #fff;
  box-sizing: border-box;
  background-color: #2f363c;
  border-radius: 2px;
  padding: 0 15px;
  font-size: 0;
  position: relative;
}

.layui-nav * {
  font-size: 14px;
}

.layui-nav .layui-nav-item {
  vertical-align: middle;
  margin-top: 0;
  line-height: 60px;
  list-style: none;
  display: inline-block;
  position: relative;
}

.layui-nav .layui-nav-item a {
  color: #ffffffb3;
  padding: 0 20px;
  transition: all .3s;
  display: block;
}

.layui-nav .layui-this:after, .layui-nav-bar {
  content: "";
  pointer-events: none;
  background-color: #16b777;
  width: 0;
  height: 3px;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-nav-bar {
  z-index: 1000;
}

.layui-nav[lay-bar="disabled"] .layui-nav-bar {
  display: none;
}

.layui-nav .layui-nav-item a:hover, .layui-nav .layui-this a {
  color: #fff;
  text-decoration: none;
}

.layui-nav .layui-this:after {
  width: 100%;
  top: auto;
  bottom: 0;
}

.layui-nav-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.layui-nav .layui-nav-more {
  cursor: pointer;
  margin-top: 0;
  font-size: 12px;
  transition: all .2s;
  position: absolute;
  top: 0;
  right: 3px;
  left: auto !important;
}

.layui-nav .layui-nav-mored, .layui-nav-itemed > a .layui-nav-more {
  transform: rotate(180deg);
}

.layui-nav-child {
  z-index: 100;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  min-width: 100%;
  padding: 5px 0;
  line-height: 36px;
  display: none;
  position: absolute;
  top: 65px;
  left: 0;
  box-shadow: 0 2px 4px #0000001f;
}

.layui-nav .layui-nav-child a {
  color: #000c;
}

.layui-nav .layui-nav-child a:hover {
  color: #000c;
  background-color: #f8f8f8;
}

.layui-nav-child dd {
  margin: 1px 0;
  position: relative;
}

.layui-nav-child dd.layui-this {
  color: #000;
  background-color: #f8f8f8;
}

.layui-nav-child dd.layui-this:after {
  display: none;
}

.layui-nav-child-r {
  left: auto;
  right: 0;
}

.layui-nav-child-c {
  text-align: center;
}

.layui-nav.layui-nav-tree {
  width: 200px;
  padding: 0;
}

.layui-nav-tree .layui-nav-item {
  width: 100%;
  line-height: 40px;
  display: block;
}

.layui-nav-tree .layui-nav-item a {
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}

.layui-nav-tree .layui-nav-item > a {
  padding-top: 5px;
  padding-bottom: 5px;
}

.layui-nav-tree .layui-nav-more {
  right: 15px;
}

.layui-nav-tree .layui-nav-item > a .layui-nav-more {
  padding: 5px 0;
}

.layui-nav-tree .layui-nav-bar {
  width: 5px;
  height: 0;
}

.layui-side .layui-nav-tree .layui-nav-bar {
  width: 2px;
}

.layui-nav-tree .layui-nav-child dd.layui-this, .layui-nav-tree .layui-nav-child dd.layui-this a, .layui-nav-tree .layui-this, .layui-nav-tree .layui-this > a, .layui-nav-tree .layui-this > a:hover {
  color: #fff;
  background-color: #16baaa;
}

.layui-nav-tree .layui-this:after {
  display: none;
}

.layui-nav-itemed > a, .layui-nav-tree .layui-nav-title a, .layui-nav-tree .layui-nav-title a:hover {
  color: #fff !important;
}

.layui-nav-tree .layui-nav-bar {
  background-color: #16baaa;
}

.layui-nav-tree .layui-nav-child {
  z-index: 0;
  box-shadow: none;
  border: none;
  position: relative;
  top: 0;
}

.layui-nav-tree .layui-nav-child dd {
  margin: 0;
}

.layui-nav-tree .layui-nav-child a {
  color: #ffffffb3;
}

.layui-nav-tree .layui-nav-child, .layui-nav-tree .layui-nav-child a:hover {
  color: #fff;
  background: none;
}

.layui-nav-itemed > .layui-nav-child {
  display: block;
  background-color: #0000004d !important;
}

.layui-nav-itemed > .layui-nav-child > .layui-this > .layui-nav-child {
  display: block;
}

.layui-nav-side {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

.layui-nav-tree.layui-bg-gray a, .layui-nav.layui-bg-gray .layui-nav-item a {
  color: #000c;
}

.layui-nav-tree.layui-bg-gray {
  padding: 6px 0;
}

.layui-nav-tree.layui-bg-gray .layui-nav-itemed > a {
  color: #000 !important;
}

.layui-nav.layui-bg-gray .layui-this a {
  color: #16b777;
}

.layui-nav-tree.layui-bg-gray .layui-nav-itemed > .layui-nav-child {
  padding-left: 11px;
  background: none !important;
}

.layui-nav-tree.layui-bg-gray .layui-nav-item > a {
  padding-top: 0;
  padding-bottom: 0;
}

.layui-nav-tree.layui-bg-gray .layui-nav-item > a .layui-nav-more {
  padding: 0;
}

.layui-nav-tree.layui-bg-gray .layui-nav-child dd.layui-this, .layui-nav-tree.layui-bg-gray .layui-nav-child dd.layui-this a, .layui-nav-tree.layui-bg-gray .layui-this, .layui-nav-tree.layui-bg-gray .layui-this > a {
  font-weight: 700;
  color: #16b777 !important;
  background: none !important;
}

.layui-nav-tree.layui-bg-gray .layui-nav-bar {
  background-color: #16b777;
}

.layui-breadcrumb {
  visibility: hidden;
  font-size: 0;
}

.layui-breadcrumb > * {
  font-size: 14px;
}

.layui-breadcrumb a {
  color: #999 !important;
}

.layui-breadcrumb a:hover {
  color: #16b777 !important;
}

.layui-breadcrumb a cite {
  color: #5f5f5f;
  font-style: normal;
}

.layui-breadcrumb span[lay-separator] {
  color: #999;
  margin: 0 10px;
}

.layui-tab {
  margin: 10px 0;
  text-align: left !important;
}

.layui-tab[overflow] > .layui-tab-title {
  overflow: hidden;
}

.layui-tab .layui-tab-title {
  white-space: nowrap;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 40px;
  font-size: 0;
  transition: all .2s;
  position: relative;
  left: 0;
}

.layui-tab .layui-tab-title li {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  min-width: 65px;
  margin: 0;
  padding: 0 15px;
  font-size: 14px;
  line-height: 40px;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.layui-tab .layui-tab-title li a {
  margin: 0 -15px;
  padding: 0 15px;
  display: block;
}

.layui-tab-title .layui-this {
  color: #000;
}

.layui-tab-title .layui-this:after {
  content: "";
  box-sizing: border-box;
  pointer-events: none;
  border-style: solid;
  border-width: 1px;
  border-bottom-color: #fff;
  border-radius: 2px 2px 0 0;
  width: 100%;
  height: 41px;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-tab-bar {
  z-index: 10;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  width: 30px;
  height: 39px;
  line-height: 39px;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-tab-bar .layui-icon {
  transition: all .3s;
  display: inline-block;
  position: relative;
  top: 3px;
}

.layui-tab-item {
  display: none;
}

.layui-tab-more {
  padding-right: 30px;
  white-space: normal !important;
  height: auto !important;
}

.layui-tab-more li.layui-this:after {
  border-bottom-color: #eee;
  border-radius: 2px;
}

.layui-tab-more .layui-tab-bar .layui-icon {
  top: -2px;
  top: 3px �;
  transform: rotate(180deg);
}

:root .layui-tab-more .layui-tab-bar .layui-icon {
  top: -2px � / IE9;
}

.layui-tab-content {
  padding: 15px 0;
}

.layui-tab-title li .layui-tab-close {
  text-align: center;
  color: #c2c2c2;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  transition: all .2s;
  display: inline-block;
  position: relative;
  top: 1px;
}

.layui-tab-title li .layui-tab-close:hover {
  color: #fff;
  background-color: #ff5722;
  border-radius: 2px;
}

.layui-tab-brief > .layui-tab-title .layui-this {
  color: #16baaa;
}

.layui-tab-brief > .layui-tab-more li.layui-this:after, .layui-tab-brief > .layui-tab-title .layui-this:after {
  border: none;
  border-bottom: 2px solid #16b777;
  border-radius: 0;
}

.layui-tab-brief[overflow] > .layui-tab-title .layui-this:after {
  top: -1px;
}

.layui-tab-card {
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  box-shadow: 0 2px 5px #0000001a;
}

.layui-tab-card > .layui-tab-title {
  background-color: #fafafa;
}

.layui-tab-card > .layui-tab-title li {
  margin-left: -1px;
  margin-right: -1px;
}

.layui-tab-card > .layui-tab-title .layui-this {
  background-color: #fff;
}

.layui-tab-card > .layui-tab-title .layui-this:after {
  border-width: 1px;
  border-top-style: none;
  border-top-color: currentColor;
  border-bottom-color: #fff;
}

.layui-tab-card > .layui-tab-title .layui-tab-bar {
  border-top: none;
  border-right: none;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
}

.layui-tab-card > .layui-tab-more .layui-this {
  color: #16b777;
  background: none;
}

.layui-tab-card > .layui-tab-more .layui-this:after {
  border: none;
}

.layui-timeline {
  padding-left: 5px;
}

.layui-timeline-item {
  padding-bottom: 20px;
  position: relative;
}

.layui-timeline-axis {
  z-index: 10;
  color: #16b777;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: -5px;
}

.layui-timeline-axis:hover {
  color: #ff5722;
}

.layui-timeline-item:before {
  content: "";
  z-index: 0;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 5px;
}

.layui-timeline-item:first-child:before {
  display: block;
}

.layui-timeline-item:last-child:before {
  display: none;
}

.layui-timeline-content {
  padding-left: 25px;
}

.layui-timeline-title {
  margin-bottom: 10px;
  line-height: 22px;
  position: relative;
}

.layui-badge, .layui-badge-dot, .layui-badge-rim {
  text-align: center;
  color: #fff;
  background-color: #ff5722;
  border-radius: 2px;
  padding: 0 6px;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.layui-badge {
  height: 18px;
  line-height: 18px;
}

.layui-badge-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  padding: 0;
}

.layui-badge-rim {
  color: #5f5f5f;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  height: 18px;
  line-height: 18px;
}

.layui-btn .layui-badge, .layui-btn .layui-badge-dot {
  margin-left: 5px;
}

.layui-nav .layui-badge, .layui-nav .layui-badge-dot {
  margin: -5px 6px 0;
  position: absolute;
  top: 50%;
}

.layui-nav .layui-badge {
  margin-top: -10px;
}

.layui-tab-title .layui-badge, .layui-tab-title .layui-badge-dot {
  top: -2px;
  left: 5px;
}

.layui-carousel {
  background-color: #f8f8f8;
  position: relative;
  top: 0;
  left: 0;
}

.layui-carousel > [carousel-item] {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.layui-carousel > [carousel-item]:before {
  content: "";
  text-align: center;
  color: #c2c2c2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100px;
  margin: -10px 0 0 -50px;
  font-size: 30px;
  font-style: normal;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: layui-icon !important;
}

.layui-carousel > [carousel-item] > * {
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  transition-duration: .3s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-carousel-updown > * {
  transition: up .3s ease-in-out;
}

.layui-carousel-arrow {
  display: none �;
  opacity: 0;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #0003;
  border: 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  font-size: 20px;
  line-height: 36px;
  transition-duration: .3s;
  position: absolute;
  top: 50%;
  left: 10px;
}

.layui-carousel-arrow[lay-type="add"] {
  right: 10px;
  left: auto !important;
}

.layui-carousel[lay-arrow="always"] .layui-carousel-arrow {
  opacity: 1;
  left: 20px;
}

.layui-carousel[lay-arrow="always"] .layui-carousel-arrow[lay-type="add"] {
  right: 20px;
}

.layui-carousel[lay-arrow="none"] .layui-carousel-arrow {
  display: none;
}

.layui-carousel-arrow:hover, .layui-carousel-ind ul:hover {
  background-color: #00000059;
}

.layui-carousel:hover .layui-carousel-arrow {
  display: block �;
  opacity: 1;
  left: 20px;
}

.layui-carousel:hover .layui-carousel-arrow[lay-type="add"] {
  right: 20px;
}

.layui-carousel-ind {
  text-align: center;
  width: 100%;
  font-size: 0;
  position: relative;
  top: -35px;
  line-height: 0 !important;
}

.layui-carousel[lay-indicator="outside"] {
  margin-bottom: 30px;
}

.layui-carousel[lay-indicator="outside"] .layui-carousel-ind {
  top: 10px;
}

.layui-carousel[lay-indicator="outside"] .layui-carousel-ind ul {
  background-color: #00000080;
}

.layui-carousel[lay-indicator="none"] .layui-carousel-ind {
  display: none;
}

.layui-carousel-ind ul {
  background-color: #0003;
  border-radius: 10px;
  padding: 5px;
  transition-duration: .3s;
  display: inline-block;
}

.layui-carousel-ind ul li {
  cursor: pointer;
  background-color: #ffffff80;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  font-size: 14px;
  transition-duration: .3s;
  display: inline-block;
}

.layui-carousel-ind ul li:hover {
  background-color: #ffffffb3;
}

.layui-carousel-ind ul li.layui-this {
  background-color: #fff;
}

.layui-carousel > [carousel-item] > .layui-carousel-next, .layui-carousel > [carousel-item] > .layui-carousel-prev, .layui-carousel > [carousel-item] > .layui-this {
  display: block;
}

.layui-carousel > [carousel-item] > .layui-this {
  left: 0;
}

.layui-carousel > [carousel-item] > .layui-carousel-prev {
  left: -100%;
}

.layui-carousel > [carousel-item] > .layui-carousel-next {
  left: 100%;
}

.layui-carousel > [carousel-item] > .layui-carousel-next.layui-carousel-left, .layui-carousel > [carousel-item] > .layui-carousel-prev.layui-carousel-right {
  left: 0;
}

.layui-carousel > [carousel-item] > .layui-this.layui-carousel-left {
  left: -100%;
}

.layui-carousel > [carousel-item] > .layui-this.layui-carousel-right {
  left: 100%;
}

.layui-carousel[lay-anim="updown"] .layui-carousel-arrow {
  margin: 0 0 0 -18px;
  top: 20px;
  left: 50% !important;
}

.layui-carousel[lay-anim="updown"] .layui-carousel-arrow[lay-type="add"] {
  bottom: 20px;
  top: auto !important;
}

.layui-carousel[lay-anim="updown"] .layui-carousel-ind {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  right: 20px;
}

.layui-carousel[lay-anim="updown"] .layui-carousel-ind ul {
  padding: 3px 5px;
}

.layui-carousel[lay-anim="updown"] .layui-carousel-ind li {
  margin: 6px 0;
  display: block;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > * {
  left: 0 !important;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-this {
  top: 0;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-carousel-prev {
  top: -100%;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-carousel-next {
  top: 100%;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-carousel-next.layui-carousel-left, .layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-carousel-prev.layui-carousel-right {
  top: 0;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-this.layui-carousel-left {
  top: -100%;
}

.layui-carousel[lay-anim="updown"] > [carousel-item] > .layui-this.layui-carousel-right {
  top: 100%;
}

.layui-carousel[lay-anim="fade"] > [carousel-item] > * {
  left: 0 !important;
}

.layui-carousel[lay-anim="fade"] > [carousel-item] > .layui-carousel-next, .layui-carousel[lay-anim="fade"] > [carousel-item] > .layui-carousel-prev {
  opacity: 0;
}

.layui-carousel[lay-anim="fade"] > [carousel-item] > .layui-carousel-next.layui-carousel-left, .layui-carousel[lay-anim="fade"] > [carousel-item] > .layui-carousel-prev.layui-carousel-right {
  opacity: 1;
}

.layui-carousel[lay-anim="fade"] > [carousel-item] > .layui-this.layui-carousel-left, .layui-carousel[lay-anim="fade"] > [carousel-item] > .layui-this.layui-carousel-right {
  opacity: 0;
}

.layui-fixbar {
  z-index: 999999;
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.layui-fixbar li {
  text-align: center;
  cursor: pointer;
  color: #fff;
  opacity: .95;
  background-color: #9f9f9f;
  border-radius: 2px;
  width: 50px;
  height: 50px;
  margin-bottom: 1px;
  font-size: 30px;
  line-height: 50px;
}

.layui-fixbar li:hover {
  opacity: .85;
}

.layui-fixbar li:active {
  opacity: 1;
}

.layui-fixbar .layui-fixbar-top {
  font-size: 40px;
  display: none;
}

body .layui-util-face {
  background: none;
  border: none;
}

body .layui-util-face .layui-layer-content {
  color: #5f5f5f;
  box-shadow: none;
  background-color: #fff;
  padding: 0;
}

.layui-util-face .layui-layer-TipsG {
  display: none;
}

.layui-util-face ul {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 372px;
  padding: 10px;
  position: relative;
  box-shadow: 0 0 20px #0003;
}

.layui-util-face ul li {
  cursor: pointer;
  float: left;
  text-align: center;
  border: 1px solid #e8e8e8;
  width: 26px;
  height: 22px;
  margin: -1px 0 0 -1px;
  padding: 4px 2px;
  overflow: hidden;
}

.layui-util-face ul li:hover {
  z-index: 2;
  background: #fff9ec;
  border: 1px solid #eb7350;
  position: relative;
}

.layui-code {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #eee;
  border-left-width: 6px;
  margin: 10px 0;
  padding: 15px;
  font-family: Courier New, Lucida Console, Consolas;
  font-size: 12px;
  line-height: 20px;
  position: relative;
}

.layui-transfer-box, .layui-transfer-header, .layui-transfer-search {
  border: 0 solid #eee;
}

.layui-transfer-box {
  vertical-align: middle;
  background-color: #fff;
  border-width: 1px;
  border-radius: 2px;
  width: 200px;
  height: 360px;
  display: inline-block;
  position: relative;
}

.layui-transfer-box .layui-form-checkbox {
  width: 100%;
  margin: 0 !important;
}

.layui-transfer-header {
  border-bottom-width: 1px;
  height: 38px;
  padding: 0 11px;
  line-height: 38px;
}

.layui-transfer-search {
  border-bottom-width: 1px;
  padding: 11px;
  position: relative;
}

.layui-transfer-search .layui-input {
  height: 32px;
  padding-left: 30px;
  font-size: 12px;
}

.layui-transfer-search .layui-icon-search {
  color: #5f5f5f;
  margin-top: -8px;
  line-height: normal;
  position: absolute;
  top: 50%;
  left: 20px;
}

.layui-transfer-active {
  vertical-align: middle;
  margin: 0 15px;
  display: inline-block;
}

.layui-transfer-active .layui-btn {
  color: #fff;
  background-color: #16b777;
  border-color: #16b777;
  margin: 0;
  padding: 0 15px;
  display: block;
}

.layui-transfer-active .layui-btn-disabled {
  color: #d2d2d2;
  background-color: #fbfbfb;
  border-color: #eee;
}

.layui-transfer-active .layui-btn:first-child {
  margin-bottom: 15px;
}

.layui-transfer-active .layui-btn .layui-icon {
  margin: 0;
  font-size: 14px !important;
}

.layui-transfer-data {
  padding: 5px 0;
  overflow: auto;
}

.layui-transfer-data li {
  height: 32px;
  padding: 0 11px;
  line-height: 32px;
  margin-top: 0 !important;
  list-style-type: none !important;
}

.layui-transfer-data li:hover {
  background-color: #f8f8f8;
  transition: all .5s;
}

.layui-transfer-data .layui-none {
  text-align: center;
  color: #999;
  padding: 15px 11px;
}

.layui-rate, .layui-rate * {
  vertical-align: middle;
  display: inline-block;
}

.layui-rate {
  padding: 11px 6px 11px 0;
  font-size: 0;
}

.layui-rate li {
  margin-top: 0 !important;
}

.layui-rate li i.layui-icon {
  color: #ffb800;
  margin-right: 5px;
  font-size: 20px;
  transition: all .3s;
}

.layui-rate li i:hover {
  cursor: pointer;
  transform: scale(1.12);
}

.layui-rate[readonly] li i:hover {
  cursor: default;
  transform: scale(1);
}

.layui-colorpicker {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 2px;
  width: 38px;
  height: 38px;
  padding: 5px;
  line-height: 24px;
  transition: all .3s;
  display: inline-block;
}

.layui-colorpicker:hover {
  border-color: #d2d2d2;
}

.layui-colorpicker.layui-colorpicker-lg {
  width: 44px;
  height: 44px;
  line-height: 30px;
}

.layui-colorpicker.layui-colorpicker-sm {
  width: 30px;
  height: 30px;
  padding: 3px;
  line-height: 20px;
}

.layui-colorpicker.layui-colorpicker-xs {
  width: 22px;
  height: 22px;
  padding: 1px;
  line-height: 16px;
}

.layui-colorpicker-trigger-bgcolor {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
  border-radius: 2px;
  display: block;
}

.layui-colorpicker-trigger-span {
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #00000026;
  border-radius: 2px;
  height: 100%;
  display: block;
}

.layui-colorpicker-trigger-i {
  color: #fff;
  font-size: 12px;
  display: inline-block;
}

.layui-colorpicker-trigger-i.layui-icon-close {
  color: #999;
}

.layui-colorpicker-main {
  z-index: 77777777;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  width: 280px;
  margin: 5px 0;
  padding: 7px;
  position: absolute;
  top: -999999px;
  left: -999999px;
  box-shadow: 0 2px 4px #0000001f;
}

.layui-colorpicker-main-wrapper {
  height: 180px;
  position: relative;
}

.layui-colorpicker-basis {
  width: 260px;
  height: 100%;
  position: relative;
}

.layui-colorpicker-basis-white {
  background: linear-gradient(90deg, #fff, #fff0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-colorpicker-basis-black {
  background: linear-gradient(0deg, #000, #0000);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-colorpicker-basis-cursor {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -3px;
  right: -3px;
}

.layui-colorpicker-side {
  background: linear-gradient(red, #ff0, #0f0, #0ff, #00f, #f0f, red);
  width: 12px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-colorpicker-side-slider {
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  box-shadow: 0 0 1px #888;
}

.layui-colorpicker-main-alpha {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
  height: 12px;
  margin-top: 7px;
  display: none;
}

.layui-colorpicker-alpha-bgcolor {
  height: 100%;
  position: relative;
}

.layui-colorpicker-alpha-slider {
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  box-shadow: 0 0 1px #888;
}

.layui-colorpicker-main-pre {
  padding-top: 7px;
  font-size: 0;
}

.layui-colorpicker-pre {
  cursor: pointer;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  margin-bottom: 7px;
  margin-left: 6px;
  display: inline-block;
}

.layui-colorpicker-pre:nth-child(11n+1) {
  margin-left: 0;
}

.layui-colorpicker-pre-isalpha {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}

.layui-colorpicker-pre.layui-this {
  box-shadow: 0 0 3px 2px #00000026;
}

.layui-colorpicker-pre > div {
  border-radius: 2px;
  height: 100%;
}

.layui-colorpicker-main-input {
  text-align: right;
  padding-top: 7px;
}

.layui-colorpicker-main-input .layui-btn-container .layui-btn {
  margin: 0 0 0 10px;
}

.layui-colorpicker-main-input div.layui-inline {
  float: left;
  margin-right: 10px;
  font-size: 14px;
}

.layui-colorpicker-main-input input.layui-input {
  color: #5f5f5f;
  width: 150px;
  height: 30px;
}

.layui-slider {
  cursor: pointer;
  background: #eee;
  border-radius: 3px;
  height: 4px;
  position: relative;
}

.layui-slider-bar {
  border-radius: 3px;
  height: 100%;
  position: absolute;
}

.layui-slider-step {
  background: #fff;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.layui-slider-wrap {
  z-index: 10;
  text-align: center;
  width: 36px;
  height: 36px;
  position: absolute;
  top: -16px;
  transform: translateX(-50%);
}

.layui-slider-wrap-btn {
  vertical-align: middle;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: all .3s;
  display: inline-block;
}

.layui-slider-wrap:after {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.layui-slider-wrap-btn.layui-slider-hover, .layui-slider-wrap-btn:hover {
  transform: scale(1.2);
}

.layui-slider-wrap-btn.layui-disabled:hover {
  transform: scale(1) !important;
}

.layui-slider-tips {
  z-index: 77777777;
  white-space: nowrap;
  color: #fff;
  background: #000;
  border-radius: 3px;
  height: 25px;
  padding: 0 10px;
  line-height: 25px;
  display: none;
  position: absolute;
  top: -42px;
  transform: translateX(-50%);
}

.layui-slider-tips:after {
  content: "";
  border: 6px solid #0000;
  border-top-color: #000;
  width: 0;
  height: 0;
  margin-left: -6px;
  position: absolute;
  bottom: -12px;
  left: 50%;
}

.layui-slider-input {
  border: 1px solid #eee;
  border-radius: 3px;
  width: 70px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  position: absolute;
  top: -14px;
  right: 0;
}

.layui-slider-input-btn {
  border-left: 1px solid #eee;
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.layui-slider-input-btn i {
  cursor: pointer;
  text-align: center;
  color: #999;
  width: 20px;
  height: 50%;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.layui-slider-input-btn i:first-child {
  border-bottom: 1px solid #eee;
  top: 0;
}

.layui-slider-input-txt {
  height: 100%;
  font-size: 14px;
}

.layui-slider-input-txt input {
  border: none;
  height: 100%;
}

.layui-slider-input-btn i:hover {
  color: #16baaa;
}

.layui-slider-vertical {
  width: 4px;
  margin-left: 33px;
}

.layui-slider-vertical .layui-slider-bar {
  width: 4px;
}

.layui-slider-vertical .layui-slider-step {
  top: auto;
  left: 0;
  transform: translateY(50%);
}

.layui-slider-vertical .layui-slider-wrap {
  top: auto;
  left: -16px;
  transform: translateY(50%);
}

.layui-slider-vertical .layui-slider-tips {
  top: auto;
  left: 2px;
}

@media �screen {
  .layui-slider-wrap-btn {
    margin-left: -20px;
  }

  .layui-slider-vertical .layui-slider-wrap-btn {
    margin-bottom: -20px;
    margin-left: 0;
  }

  .layui-slider-vertical .layui-slider-tips {
    margin-left: -8px;
  }

  .layui-slider > span {
    margin-left: 8px;
  }
}

.layui-tree {
  line-height: 22px;
}

.layui-tree .layui-form-checkbox {
  margin: 0 !important;
}

.layui-tree-set {
  width: 100%;
  position: relative;
}

.layui-tree-pack {
  padding-left: 20px;
  display: none;
  position: relative;
}

.layui-tree-line .layui-tree-pack {
  padding-left: 27px;
}

.layui-tree-line .layui-tree-set .layui-tree-set:after {
  content: "";
  border-top: 1px dotted #c0c4cc;
  width: 17px;
  height: 0;
  position: absolute;
  top: 14px;
  left: -9px;
}

.layui-tree-entry {
  white-space: nowrap;
  height: 20px;
  padding: 3px 0;
  position: relative;
}

.layui-tree-entry:hover {
  background-color: #eee;
}

.layui-tree-line .layui-tree-entry:hover {
  background-color: #0000;
}

.layui-tree-line .layui-tree-entry:hover .layui-tree-txt {
  color: #999;
  text-decoration: underline;
  transition: all .3s;
}

.layui-tree-main {
  vertical-align: middle;
  cursor: pointer;
  padding-right: 10px;
  display: inline-block;
}

.layui-tree-line .layui-tree-set:before {
  content: "";
  border-left: 1px dotted #c0c4cc;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: -9px;
}

.layui-tree-line .layui-tree-set.layui-tree-setLineShort:before {
  height: 13px;
}

.layui-tree-line .layui-tree-set.layui-tree-setHide:before {
  height: 0;
}

.layui-tree-iconClick {
  vertical-align: middle;
  color: #c0c4cc;
  height: 20px;
  margin: 0 10px;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

.layui-tree-icon {
  text-align: center;
  border: 1px solid #c0c4cc;
  width: 12px;
  height: 12px;
  line-height: 12px;
}

.layui-tree-iconClick .layui-icon {
  font-size: 18px;
}

.layui-tree-icon .layui-icon {
  color: #5f5f5f;
  font-size: 12px;
}

.layui-tree-iconArrow {
  padding: 0 5px;
}

.layui-tree-iconArrow:after {
  content: "";
  z-index: 100;
  border: 5px solid #0000;
  border-left-color: #c0c4cc;
  width: 0;
  height: 0;
  transition: all .5s;
  position: absolute;
  top: 3px;
  left: 4px;
}

.layui-tree-spread > .layui-tree-entry .layui-tree-iconClick > .layui-tree-iconArrow:after {
  transform: rotate(90deg)translate(3px, 4px);
}

.layui-tree-txt {
  vertical-align: middle;
  color: #555;
  display: inline-block;
}

.layui-tree-search {
  color: #5f5f5f;
  margin-bottom: 15px;
}

.layui-tree-btnGroup {
  visibility: hidden;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.layui-tree-btnGroup .layui-icon {
  vertical-align: middle;
  cursor: pointer;
  padding: 0 2px;
  display: inline-block;
}

.layui-tree-btnGroup .layui-icon:hover {
  color: #999;
  transition: all .3s;
}

.layui-tree-entry:hover .layui-tree-btnGroup {
  visibility: visible;
}

.layui-tree-editInput {
  vertical-align: middle;
  background-color: #0000000d;
  border: none;
  height: 20px;
  padding: 0 3px;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

.layui-tree-emptyText {
  text-align: center;
  color: #999;
}

.layui-anim {
  animation-duration: .3s;
  animation-fill-mode: both;
}

.layui-anim.layui-icon {
  display: inline-block;
}

.layui-anim-loop {
  animation-iteration-count: infinite;
}

.layui-trans, .layui-trans a {
  transition: all .2s;
}

@-webkit-keyframes layui-rotate {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes layui-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.layui-anim-rotate {
  animation-name: layui-rotate;
  animation-duration: 1s;
  animation-timing-function: linear;
}

@-webkit-keyframes layui-up {
  from {
    opacity: .3;
    -webkit-transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes layui-up {
  from {
    opacity: .3;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.layui-anim-up {
  animation-name: layui-up;
}

@-webkit-keyframes layui-upbit {
  from {
    opacity: .3;
    -webkit-transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@keyframes layui-upbit {
  from {
    opacity: .3;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.layui-anim-upbit {
  animation-name: layui-upbit;
}

@keyframes layui-down {
  0% {
    opacity: .3;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.layui-anim-down {
  animation-name: layui-down;
}

@keyframes layui-downbit {
  0% {
    opacity: .3;
    transform: translate3d(0, -5px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.layui-anim-downbit {
  animation-name: layui-downbit;
}

@-webkit-keyframes layui-scale {
  0% {
    opacity: .3;
    -webkit-transform: scale(.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes layui-scale {
  0% {
    opacity: .3;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.layui-anim-scale {
  animation-name: layui-scale;
}

@-webkit-keyframes layui-scale-spring {
  0% {
    opacity: .5;
    -webkit-transform: scale(.5);
  }

  80% {
    opacity: .8;
    -webkit-transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes layui-scale-spring {
  0% {
    opacity: .5;
    transform: scale(.5);
  }

  80% {
    opacity: .8;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.layui-anim-scaleSpring {
  animation-name: layui-scale-spring;
}

@keyframes layui-scalesmall {
  0% {
    opacity: .3;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.layui-anim-scalesmall {
  animation-name: layui-scalesmall;
}

@keyframes layui-scalesmall-spring {
  0% {
    opacity: .3;
    transform: scale(1.5);
  }

  80% {
    opacity: .8;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.layui-anim-scalesmall-spring {
  animation-name: layui-scalesmall-spring;
}

@keyframes layui-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.layui-anim-fadein {
  animation-name: layui-fadein;
}

@keyframes layui-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.layui-anim-fadeout {
  animation-name: layui-fadeout;
}

html #layuicss-skincodecss {
  width: 1989px;
  display: none;
  position: absolute;
}

.layui-code-view {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #eee;
  border-left-width: 6px;
  margin: 11px 0;
  padding: 0;
  font-family: Courier New;
  font-size: 13px;
  display: block;
  position: relative;
}

.layui-code-title {
  border-bottom: 1px solid #eee;
  height: 40px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 40px;
  position: relative;
}

.layui-code-title > .layui-code-about {
  color: #b7b7b7;
  position: absolute;
  top: 0;
  right: 10px;
}

.layui-code-about > a {
  padding-left: 10px;
}

.layui-code-view > .layui-code-ol, .layui-code-view > .layui-code-ul {
  max-height: 100%;
  position: relative;
  overflow: auto;
  padding: 0 !important;
}

.layui-code-view > .layui-code-ol > li {
  background-color: #fff;
  border-left: 1px solid #e2e2e2;
  line-height: 20px;
  list-style-type: decimal-leading-zero;
  position: relative;
  margin-top: 0 !important;
  margin-left: 45px !important;
  padding: 0 10px !important;
}

.layui-code-view > .layui-code-ol > li:first-child, .layui-code-view > .layui-code-ul > li:first-child {
  padding-top: 10px !important;
}

.layui-code-view > .layui-code-ol > li:last-child, .layui-code-view > .layui-code-ul > li:last-child {
  padding-bottom: 10px !important;
}

.layui-code-view > .layui-code-ul > li {
  background-color: #fff;
  line-height: 20px;
  list-style-type: none;
  position: relative;
  padding: 0 10px !important;
}

.layui-code-view pre {
  margin: 0;
}

.layui-code-dark {
  color: #c2be9e;
  background-color: #0c0c0c;
  border: 1px solid #0c0c0c;
  border-left-color: #3f3f3f;
}

.layui-code-dark > .layui-code-title {
  border-bottom: none;
}

.layui-code-dark > .layui-code-ol > li, .layui-code-dark > .layui-code-ul > li {
  background-color: #3f3f3f;
  border-left: none;
}

.layui-code-dark > .layui-code-ul > li {
  margin-left: 6px;
}

.layui-code textarea {
  display: none;
}

.layui-code-preview > .layui-code {
  margin: 0;
}

.layui-code-preview > .layui-tab {
  z-index: 1;
  margin-bottom: 0;
  position: relative;
}

.layui-code-preview > .layui-tab > .layui-tab-title {
  border-bottom: none;
}

.layui-code-preview > .layui-code > .layui-code-title, .layui-code-preview .layui-code-item {
  display: none;
}

.layui-code-item-preview {
  padding: 16px;
  position: relative;
}

.layui-code-item-preview > iframe {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-code-tools {
  position: absolute;
  top: 3px;
  right: 11px;
}

.layui-code-tools > i {
  cursor: pointer;
  margin-left: 6px;
  padding: 3px;
  display: inline-block;
}

.layui-code-tools > i.layui-icon-file-b {
  color: #999;
}

.layui-code-tools > i:hover {
  color: #16b777;
}

.layui-code-copy {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 6px;
  right: 6px;
}

.layui-code-copy .layui-icon {
  color: #777;
  transition: all .3s;
}

.layui-code-copy:hover .layui-icon {
  color: #16b777;
}

.layui-code-view:hover > .layui-code-copy {
  display: block;
}

.layui-code-copy-offset {
  margin-right: 17px;
}

.layui-code-preview > .layui-code-view > .layui-code-copy {
  display: none !important;
}

.layui-code-full {
  z-index: 1111111;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.layui-code-full .layui-code-item {
  border-width: 1px 0 0 !important;
  width: 100% !important;
}

.layui-code-full .layui-code-item, .layui-code-full .layui-code-ol, .layui-code-full .layui-code-ul {
  box-sizing: border-box;
  height: calc(100vh - 51px) !important;
}

html #layuicss-laydate {
  width: 1989px;
  display: none;
  position: absolute;
}

.layui-laydate * {
  margin: 0;
  padding: 0;
}

.layui-laydate, .layui-laydate * {
  box-sizing: border-box;
}

.layui-laydate {
  z-index: 99999999;
  border-radius: 2px;
  margin: 5px 0;
  font-size: 14px;
  line-height: normal;
  animation-duration: .2s;
  animation-fill-mode: both;
  position: absolute;
}

.layui-laydate-main {
  width: 272px;
}

.layui-laydate-content td, .layui-laydate-header *, .layui-laydate-list li {
  transition-duration: .3s;
}

.layui-laydate-shade {
  _height: expression(document. body. offsetHeight + "px");
  _position: absolute;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes laydate-downbit {
  0% {
    opacity: .3;
    transform: translate3d(0, -5px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.layui-laydate {
  animation-name: laydate-downbit;
}

.layui-laydate-static {
  z-index: 0;
  margin: 0;
  animation: none;
  display: inline-block;
  position: relative;
}

.laydate-ym-show .laydate-next-m, .laydate-ym-show .laydate-prev-m {
  display: none !important;
}

.laydate-ym-show .laydate-next-y, .laydate-ym-show .laydate-prev-y {
  display: inline-block !important;
}

.laydate-ym-show .laydate-set-ym span[lay-type="month"], .laydate-time-show .laydate-set-ym span[lay-type="month"], .laydate-time-show .laydate-set-ym span[lay-type="year"], .laydate-time-show .layui-laydate-header .layui-icon {
  display: none !important;
}

.layui-laydate-header {
  padding: 10px 70px 5px;
  line-height: 30px;
  position: relative;
}

.layui-laydate-header * {
  vertical-align: bottom;
  display: inline-block;
}

.layui-laydate-header i {
  color: #999;
  cursor: pointer;
  padding: 0 5px;
  font-size: 18px;
  position: absolute;
  top: 10px;
}

.layui-laydate-header i.laydate-prev-y {
  left: 15px;
}

.layui-laydate-header i.laydate-prev-m {
  left: 45px;
}

.layui-laydate-header i.laydate-next-y {
  right: 15px;
}

.layui-laydate-header i.laydate-next-m {
  right: 45px;
}

.laydate-set-ym {
  text-align: center;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.laydate-set-ym span {
  cursor: pointer;
  padding: 0 10px;
}

.laydate-time-text {
  cursor: default !important;
}

.layui-laydate-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding: 10px;
  position: relative;
}

.layui-laydate-content table {
  border-collapse: collapse;
  border-spacing: 0;
}

.layui-laydate-content td, .layui-laydate-content th {
  text-align: center;
  width: 36px;
  height: 30px;
  padding: 0;
}

.layui-laydate-content th {
  font-weight: 400;
}

.layui-laydate-content td {
  cursor: pointer;
  position: relative;
}

.laydate-day-mark {
  width: 100%;
  font-size: 12px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.laydate-day-mark:after {
  content: "";
  border-radius: 50%;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.laydate-day-holidays:before {
  content: "休";
  color: #ff5722;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.7);
}

.laydate-day-holidays[type="work"]:before {
  content: "班";
  color: inherit;
}

.layui-laydate .layui-this .laydate-day-holidays:before {
  color: #fff;
}

.layui-laydate-footer {
  height: 46px;
  padding: 10px;
  line-height: 26px;
  position: relative;
}

.layui-laydate-footer span {
  vertical-align: top;
  cursor: pointer;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  height: 26px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 24px;
  transition: all .3s;
  display: inline-block;
}

.layui-laydate-footer span:hover {
  color: #16b777;
}

.layui-laydate-footer span.layui-laydate-preview {
  cursor: default;
  border-color: #0000 !important;
}

.layui-laydate-footer span.layui-laydate-preview:hover {
  color: #777;
}

.layui-laydate-footer span:first-child.layui-laydate-preview {
  padding-left: 0;
}

.laydate-footer-btns {
  position: absolute;
  top: 10px;
  right: 10px;
}

.laydate-footer-btns span {
  border-radius: 0;
  margin: 0 0 0 -1px;
}

.laydate-footer-btns span:first-child {
  border-radius: 2px 0 0 2px;
}

.laydate-footer-btns span:last-child {
  border-radius: 0 2px 2px 0;
}

.layui-laydate-shortcut {
  vertical-align: top;
  text-align: center;
  width: 80px;
  max-height: 276px;
  padding: 6px 0;
  display: inline-block;
  overflow: auto;
}

.layui-laydate-shortcut + .layui-laydate-main {
  border-left: 1px solid #e2e2e2;
  display: inline-block;
}

.layui-laydate-shortcut > li {
  cursor: pointer;
  padding: 5px 8px;
  line-height: 18px;
}

.layui-laydate .layui-laydate-list {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-laydate .layui-laydate-list > li {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  width: 33.3%;
  height: 36px;
  margin: 3px 0;
  line-height: 36px;
  list-style: none;
  display: inline-block;
  position: relative;
}

.layui-laydate .laydate-month-list > li {
  width: 25%;
  margin: 17px 0;
}

.layui-laydate .laydate-time-list > li {
  cursor: default;
  height: 100%;
  margin: 0;
  line-height: normal;
}

.layui-laydate .laydate-time-list p {
  margin: 0;
  line-height: 29px;
  position: relative;
  top: -4px;
}

.layui-laydate .laydate-time-list ol {
  height: 181px;
  overflow: hidden;
}

.layui-laydate .laydate-time-list > li:hover ol {
  overflow-y: auto;
}

.layui-laydate .laydate-time-list ol li {
  text-align: left;
  cursor: pointer;
  width: 130%;
  height: 30px;
  padding-left: 33px;
  line-height: 30px;
}

.layui-laydate-hint {
  text-align: center;
  color: #ff5722;
  width: 250px;
  margin-left: -125px;
  padding: 15px;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 115px;
  left: 50%;
}

.layui-laydate-range {
  width: 546px;
}

.layui-laydate-range .layui-laydate-main {
  vertical-align: middle;
  max-width: 50%;
  display: inline-block;
}

.layui-laydate-range .laydate-main-list-1 .layui-laydate-content, .layui-laydate-range .laydate-main-list-1 .layui-laydate-header {
  border-left: 1px solid #e2e2e2;
}

.layui-laydate-range.layui-laydate-linkage .laydate-main-list-0 .laydate-next-m, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-0 .laydate-next-y, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .laydate-prev-m, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .laydate-prev-y {
  display: none;
}

.layui-laydate, .layui-laydate-hint {
  color: #777;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  box-shadow: 0 2px 4px #0000001f;
}

.layui-laydate-header {
  border-bottom: 1px solid #e2e2e2;
}

.layui-laydate-header i:hover, .layui-laydate-header span:hover {
  color: #16b777;
}

.layui-laydate-content {
  border-top: 0;
  border-bottom: 0;
}

.layui-laydate-content th {
  color: #333;
}

.layui-laydate-content td {
  color: #777;
}

.layui-laydate-content td.laydate-day-now {
  color: #16b777;
}

.layui-laydate-content td.laydate-day-now:after {
  content: "";
  box-sizing: border-box;
  border: 1px solid #16b777;
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.layui-laydate-linkage .layui-laydate-content td.laydate-selected > div {
  background-color: #00f7de;
}

.layui-laydate-linkage .laydate-selected:hover > div {
  background-color: #00f7de !important;
}

.layui-laydate-content td.laydate-selected:after, .layui-laydate-content td:hover:after {
  content: none;
}

.layui-laydate-content td > div:hover, .layui-laydate-list li:hover, .layui-laydate-shortcut > li:hover {
  color: #333;
  background-color: #eee;
}

.laydate-time-list li ol {
  border: 1px solid #e2e2e2;
  border-left-width: 0;
  margin: 0;
  padding: 0;
}

.laydate-time-list li:first-child ol {
  border-left-width: 1px;
}

.laydate-time-list > li:hover {
  background: none;
}

.layui-laydate-content .laydate-day-next, .layui-laydate-content .laydate-day-prev {
  color: #d2d2d2;
}

.layui-laydate-linkage .laydate-selected.laydate-day-next > div, .layui-laydate-linkage .laydate-selected.laydate-day-prev > div {
  background-color: #f8f8f8 !important;
}

.layui-laydate-footer {
  border-top: 1px solid #e2e2e2;
}

.layui-laydate-hint {
  color: #ff5722;
}

.laydate-day-mark:after {
  background-color: #16b777;
}

.layui-laydate-content td.layui-this .laydate-day-mark:after {
  display: none;
}

.layui-laydate-footer span[lay-type="date"] {
  color: #16b777;
}

.layui-laydate .layui-this, .layui-laydate .layui-this > div {
  color: #fff !important;
  background-color: #16baaa !important;
}

.layui-laydate .laydate-disabled, .layui-laydate .laydate-disabled:hover {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: #d2d2d2 !important;
  cursor: not-allowed !important;
  background: none !important;
}

.layui-laydate-content td > div {
  height: 100%;
  padding: 7px 0;
}

.laydate-theme-molv {
  border: none;
}

.laydate-theme-molv.layui-laydate-range {
  width: 548px;
}

.laydate-theme-molv .layui-laydate-main {
  width: 274px;
}

.laydate-theme-molv .layui-laydate-header {
  background-color: #16baaa;
  border: none;
}

.laydate-theme-molv .layui-laydate-header i, .laydate-theme-molv .layui-laydate-header span {
  color: #f6f6f6;
}

.laydate-theme-molv .layui-laydate-header i:hover, .laydate-theme-molv .layui-laydate-header span:hover {
  color: #fff;
}

.laydate-theme-molv .layui-laydate-content {
  border: none;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
}

.laydate-theme-molv .laydate-main-list-1 .layui-laydate-content {
  border-left: none;
}

.laydate-theme-molv .layui-laydate-footer, .laydate-theme-grid .laydate-month-list > li, .laydate-theme-grid .laydate-year-list > li, .laydate-theme-grid .layui-laydate-content td, .laydate-theme-grid .layui-laydate-content thead {
  border: 1px solid #e2e2e2;
}

.layui-laydate-linkage.laydate-theme-grid .laydate-selected, .layui-laydate-linkage.laydate-theme-grid .laydate-selected:hover {
  color: #16baaa !important;
  background-color: #f2f2f2 !important;
}

.layui-laydate-linkage.laydate-theme-grid .laydate-selected.laydate-day-next, .layui-laydate-linkage.laydate-theme-grid .laydate-selected.laydate-day-prev {
  color: #d2d2d2 !important;
}

.laydate-theme-grid .laydate-month-list, .laydate-theme-grid .laydate-year-list {
  margin: 1px 0 0 1px;
}

.laydate-theme-grid .laydate-month-list > li, .laydate-theme-grid .laydate-year-list > li {
  margin: 0 -1px -1px 0;
}

.laydate-theme-grid .laydate-year-list > li {
  height: 43px;
  line-height: 43px;
}

.laydate-theme-grid .laydate-month-list > li {
  height: 71px;
  line-height: 71px;
}

.laydate-theme-grid .layui-laydate-content td > div {
  height: 29px;
  margin-top: -1px;
}

.laydate-theme-circle .layui-laydate-content td.layui-this > div, .laydate-theme-circle .layui-laydate-content td > div {
  border-radius: 14px;
  width: 28px;
  height: 28px;
  margin: 0 4px;
  padding: 0;
  line-height: 28px;
}

.layui-laydate.laydate-theme-circle .layui-laydate-content table td.layui-this {
  background-color: #0000 !important;
}

.laydate-theme-grid.laydate-theme-circle .layui-laydate-content td > div {
  margin: 0 3.5px;
}

.laydate-theme-fullpanel .layui-laydate-main {
  width: 526px;
}

.laydate-theme-fullpanel .layui-laydate-list {
  width: 252px;
  left: 272px;
}

.laydate-theme-fullpanel .laydate-set-ym span {
  display: none;
}

.laydate-theme-fullpanel .laydate-time-show .laydate-set-ym span[lay-type="month"], .laydate-theme-fullpanel .laydate-time-show .laydate-set-ym span[lay-type="year"], .laydate-theme-fullpanel .laydate-time-show .layui-laydate-header .layui-icon {
  display: inline-block !important;
}

.laydate-theme-fullpanel .laydate-btns-time {
  display: none;
}

html #layuicss-layer {
  width: 1989px;
  display: none;
  position: absolute;
}

.layui-layer, .layui-layer-shade {
  _position: absolute;
  pointer-events: auto;
  position: fixed;
}

.layui-layer-shade {
  _height: expression(document. body. offsetHeight + "px");
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.layui-layer {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  -webkit-background-clip: content;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  top: 150px;
  left: 0;
  box-shadow: 1px 1px 50px #0000004d;
}

.layui-layer-close {
  position: absolute;
}

.layui-layer-content {
  position: relative;
}

.layui-layer-border {
  border: 1px solid #0000001a;
  box-shadow: 1px 1px 5px #0003;
}

.layui-layer-btn a, .layui-layer-setwin span {
  vertical-align: top;
  display: inline-block;
}

.layui-layer-move {
  cursor: move;
  opacity: 0;
  filter: alpha(opacity= 0);
  z-index: 2147483647;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.layui-layer-resize {
  cursor: se-resize;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.layer-anim {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@keyframes layer-bounceIn {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.layer-anim-00 {
  animation-name: layer-bounceIn;
}

@keyframes layer-zoomInDown {
  0% {
    opacity: 0;
    animation-timing-function: ease-in-out;
    transform: scale(.1)translateY(-2000px);
  }

  60% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: scale(.475)translateY(60px);
  }
}

.layer-anim-01 {
  animation-name: layer-zoomInDown;
}

@keyframes layer-fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.layer-anim-02 {
  animation-name: layer-fadeInUpBig;
}

@keyframes layer-zoomInLeft {
  0% {
    opacity: 0;
    animation-timing-function: ease-in-out;
    transform: scale(.1)translateX(-2000px);
  }

  60% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: scale(.475)translateX(48px);
  }
}

.layer-anim-03 {
  animation-name: layer-zoomInLeft;
}

@keyframes layer-rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

.layer-anim-04 {
  animation-name: layer-rollIn;
}

@keyframes layer-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.layer-anim-05 {
  animation-name: layer-fadeIn;
}

@keyframes layer-shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.layer-anim-06 {
  animation-name: layer-shake;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes layer-slide-down {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes layer-slide-down-out {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

.layer-anim-slide-down {
  animation-name: layer-slide-down;
}

.layer-anim-slide-down-out {
  animation-name: layer-slide-down-out;
}

@keyframes layer-slide-left {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes layer-slide-left-out {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

.layer-anim-slide-left {
  animation-name: layer-slide-left;
}

.layer-anim-slide-left-out {
  animation-name: layer-slide-left-out;
}

@keyframes layer-slide-up {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes layer-slide-up-out {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

.layer-anim-slide-up {
  animation-name: layer-slide-up;
}

.layer-anim-slide-up-out {
  animation-name: layer-slide-up-out;
}

@keyframes layer-slide-right {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes layer-slide-right-out {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.layer-anim-slide-right {
  animation-name: layer-slide-right;
}

.layer-anim-slide-right-out {
  animation-name: layer-slide-right-out;
}

.layui-layer-title {
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  height: 50px;
  padding: 0 81px 0 16px;
  font-size: 14px;
  line-height: 50px;
  overflow: hidden;
}

.layui-layer-setwin {
  font-size: 0;
  line-height: initial;
  position: absolute;
  top: 16px;
  right: 15px;
}

.layui-layer-setwin span {
  text-align: center;
  cursor: pointer;
  color: #000;
  _overflow: hidden;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  font-size: 16px;
  line-height: 18px;
  position: relative;
}

.layui-layer-setwin .layui-layer-min:before {
  content: "";
  cursor: pointer;
  _overflow: hidden;
  background-color: #2e2d3c;
  width: 12px;
  height: 1px;
  margin: -.5px 0 0 -6px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.layui-layer-setwin .layui-layer-max:after, .layui-layer-setwin .layui-layer-max:before {
  content: "";
  z-index: 1;
  border: 1px solid #2e2d3c;
  width: 9px;
  height: 9px;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.layui-layer-setwin .layui-layer-max:hover:after, .layui-layer-setwin .layui-layer-max:hover:before {
  border-color: #2d93ca;
}

.layui-layer-setwin .layui-layer-min:hover:before {
  background-color: #2d93ca;
}

.layui-layer-setwin .layui-layer-maxmin:after, .layui-layer-setwin .layui-layer-maxmin:before {
  background-color: #fff;
  width: 7px;
  height: 7px;
  margin: -3px 0 0 -3px;
}

.layui-layer-setwin .layui-layer-maxmin:after {
  z-index: 0;
  margin: -5px 0 0 -1px;
}

.layui-layer-setwin .layui-layer-close {
  cursor: pointer;
}

.layui-layer-setwin .layui-layer-close:hover {
  opacity: .7;
}

.layui-layer-setwin .layui-layer-close2 {
  color: #fff;
  _display: none;
  background-color: #787878;
  border: 3px solid;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-left: 0;
  padding: 3px;
  font-size: 18px;
  font-weight: bolder;
  position: absolute;
  top: -28px;
  right: -28px;
}

.layui-layer-setwin .layui-layer-close2:hover {
  opacity: unset;
  background-color: #3888f6;
}

.layui-layer-btn {
  text-align: right;
  pointer-events: auto;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 15px 12px;
}

.layui-layer-btn a {
  color: #333;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 2px;
  height: 28px;
  margin: 5px 5px 0;
  padding: 0 15px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: none;
}

.layui-layer-btn a:hover {
  opacity: .9;
  text-decoration: none;
}

.layui-layer-btn a:active {
  opacity: .8;
}

.layui-layer-btn .layui-layer-btn0 {
  color: #fff;
  background-color: #1e9fff;
  border-color: #1e9fff;
}

.layui-layer-btn-l {
  text-align: left;
}

.layui-layer-btn-c {
  text-align: center;
}

.layui-layer-dialog {
  min-width: 240px;
}

.layui-layer-dialog .layui-layer-content {
  word-break: break-all;
  padding: 16px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.layui-layer-dialog .layui-layer-content .layui-layer-face {
  color: #959595;
  _left: -40px;
  font-size: 32px;
  position: absolute;
  top: 18px;
  left: 16px;
}

.layui-layer-dialog .layui-layer-content .layui-icon-tips {
  color: #f39b12;
}

.layui-layer-dialog .layui-layer-content .layui-icon-success {
  color: #16b777;
}

.layui-layer-dialog .layui-layer-content .layui-icon-error {
  color: #ff5722;
  top: 19px;
}

.layui-layer-dialog .layui-layer-content .layui-icon-question {
  color: #ffb800;
}

.layui-layer-dialog .layui-layer-content .layui-icon-lock {
  color: #787878;
}

.layui-layer-dialog .layui-layer-content .layui-icon-face-cry {
  color: #ff5722;
}

.layui-layer-dialog .layui-layer-content .layui-icon-face-smile {
  color: #16b777;
}

.layui-layer-rim {
  box-shadow: none;
  border: 6px solid #0000004d;
  border-radius: 5px;
}

.layui-layer-msg {
  box-shadow: none;
  border: 1px solid #d3d4d3;
  min-width: 180px;
}

.layui-layer-hui {
  filter: alpha(opacity= 60);
  color: #fff;
  background-color: #0009;
  border: none;
  min-width: 100px;
}

.layui-layer-hui .layui-layer-close {
  color: #fff;
}

.layui-layer-hui .layui-layer-content {
  text-align: center;
  padding: 11px 24px;
}

.layui-layer-dialog .layui-layer-padding {
  text-align: left;
  padding: 18px 24px 18px 58px;
}

.layui-layer-page .layui-layer-content {
  position: relative;
  overflow: auto;
}

.layui-layer-iframe .layui-layer-btn, .layui-layer-page .layui-layer-btn {
  padding-top: 10px;
}

.layui-layer-nobg {
  background: none;
}

.layui-layer-iframe iframe {
  width: 100%;
  display: block;
}

.layui-layer-loading {
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 100%;
}

.layui-layer-loading .layui-layer-content {
  text-align: center;
  width: 76px;
  height: 38px;
  line-height: 38px;
}

.layui-layer-loading-icon {
  color: #959595;
  font-size: 38px;
}

.layui-layer-loading2 {
  text-align: center;
}

.layui-layer-loading-2 {
  height: 38px;
  position: relative;
}

.layui-layer-loading-2:after, .layui-layer-loading-2:before {
  content: "";
  box-sizing: border-box;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin: -19px 0 0 -19px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.layui-layer-loading-2:after {
  border-color: #0000 #0000 #0000 #1e9fff;
}

.layui-layer-tips {
  box-shadow: none;
  background: none;
  border: none;
}

.layui-layer-tips .layui-layer-content {
  _float: left;
  color: #fff;
  background-color: #000;
  border-radius: 2px;
  min-width: 12px;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 22px;
  position: relative;
  box-shadow: 1px 1px 3px #0003;
}

.layui-layer-tips .layui-layer-close {
  top: -1px;
  right: -2px;
}

.layui-layer-tips i.layui-layer-TipsG {
  border: 8px dashed #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.layui-layer-tips i.layui-layer-TipsB, .layui-layer-tips i.layui-layer-TipsT {
  border-right-style: solid;
  border-right-color: #000;
  left: 5px;
}

.layui-layer-tips i.layui-layer-TipsT {
  bottom: -8px;
}

.layui-layer-tips i.layui-layer-TipsB {
  top: -8px;
}

.layui-layer-tips i.layui-layer-TipsL, .layui-layer-tips i.layui-layer-TipsR {
  border-bottom-style: solid;
  border-bottom-color: #000;
  top: 5px;
}

.layui-layer-tips i.layui-layer-TipsR {
  left: -8px;
}

.layui-layer-tips i.layui-layer-TipsL {
  right: -8px;
}

.layui-layer-lan .layui-layer-title {
  color: #fff;
  background: #4476a7;
  border: none;
}

.layui-layer-lan .layui-layer-btn {
  border-top: 1px solid #e9e7e7;
  padding: 5px 10px 10px;
}

.layui-layer-lan .layui-layer-btn a {
  color: #333;
  background: #fff;
  border-color: #e9e7e7;
}

.layui-layer-lan .layui-layer-btn .layui-layer-btn1 {
  background: #c9c5c5;
}

.layui-layer-molv .layui-layer-title {
  color: #fff;
  background: #009f95;
  border: none;
}

.layui-layer-molv .layui-layer-btn a {
  background: #009f95;
  border-color: #009f95;
}

.layui-layer-molv .layui-layer-btn .layui-layer-btn1 {
  background: #92b8b1;
}

.layui-layer-lan .layui-layer-setwin .layui-icon, .layui-layer-molv .layui-layer-setwin .layui-icon {
  color: #fff;
}

.layui-layer-win10 {
  border-radius: none;
  border: 1px solid #aaa;
  box-shadow: 1px 1px 6px #0000004d;
}

.layui-layer-win10 .layui-layer-title {
  border-bottom: none;
  height: 32px;
  padding-left: 8px;
  font-size: 12px;
  line-height: 32px;
}

.layui-layer-win10 .layui-layer-setwin {
  top: 0;
  right: 0;
}

.layui-layer-win10 .layui-layer-setwin span {
  margin-left: 0;
  padding: 8px;
}

.layui-layer-win10.layui-layer-page .layui-layer-setwin span {
  padding: 8px 11px;
}

.layui-layer-win10 .layui-layer-setwin span:hover {
  background-color: #e5e5e5;
}

.layui-layer-win10 .layui-layer-setwin span.layui-icon-close:hover {
  color: #fff;
  background-color: #e81123;
}

.layui-layer-win10.layui-layer-dialog .layui-layer-content {
  color: #0033bc;
  padding: 8px 16px 32px;
}

.layui-layer-win10.layui-layer-dialog .layui-layer-padding {
  padding-top: 18px;
  padding-left: 58px;
}

.layui-layer-win10 .layui-layer-btn {
  background-color: #f0f0f0;
  border-top: 1px solid #dfdfdf;
  padding: 5px 5px 10px;
}

.layui-layer-win10 .layui-layer-btn a {
  color: #000;
  background-color: #e1e1e1;
  border-color: #adadad;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  transition: all .3s;
}

.layui-layer-win10 .layui-layer-btn a:hover {
  background-color: #e5f1fb;
  border-color: #2a8edd;
}

.layui-layer-win10 .layui-layer-btn .layui-layer-btn0 {
  border-color: #0078d7;
}

.layui-layer-prompt .layui-layer-input {
  color: #333;
  border: 1px solid #e6e6e6;
  width: 260px;
  height: 36px;
  margin: 0 auto;
  padding-left: 10px;
  line-height: 30px;
  display: block;
}

.layui-layer-prompt textarea.layui-layer-input {
  width: 300px;
  height: 100px;
  padding: 6px 10px;
  line-height: 20px;
}

.layui-layer-prompt .layui-layer-content {
  padding: 16px;
}

.layui-layer-prompt .layui-layer-btn {
  padding-top: 0;
}

.layui-layer-tab {
  box-shadow: 1px 1px 50px #0006;
}

.layui-layer-tab .layui-layer-title {
  padding-left: 0;
  overflow: visible;
}

.layui-layer-tab .layui-layer-title span {
  vertical-align: top;
  text-align: center;
  cursor: default;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-left: 1px solid #0000;
  border-right: 1px solid #0000;
  min-width: 80px;
  max-width: 300px;
  padding: 0 16px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.layui-layer-tab .layui-layer-title span.layui-this {
  z-index: 10;
  background-color: #fff;
  border-left-color: #eee;
  border-right-color: #eee;
  height: 51px;
}

.layui-layer-tab .layui-layer-title span:first-child {
  border-left-color: #0000;
}

.layui-layer-tabmain {
  clear: both;
  line-height: 24px;
}

.layui-layer-tabmain .layui-layer-tabli {
  display: none;
}

.layui-layer-tabmain .layui-layer-tabli.layui-this {
  display: block;
}

.layui-layer-photos {
  box-shadow: none;
  background: none;
}

.layui-layer-photos .layui-layer-content {
  text-align: center;
  overflow: hidden;
}

.layui-layer-photos .layui-layer-phimg img {
  vertical-align: top;
  width: 100%;
  display: inline-block;
  position: relative;
}

.layui-layer-imgnext, .layui-layer-imgprev {
  cursor: pointer;
  color: #717171;
  width: 52px;
  height: 52px;
  margin-top: -26px;
  font-size: 52px;
  line-height: 52px;
  position: fixed;
  top: 50%;
}

.layui-layer-imgprev {
  left: 32px;
}

.layui-layer-imgnext {
  right: 32px;
}

.layui-layer-imgnext:hover, .layui-layer-imgprev:hover {
  color: #959595;
}

.layui-layer-imgbar {
  background-color: #000 \9 ;
  filter: Alpha(opacity= 60);
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #02000059;
  width: 100%;
  height: 40px;
  font-size: 0;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.layui-layer-imgtit * {
  vertical-align: top;
  color: #fff;
  padding: 0 5px;
  font-size: 12px;
  display: inline-block;
}

.layui-layer-imgtit h3 {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 65%;
  font-weight: 300;
  overflow: hidden;
}

.layui-layer-imgtit a:hover {
  color: #fff;
  text-decoration: underline;
}

.layui-layer-imgtit em {
  font-style: normal;
}

@keyframes layer-bounceOut {
  100% {
    opacity: 0;
    transform: scale(.7);
  }

  30% {
    transform: scale(1.05);
  }

  0% {
    transform: scale(1);
  }
}

.layer-anim-close {
  animation-name: layer-bounceOut;
  animation-duration: .2s;
  animation-fill-mode: both;
}

@media screen and (width <= 1100px) {
  .layui-layer-iframe {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}

.grid-demo {
  text-align: center;
  color: #fff;
  background-color: #79c48c;
  padding: 10px;
  line-height: 50px;
}

.grid-demo-bg1 {
  background-color: #63ba79;
}

.grid-demo-bg2 {
  background-color: #49a761;
}

.grid-demo-bg3 {
  background-color: #38814a;
}

.fishcalc {
  cursor: pointer;
}

tbody .layui-table-cell {
  height: 250px;
}

.biaoti {
  text-align: center;
  margin: 20px;
  font-size: 20px;
}

@media print {
  .noprint {
    display: none;
  }
}

html, body, #main, #rightmain, #leftmain {
  height: 100%;
  overflow: hidden;
}

.jh_point {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background: #ffffff80;
  border-radius: 5px;
  padding: 5px;
  line-height: 1;
  position: absolute;
}

.jh_shuoming {
  color: #00f7de;
  background: #ffffff80;
  border-radius: 5px;
  padding: 10px;
  line-height: 1;
  position: absolute;
}

.ruler_kedu {
  color: #00f7de;
  -webkit-user-select: none;
  user-select: none;
  background: #fff0;
  border-radius: 5px;
  line-height: 1;
  position: absolute;
}

/*# sourceMappingURL=index.2a0b2486.css.map */
